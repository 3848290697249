import { Component, Input, OnInit, ViewChild, viewChild } from "@angular/core";
import { FormBuilder, FormGroup, Validators } from "@angular/forms";
import { EquipmentFormComponent } from "../../common/equipment-form/equipment-form.component";
import { TableComponent } from "../../common/table/table.component";
import { ToastrService } from "ngx-toastr";
import { ApiService } from "../../../api.service";
import { CommonModule } from "@angular/common";
import { MapComponent } from "../../common/map/map.component";
import { LfnPathGenerartionComponent } from "../lfn-path-generartion/lfn-path-generartion.component";
import { StructureScreenComponent } from "../../common/structure-screen/structure-screen.component";
import { JlStructureComponent } from "../../common/jl-structure/jl-structure.component";
import { LfnUpstreamEquipmentComponent } from "../lfn-upstream-equipment/lfn-upstream-equipment.component";
import { NgxSpinnerService, NgxSpinnerModule } from 'ngx-spinner';
import { TitleTextPipe } from "../../../custom.pipe";
import { AdrFormComponent } from "../../common/adr-form/adr-form.component";

@Component({
  selector: "app-lfn-equipment-flow",
  standalone: true,
  imports: [EquipmentFormComponent,
    TableComponent,
    CommonModule,
    MapComponent,
    LfnPathGenerartionComponent,
    StructureScreenComponent,
    JlStructureComponent,
    LfnUpstreamEquipmentComponent,
    NgxSpinnerModule, TitleTextPipe,
    AdrFormComponent
  ],
  templateUrl: "./lfn-equipment-flow.component.html",
  styleUrls: ["./lfn-equipment-flow.component.scss"]
})

export class LfnEquipmentFlowComponent implements OnInit {
  isLoading: boolean = false;
  @Input() equPayLoad: any;
  @Input() table_title: string = '';
  @Input() inputData: any;
  @Input() showEquipmetCard: boolean = true;
  myEquipmetForm: any = FormGroup;
  tableData: any;  
  showTable: boolean = false;
  showMessage: boolean = false;
  MessageToShow: any; 
  success_path: boolean = false;
  upstream_screens: boolean = true;
  structure_screen: boolean = false;
  showADRCard: boolean = false;

  constructor(private toastr: ToastrService,
    private fb: FormBuilder,
    private apiService: ApiService,
    private spinner: NgxSpinnerService)
  { 
      this.myEquipmetForm = this.fb.group({
        equpType: ['', Validators.required],
      });
  }

  ngOnInit() {

  }

  onEuipCall(myEquipmetForm: any) {
    /// onEuipCall function call the api and based response select next flow. 
    this.isLoading = true; 
    this.spinner.show();
    this.equPayLoad['equipment'] = myEquipmetForm.value.equpType;

    this.apiService.selectEquipAdr(this.equPayLoad).subscribe({
      next: (response: { message: string; }) => {
        
        if (response.message === 'path generation successful' ||
          response.message === 'path generation successful type2') {
          this.tableData = response;
          this.showEquipmetCard = false;
          this.inputData = response;
          this.success_path = true;
          //this.lfnPathGeneration.viewProfile(this.inputData);
          this.upstream_screens = false;
        } else if (response.message === 'select upstream equipment adr' ) {
          this.showTable = true;
          this.tableData = response;
          this.showEquipmetCard = false;
          this.inputData = response;
          this.upstream_screens = false;
        } else if (response.message === 'select structure') {
          this.showEquipmetCard = false;
          this.upstream_screens = false;
          this.structure_screen = true;
          this.inputData = response;
        } else if (response.message === 'receive adr') {
          this.showADRCard = true;
          this.showEquipmetCard = false;
          this.upstream_screens = false;
          this.inputData = response;
        } else {
          if ('file_name' in response) {
            this.showMessage = true;
            this.MessageToShow = response;
          } else {
            this.toastr.error(response.message, 'Error Message');
          }
        }
        this.isLoading = false;
        this.spinner.hide();
      },
      error: (error: any) => {
        this.isLoading = false;
        this.spinner.hide();
        this.toastr.error(
          `The API returned Unsuccessfull response : ${error}`,
          'Error Message'
        );
      },
    });
  }


}
