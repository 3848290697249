import { Component, EventEmitter, Input, OnInit, Output } from "@angular/core";
import { FormBuilder, FormGroup, ReactiveFormsModule, Validators } from "@angular/forms";
import { CommonModule } from "@angular/common";

@Component({
  selector: "app-equipment-form",
  standalone: true,
  imports:[ReactiveFormsModule,CommonModule],
  templateUrl: "./equipment-form.component.html",
  styleUrls: ["./equipment-form.component.scss"]
})

export class EquipmentFormComponent implements OnInit {
  isLoading: boolean = false;
  @Input() equPayLoad: any ;
  myEquipmetForm: any = FormGroup;
  tableData: any;
  @Input() showEquipmetCard: boolean = true;
  showTable: boolean = false;
  table2Data: any;
  showType2Table: boolean = false;
  showMap: boolean = false;
  mapData: any;
  showMessage: boolean = false;
  MessageToShow: any;

  @Output() onEuipCall = new EventEmitter<string>();
  
  
  constructor(
    private fb: FormBuilder)
  { 
      this.myEquipmetForm = this.fb.group({
            equpType: ['', Validators.required],
          });
  }

  ngOnInit() {

  }

  
  onEuipCallForm() {
    // call parent function
    this.onEuipCall.emit(this.myEquipmetForm);
}
  
}
