<div *ngIf="upstream_screens">
<h3>{{'upstream-overwrite' | titleText }}</h3>
<div  class="card">
    <app-table 
        
        table_title={{table_title}}
        [inputData]="inputData"
        (appItemEvent)="receiveTableEvent($event)" >
    </app-table>
</div>
</div>

<div *ngIf="map_div">
           <app-jl-structure-overwrite
                [tableData]="tableData"
                [inputData]="inputData"
                table_title={{table_title}}                           
            ></app-jl-structure-overwrite>
</div>

<!--ADR Component called-->
<div *ngIf="showADRCard">
  <app-adr-form 
    [inputData]="inputData" 
    [showADRCard]="showADRCard"
  ></app-adr-form>
</div>

<!-- spinner Component Called-->
<div *ngIf="isLoading">
  <ngx-spinner type = "ball-scale-multiple"></ngx-spinner> 
</div>