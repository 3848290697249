import { HttpClient } from "@angular/common/http";
import { Component, EventEmitter, HostListener, Input, OnInit, Output, SimpleChanges } from "@angular/core";
import { FormBuilder, FormGroup, ReactiveFormsModule, Validators } from "@angular/forms";
import { ApiService } from "../../../api.service";
import { CommonModule } from "@angular/common";
import { LfnPathGenerartionComponent } from "../../lfn-flow/lfn-path-generartion/lfn-path-generartion.component";
import { NgxSpinnerService,NgxSpinnerModule } from 'ngx-spinner';
import { DfnPathGenerartionComponent } from "../../dfn-flow/dfn-path-generartion/dfn-path-generartion.component";
import { TitleTextPipe } from "../../../custom.pipe";
import { ToastrService } from "ngx-toastr";
import { AdrFormComponent } from "../adr-form/adr-form.component";
import { LfnUpstreamEquipmentComponent } from "../../lfn-flow/lfn-upstream-equipment/lfn-upstream-equipment.component";
declare var google: any;

@Component({
  selector: "app-structure-screen",
  standalone: true,
  imports: [ReactiveFormsModule,
    CommonModule,
    LfnPathGenerartionComponent,
    NgxSpinnerModule,
    DfnPathGenerartionComponent,
    TitleTextPipe,
    AdrFormComponent,
    LfnUpstreamEquipmentComponent
  ],
  templateUrl: "./structure-screen.component.html",
  styleUrls: ["./structure-screen.component.scss"]
})

export class StructureScreenComponent implements OnInit {
  
  markerData: any = [];
  
  selectStructure = true;
  MessageToShow = '';
  showMessage: boolean = false;
  enableProceedButton: boolean = false;
  isLoading: boolean = false;
  myStrForm: FormGroup;
  success_path: boolean = false;
  success_path_dfn: boolean = false;
  showADRCard = false;
  upstream_screen: boolean = false;
  
  @Input() inputData: any;
  @Input() refreshVariable: any = '';
  @Input() table_title :string = '';
  @Output() appItemEvent = new EventEmitter<any>();
  map: any;
  
  structure_list = [
      {
        lat: -37.847361,
        lon: 144.991863,
        label: '000000000000000000',
      },
  ];
  markers: any[] = [];
  // structure_list:any;
  marker_icon_FF0000 = {
    url: 'data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABUAAAAiCAYAAACwaJKDAAAABmJLR0QA/wD/AP+gvaeTAAACBklEQVRIia3VzUtUURgH4GdG/AiyZZShtWgXUbSIFtGqRYtqWRLhXyBYf0K6MaJQ2gRtayHtijYpleHKSCgIcRHoIiOSKEzLKea0OOeqTfPlzPzg5Qwz9zz3nXPvPTeneo7gNA4gjyI+Ygbva8z9L2cxi9BHOE+4msY+gliz6biayWE0R7GfMEcoEkJJzRH6CbnY+WiaVxEc6yY8KQOVq8eE7tj1WCV4qIswUyeY1QyhK8JDpWAP1m7vEMzqTkTXkrOZkYOEQoNogXAowiPE2wQuDqC9nktZJu0YSE72XRs2phrsMqup2OkG2vLpRB19DXaZJc3vQHv294Um0e3z8yigsNQkmuYXUMie5/npJtE0fz55YLiXsNHELdUbV2B4+4n2Y/Vmg+itCK4m558MdhBe7hCcJnRGdLDS0ox3E17XCb4h7IngeLX1zuFhD2G5BriytY4Tqmx9WXbh3Tnl99KsLkdwAbtrgVmO4/eDCuCkzd3/TL1glru9hF8lYJFwMoKPdgrCXqzfL0GfR7CIo42gcO9YCXopolONgnAC4W0Cv9l8dVxpBoWFGwmdiOC6Glc8X+3HlKeT6cOzOLzAjyaaBBc602ZzOHZ6vVkQ9kl7Qi6ip1qBwpdrEfwjPnFVU8+awuKrOC7hZ6vQlQ9baM3Ui379HsfVVqKf07jcSvRTGhfrOfgvIP3ECS77BDoAAAAASUVORK5CYII=',
    labelOrigin: new google.maps.Point(10, 11),
  };

  marker_icon_FF0001 = {
    url: '/assets/images/home.png',
    labelOrigin: new google.maps.Point(10, 11),
  };
  marker_icon_FF0002 = {
    url: '/assets/images/highligted_icon.png',
    labelOrigin: new google.maps.Point(10, 11),
  };

  
  
  constructor(
    private http: HttpClient,
    private fb: FormBuilder,
    private apiService: ApiService,
    private spinner: NgxSpinnerService,
    private toastr: ToastrService
  ) { 
    this.myStrForm = this.fb.group({
      structure: ['', Validators.required],
    });
    this.myStrForm.setValue({
      structure: 'Select Structure',
    });

  }

  ngOnInit() {

    this.structure_list = this.inputData.structure_list;
    if (this.inputData.message == 'select bjl structure') {
      this.selectStructure = false;
      this.structure_list = this.inputData.bjl_structure_list;
    }
    if (this.inputData.message == 'select bjl structure dfn') {
      this.selectStructure = false;
      this.structure_list = this.inputData.bjl_structure_list;
    }
    if (this.inputData.message == 'select mpt structure') {
      this.selectStructure = false;
      this.structure_list = this.inputData.mpt_structure_list;
    }
    if (this.inputData.message == 'select ajl structure') {
      this.selectStructure = false;
      this.structure_list = this.inputData.ajl_structure_list;
    }
    console.log(this.structure_list[this.structure_list.length - 1].label);
    console.log(this.structure_list[this.structure_list.length - 1].lat);
    this.initMap(
      this.structure_list[this.structure_list.length - 1].lat,
      this.structure_list[this.structure_list.length - 1].lon
    );
    this.addMarkers(this.structure_list, 'default');
  }

  ngOnChanges(changes: SimpleChanges) {
    if (changes['refreshVariable']) {
      this.receiveEvent(changes['refreshVariable'].currentValue);
      // Add your code to be executed when myVariable changes
    }
  }
  addMarkers(structure_list: any, selectedLabel: any) {
    for (let ele of structure_list) {
      const data = {
        position: new google.maps.LatLng(ele.lat, ele.lon),
        label: ele.label,
      };
      this.markerData.push(data);
    }

    // Create markers and attach click event listeners
    this.markerData.forEach((data: { position: any; label: any }) => {
      let marker: any;
      if (data.label.indexOf('LOC') != -1) {
        marker = new google.maps.Marker({
          position: data.position,
          label: {
            text: data.label,
            color: 'white',
          },
          icon: this.marker_icon_FF0001,
          map: this.map,
        });
      } else if (data.label == selectedLabel) {
        marker = new google.maps.Marker({
          position: data.position,
          label: data.label,
          icon: this.marker_icon_FF0002,
          map: this.map,
        });
      } else {
        marker = new google.maps.Marker({
          position: data.position,
          label: {
            text: data.label,
            color: 'white',
          },
          icon: this.marker_icon_FF0000,
          map: this.map,
        });
      }

      // Add a click event listener to each marker
      marker.addListener('click', () => {
        // Handle the click event here, e.g., display the label
        this.handleMarker(marker);
        console.log('Marker clicked with label:', marker.getLabel());
      });

      // Push the marker object to the array
      this.markers.push(marker);
    });
  }
  handleMarker(_marker: any) {
    let label = _marker.getLabel();
    this.inputData['structure_id'] = label.text;
    this.myStrForm.setValue({
      structure: label.text,
    });
  }
  initMap(lat: any, long: any): void {
    this.map = new google.maps.Map(document.getElementById('map'), {
      center: { lat: lat, lng: long },
      zoom: 20,
      mapTypeId: 'satellite',
      // center: { lat: -25.274400, lng: 133.775100 },
      // zoom: 4
    });

    const marker = new google.maps.Marker({
      position: { lat: 37.7749, lng: -122.4194 },
      map: this.map,
      title: 'Marker Title',
    });
  }
  @HostListener('window:message', ['$event'])
  handleMessage(event: MessageEvent) {
    if (event.data.type === 'markerClick') {
      const label = event.data.label;
      // this.setFormValue(label)
      this.inputData['structure_id'] = label.text;
      console.log('Map clicked at:', this.inputData);
    }
  }
 
  onselectStructure() {
    // onselectStructure :- 
    this.isLoading = true;
    this.spinner.show();
    console.log(this.inputData);
    this.apiService.selectStructureAdr(this.inputData).subscribe({
      next: (response) => {
        if (response.message === 'path generation successful' || response.message === 'path generation successful type2') {
          this.selectStructure = false;
          this.success_path = true;
          this.inputData = response;
        }
        else if (response.message === 'path generation successful dfn') {
          this.selectStructure = false;
          this.success_path_dfn = true;
          this.inputData = response;
        }        
        else if (response.message === 'select upstream equipment adr') {
          //this.isLoading = true;
          this.showMessage = true;
          // this.tableData = response;
          // this.showMap = false;
          // this.selectStructure = false;
          // this.showTable = true;
          this.upstream_screen = true;
          this.inputData = response;
          this.selectStructure = false;
        }
        else if (response.message === 'receive adr') {
          this.showADRCard = true;
          this.selectStructure = false;
          this.inputData = response;
        }
        else {
          this.toastr.error(
              response.message,
            'Error Message'
          );
          // this.showMessage = true;
          // this.MessageToShow = JSON.stringify(response);
        }

        this.isLoading = false;
        this.spinner.hide();
        this.showMessage = false;
        // Handle the response data
      },

      error: (error) => {
        this.isLoading = false;
        this.spinner.hide();
        window.alert(`The API returned Unsuccessfull response : ${error}`);
      },
    });
  }

  receiveEvent(data: any) {
    
  }

}
