
<div *ngIf="showTable" class="card">
    <h3 *ngIf="showTable">{{'path' | titleText}}</h3>
    <app-duct-form 
        *ngIf="showTable"
        [myForm]="myForm"
        [inputData]="inputData" 
        (callBjlPathOverwrite)= "callBjlPathOverwrite($event)"
        [overwrite_flag]="back_button_flag"
        >
    </app-duct-form>

    <div *ngIf="showTable">
        <app-table         
            table_title="Available Paths" 
            [inputData]="inputData" 
            (appItemEvent)="receiveTableEvent($event)">
        </app-table>
    </div> 
    
</div>

<div *ngIf="alternate_path" class="card">
    <h3 *ngIf="alternate_path">{{'alternate_path' | titleText}}</h3>
    <app-table         
            table_title="Available Paths" 
            [inputData]="inputData" 
            (appItemEvent)="alternatePath($event)">
        </app-table>
</div>
<div *ngIf="showCheckboxTable" class="card">
        <h3 *ngIf="showCheckboxTable">{{'checkbox' | titleText}}</h3>
    <app-table-checkbox
        [dataSource]="dataSource"
        [displayedColumns]="displayedColumns"
        [inputData]="inputData"
        [showCheckboxTable]="showCheckboxTable"
        (goBackCall)="goBackCall($event)"
        (OnAdrCall)="OnAdrCall($event)"
    ></app-table-checkbox>


</div>

<div *ngIf="showADRCard" class="card">
    <h3 *ngIf="showADRCard">{{'adr' | titleText}}</h3>
    <app-upload-file [flowName]="flowName" (uploaded)="fileUploaded($event)" [showADRCard]="showADRCard"
            [inputData]="inputData" [selectedRow]="selectedRow" (goBackCall)="goBackCall($event)">
    </app-upload-file>
    <form [formGroup]="myAdrForm" (ngSubmit)="table_function_called()">

        <div class="row">
            <div class="col-md-3 form-group">
                <button type="submit" class="primary">
                        Submit
                </button>
            </div>
        </div>
    </form>
    
</div>


<!-- <div *ngIf="upstream_screen">
  <app-lfn-upstream-equipment-overwrite
    [inputData]="inputData"
    table_title="Upstream Equipment"
  ></app-lfn-upstream-equipment-overwrite>
</div> -->

<div *ngIf="showMessage" class="card">
        <app-message 
            [showMessage]="showMessage"
            [MessageToShow]="MessageToShow"
            url={{url}}
            msg={{msg}}
            filename={{filename}}
        >
        </app-message>
</div>
<div *ngIf="isLoading">
    <ngx-spinner type="ball-scale-multiple"></ngx-spinner>
</div>