
<div *ngIf="showMessage" class="content">
  <h3>{{'success' | titleText}}</h3>
  <mat-card>
    <mat-card-content>
      <table class="mat-elevation-z12 table_css" >
        <tr class="tr_css">
          <th class="td_css">File Status</th>
          <th class="td_css">FileName</th>
          <th class="td_css">Action</th>
        </tr>
        <tr>
          <td class="td_css">{{ msg }}</td>
          <td class="td_css">
            {{ filename }}
          </td>
          <td class="td_css"><a href="{{ url }}" download>Download</a></td>
        </tr>
      </table>
    </mat-card-content>
  </mat-card>
</div>
