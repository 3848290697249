import { Component, EventEmitter, Input, OnInit, Output, ViewChild } from "@angular/core";
import { TableComponent } from "../../common/table/table.component";
import { SelectionModel } from "@angular/cdk/collections";
import { MatTableDataSource } from "@angular/material/table";
import { NgxSpinnerService, NgxSpinnerModule } from 'ngx-spinner';
import { ToastrService } from "ngx-toastr";
import { ApiService } from "../../../api.service";
import { CommonModule } from "@angular/common";
import { JlStructureComponent } from "../../common/jl-structure/jl-structure.component";
import { TitleTextPipe } from "../../../custom.pipe";
import { Router } from '@angular/router';

@Component({
  selector: "app-dfn-path-generartion",
  standalone: true,
  imports: [
    TableComponent,
    CommonModule,
    NgxSpinnerModule,
    JlStructureComponent,
    TitleTextPipe
  ],
  templateUrl: "./dfn-path-generartion.component.html",
  styleUrls: ["./dfn-path-generartion.component.scss"]
})

export class DfnPathGenerartionComponent implements OnInit {
  @Input() inputData: any;
  @Input() table_title: string = '';
  tableData: any;
  displayedColumns: string[] = [];
  dataSource!: MatTableDataSource<any>;
  selection= new SelectionModel<any>(true, []);
  selectedRow: any;
  showTable: boolean = true;
  isLoading: boolean = false;
  showMessage: boolean = false;
  MessageToShow: any;
  showJlStructure: boolean = false;

  constructor(
    private toastr: ToastrService,
    private apiService: ApiService,
    private spinner: NgxSpinnerService,
    private router: Router
  ) { 

  }

  ngOnInit() {

  }

  receiveTableEvent(data: any) {
    this.inputData['selected_dfn_path'] = data;
    this.isLoading = true;
    this.spinner.show()  
    this.apiService.onSelectDfnPath(this.inputData).subscribe({
      next: (response) => {
            if (response.message == 'select bjl structure dfn') {
              this.showTable = false;
              this.showJlStructure = true;
              this.inputData = response;
              this.tableData = response;
            }
            else if (response.message == 'receive adr') {
               this.router.navigateByUrl('/adr');
            }
            else {              
              if ('file_name' in response) {
                this.showTable = false;
                this.showMessage = true;
                this.MessageToShow = response;
              } else {
                this.toastr.error(response.message, 'Error Message');
                console.log('error');
              }
            }

            this.isLoading = false;
            this.spinner.hide();
            // window.alert(response.messgae);
      },
      error: (error) => {
            this.isLoading = false;
            this.spinner.hide();
            this.toastr.error(
              `The API returned Unsuccessfull response : ${error}`,
              'Error Message'
            );
            //window.alert(`The API returned Unsuccessfull response : ${error}`);
          },
    });
  }
}
