import { CommonModule } from '@angular/common';
import { Component, EventEmitter, Input, Output } from '@angular/core';
import {MatIconModule} from '@angular/material/icon';
import { ActivatedRoute, Router } from '@angular/router';

@Component({
  selector: 'app-header',
  standalone: true,
  imports: [MatIconModule,CommonModule],
  templateUrl: './header.component.html',
  styleUrl: './header.component.scss'
})
export class HeaderComponent {
  title: string = 'ODMS';
  @Input() islogin: boolean = false;
  @Output() signOut = new EventEmitter<string>();

  constructor(
    private router: Router,
    private activatedRoute: ActivatedRoute
  ) {
  }
  signOutCall() {
    // call parent function
    this.signOut.emit();
  }
  realodPage(data: any) {
    console.log(this.activatedRoute);
    if (data == 'adr') {
      this.router.navigateByUrl('/adr');
    }
    else if (data == 'sd1') {
      this.router.navigateByUrl('/sd1');
    }
    else if (data == 'home') {
      // Disable route reuse strategy to force a refresh
    this.router.routeReuseStrategy.shouldReuseRoute = () => false;
    
    // Set same URL navigation to reload
    this.router.onSameUrlNavigation = 'reload';
      this.router.navigate(['/'], { relativeTo: this.activatedRoute });

    }
    // else {
    //   this.router.navigateByUrl('/');      
    // }
    
  }

  refreshHeader(islogin: boolean) {
    
    this.islogin = islogin;
    
  }
}
