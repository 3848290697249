import { Component, OnInit, ViewChild } from '@angular/core';
import { RouterOutlet } from '@angular/router';
import { LandingPageComponent } from './components/landing-page/landing-page.component';
//import { LandingPageComponentADR } from './components_adr/landing-page/landing-page.component';
import { HeaderComponent } from './components/common/header/header.component'
import { FooterComponent } from './components/common/footer/footer.component'
import { Amplify } from 'aws-amplify';
import { AmplifyAuthenticatorModule, AuthenticatorService } from '@aws-amplify/ui-angular';
import { getCurrentUser, signOut } from 'aws-amplify/auth';
import awsExports from './../aws-exports';
import { CommonModule } from '@angular/common';

@Component({
  selector: 'app-root',
  standalone: true,
  imports: [
    RouterOutlet,
    LandingPageComponent,
    HeaderComponent,
    FooterComponent,
    AmplifyAuthenticatorModule,
    CommonModule
  ],
  templateUrl: './app.component.html',
  styleUrl: './app.component.scss'
})
export class AppComponent implements OnInit{
  title = 'odms-frontend';
  existing_user: boolean = false;
  new_user: boolean = true;
  islogin: boolean = false;

  @ViewChild(HeaderComponent)
  headerComponent!: HeaderComponent;

  constructor(public authenticator: AuthenticatorService) {
      Amplify.configure(awsExports);
  }
  

  ngOnInit(): void {
    this.currentAuthenticatedUser();
    //this.headerComponent.refreshHeader(this.islogin);
  }

  async currentAuthenticatedUser() {
      try {
        const { username, userId, signInDetails } = await getCurrentUser();
        console.log(username, userId, signInDetails);
        this.existing_user = true;
        this.new_user = false;
        this.islogin = true;
        
      } catch (err) {
        this.existing_user = false;
        this.new_user = true;
        this.islogin = false;
        console.log(err);
      }
    }

  async signOut() {
    await signOut({ global: true });
    window.location.reload();
  }
}
