import { CommonModule } from "@angular/common";
import { HttpClient } from "@angular/common/http";
import { Component, EventEmitter, HostListener, Input, OnInit, Output, SimpleChanges } from "@angular/core";
import { FormGroup, FormBuilder, Validators, ReactiveFormsModule } from "@angular/forms";
import {ApiService} from "../../api.service";

declare var google: any;

@Component({
  selector: 'app-map-sd1',
  standalone: true,
  imports:[CommonModule,ReactiveFormsModule],
  templateUrl: './map-sd1.component.html',
  styleUrl: './map-sd1.component.scss'
})
export class MapSd1Component implements OnInit{
@Input() inputData: any;
  @Input() refreshVariable: any = '';
  @Output() appItemEvent = new EventEmitter<any>();
  @Output() OnSelectProceedMap = new EventEmitter<any>();
  selected_existing_smp: any = [];
  map: any;
  isLoading: boolean = false;
  showMap: boolean = true;
  selectStructure = true;
  markers: any[] = [];
  enableProceedButton: boolean = false;
  // structure_list:any;
  marker_icon_FF0000 = {
    url: 'data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABUAAAAiCAYAAACwaJKDAAAABmJLR0QA/wD/AP+gvaeTAAACBklEQVRIia3VzUtUURgH4GdG/AiyZZShtWgXUbSIFtGqRYtqWRLhXyBYf0K6MaJQ2gRtayHtijYpleHKSCgIcRHoIiOSKEzLKea0OOeqTfPlzPzg5Qwz9zz3nXPvPTeneo7gNA4gjyI+Ygbva8z9L2cxi9BHOE+4msY+gliz6biayWE0R7GfMEcoEkJJzRH6CbnY+WiaVxEc6yY8KQOVq8eE7tj1WCV4qIswUyeY1QyhK8JDpWAP1m7vEMzqTkTXkrOZkYOEQoNogXAowiPE2wQuDqC9nktZJu0YSE72XRs2phrsMqup2OkG2vLpRB19DXaZJc3vQHv294Um0e3z8yigsNQkmuYXUMie5/npJtE0fz55YLiXsNHELdUbV2B4+4n2Y/Vmg+itCK4m558MdhBe7hCcJnRGdLDS0ox3E17XCb4h7IngeLX1zuFhD2G5BriytY4Tqmx9WXbh3Tnl99KsLkdwAbtrgVmO4/eDCuCkzd3/TL1glru9hF8lYJFwMoKPdgrCXqzfL0GfR7CIo42gcO9YCXopolONgnAC4W0Cv9l8dVxpBoWFGwmdiOC6Glc8X+3HlKeT6cOzOLzAjyaaBBc602ZzOHZ6vVkQ9kl7Qi6ip1qBwpdrEfwjPnFVU8+awuKrOC7hZ6vQlQ9baM3Ui379HsfVVqKf07jcSvRTGhfrOfgvIP3ECS77BDoAAAAASUVORK5CYII=',
    labelOrigin: new google.maps.Point(10, 11),
  };
  selected_data : any = []

  marker_icon_FF0001 = {
    url: '/assets/images/home.png',
    labelOrigin: new google.maps.Point(10, 11),
  };
  marker_icon_FF0002 = {
    url: '/assets/images/highligted_icon.png',
    labelOrigin: new google.maps.Point(10, 11),
  };
  marker_icon_FF0003 = {
    url: '/assets/images/nec.png',
    labelOrigin: new google.maps.Point(10, 11),
  };

  markerData: any = [];
  myStrForm: FormGroup;
  structure_list_map_smp = [
    {
      loc_lat: -37.847361,
      loc_lon: 144.991863,
      loc_label: '000000000000000000',
    },
  ];
  structure_list_map = [
    {
      lat: -37.847361,
      lon: 144.991863,
      label: '000000000000000000',
    },
  ];

  constructor(
    private http: HttpClient,
    private fb: FormBuilder,
    private apiService: ApiService
  ) {
    this.myStrForm = this.fb.group({
      structure: ['', Validators.required],
    });
    this.myStrForm.setValue({
      structure: 'Select Structure',
    });
  }

  ngOnInit() {
    // this.structure_list=this.inputData.structure_list
    this.structure_list_map = this.inputData.structure_list;
    if (this.inputData.message == 'select existing smp' || 
      this.inputData.message == 'select new smp' ) {
        this.selectStructure = false;
        this.structure_list_map_smp = this.inputData.location_cords_list;
        for(let i=0;i<this.inputData.locations_served_from_smp.length;i++){
            let structure_list_home:any = {
                  loc_lat:this.inputData.locations_served_from_smp[i].smp_lat,
                  loc_lon: this.inputData.locations_served_from_smp[i].smp_lon,
                  loc_label: this.inputData.locations_served_from_smp[i].smp_label,
                  excluded: true
                }
              this.structure_list_map_smp.push(structure_list_home);
        }
        if ('asset_cords_list' in this.inputData) {
          for (let i = 0; i < this.inputData.asset_cords_list.length; i++) {
            let structure_list_home: any = {
              loc_lat: this.inputData.asset_cords_list[i].lat,
              loc_lon: this.inputData.asset_cords_list[i].lon,
              loc_label: this.inputData.asset_cords_list[i].label,
              excluded: true,
            }
            this.structure_list_map_smp.push(structure_list_home);
          }
        }
        
        this.structure_list_map_smp = this.removeDuplicates(this.structure_list_map_smp, 'loc_label');
        this.initMap(
        this.structure_list_map_smp[this.structure_list_map_smp.length - 1].loc_lat,
        this.structure_list_map_smp[this.structure_list_map_smp.length - 1].loc_lon
        );
        this.addMarkers(this.structure_list_map_smp, 'default');  
    }
    else if (this.inputData.message == "select smp structure" || this.inputData.message == "select bjl structure sd1") {
      if (this.inputData.message == "select smp structure") {
        this.structure_list_map = this.inputData.smp_structure_list;
      }
      if (this.inputData.message == "select bjl structure sd1") {
        this.structure_list_map = this.inputData.bjl_structure_list;
      }
      for(let i=0;i<this.inputData.location_cords_list.length;i++){
            let structure_list_home:any = {
                lat:this.inputData.location_cords_list[i].loc_lat,
                lon: this.inputData.location_cords_list[i].loc_lon,
                label: this.inputData.location_cords_list[i].loc_label,
                excluded:true
              }
            this.structure_list_map.push(structure_list_home);
      }
      if ('location_cords_list_served' in this.inputData) {
        for (let i = 0; i < this.inputData.location_cords_list_served.length; i++) {
          let structure_list_home: any = {
            lat: this.inputData.location_cords_list_served[i].loc_lat,
            lon: this.inputData.location_cords_list_served[i].loc_lon,
            label: this.inputData.location_cords_list_served[i].loc_label,
            excluded: true,
            color: 'green'
          }
          this.structure_list_map.push(structure_list_home);
        }
      }
      if ('asset_cords_list' in this.inputData) {
          for (let i = 0; i < this.inputData.asset_cords_list.length; i++) {
            let structure_list_home: any = {
              lat: this.inputData.asset_cords_list[i].lat,
              lon: this.inputData.asset_cords_list[i].lon,
              label: this.inputData.asset_cords_list[i].label,
              excluded: true,
            }
            this.structure_list_map.push(structure_list_home);
          }
        }
        this.initMap(
        this.structure_list_map[this.structure_list_map.length - 1].lat,
        this.structure_list_map[this.structure_list_map.length - 1].lon
        );
        this.addMarkers(this.structure_list_map, 'default');  
    }    
  }

  removeDuplicates(array: any[], key: string): any[] {
    const unique = new Map();
    array.forEach(item => {
      unique.set(item[key], item);
    });
    return Array.from(unique.values());
  }

  ngOnChanges(changes: SimpleChanges) {
    if (changes['refreshVariable']) {
      this.receiveEvent(changes['refreshVariable'].currentValue);
    }
  }
  addMarkers(structure_list: any, selectedLabel: any) {
    this.markers = [];
    this.markerData = [];
    //if (selectedLabel == 'default') {
    if (this.inputData.message == 'select existing smp' ||
      this.inputData.message == 'select new smp') {
        for (let ele of structure_list) {
          const data = {
            position: new google.maps.LatLng(ele.loc_lat, ele.loc_lon),
            label: ele.loc_label,
          };
          this.markerData.push(data);
        }
      }
      else {
        for (let ele of structure_list) {
          let data;
          if ('color' in ele) {
            data = {
              position: new google.maps.LatLng(ele.lat, ele.lon),
              label: ele.label,
              color:ele.color
          };
          } else {
            data = {
              position: new google.maps.LatLng(ele.lat, ele.lon),
              label: ele.label
            };
          }
          this.markerData.push(data);
        }
      }
   //}

    // Create markers and attach click event listeners
    this.markerData.forEach((data: { position: any; label: any; color:any }) => {
      let marker: any;
      if (data.label.indexOf('LOC') != -1) {
        if (selectedLabel.indexOf(data.label) !== -1) {          
          marker = new google.maps.Marker({
              position: data.position,
              label: {
                text: data.label,
                color: 'white',
              },
              icon: this.marker_icon_FF0002,
              map: this.map,
            });
        }
        else {
          if (data.color == 'green') {
          marker = new google.maps.Marker({
              position: data.position,
              label: {
                text: data.label,
                color: 'white',
              },
              icon: this.marker_icon_FF0002,
              map: this.map,
            });
          } else {
            marker = new google.maps.Marker({
          position: data.position,
          label: {
            text: data.label,
            color: 'white',
          },
          icon: this.marker_icon_FF0001,
          map: this.map,
        });
        }
        
      }
     // } else if (data.label == selectedLabel) {
      } else if (selectedLabel.indexOf(data.label) !== -1) {
        if (this.inputData.message == 'select existing smp' || 
          this.inputData.message == 'select new smp') {
          marker = new google.maps.Marker({
            position: data.position,
            label: {
              text: data.label,
              color: 'white',
            },
            icon: this.marker_icon_FF0002,
            map: this.map,
          });
        } else {
          marker = new google.maps.Marker({
            position: data.position,
            label: {
              text: data.label,
              color: 'white',
            },
            icon: this.marker_icon_FF0003,
            map: this.map,
          });
        }
      } else {
        marker = new google.maps.Marker({
          position: data.position,
          label: {
            text: data.label,
            color: 'white',
          },
          icon: this.marker_icon_FF0000,
          map: this.map,
        });
      }

      // Add a click event listener to each marker
      marker.addListener('click', () => {
        // Handle the click event here, e.g., display the label
        this.handleMarker(marker);
        console.log('Marker clicked with label:', marker.getLabel());
      });

      // Push the marker object to the array
      this.markers.push(marker);
    });
  }

  handleMarker(_marker: any) {
    let label = _marker.getLabel();
    this.inputData['structure_id'] = label.text;
    this.myStrForm.setValue({
      structure: label.text,
    });
  }
  initMap(lat: any, long: any): void {
    this.map = new google.maps.Map(document.getElementById('map'), {
      center: { lat: lat, lng: long },
      zoom: 20,
      mapTypeId: 'satellite',
      // center: { lat: -25.274400, lng: 133.775100 },
      // zoom: 4
    });

    const marker = new google.maps.Marker({
      position: { lat: 37.7749, lng: -122.4194 },
      map: this.map,
      title: 'Marker Title',
    });
  }
  @HostListener('window:message', ['$event'])
  handleMessage(event: MessageEvent) {
    if (event.data.type === 'markerClick') {
      const label = event.data.label;
      // this.setFormValue(label)
      this.inputData['structure_id'] = label.text;
      console.log('Map clicked at:', this.inputData);
    }
  }
  
  OnSelectProceed() {
    this.OnSelectProceedMap.emit(this.inputData);
  }
  onselectStructure() { }
  
  receiveEvent(data: any) {
    /* for existing smp page we are not displaying multiple locations on MAP*/
    if(this.inputData.message=='select existing smp'){
      this.selected_data = [];
    }
    if (data.hasOwnProperty('smp_lat')) {
      this.selected_data.push(data.smp_label)
      if (data.new_location != '' && data.new_location != undefined) {
        for (let k = 0; k < this.inputData.location_cords_list.length; k++) {
          if (this.inputData.location_cords_list[k].loc_label == data.new_location) {
            this.selected_data.push(data.new_location);
            break;
          }
        }
      }
      else {
        for (let k = 0; k < this.inputData.location_cords_list.length; k++) {
          if (this.inputData.location_cords_list[k].loc_label == data.suggested_location_id) {
            this.selected_data.push(data.suggested_location_id);
            break;
          }
        }
      }
      
      this.enableProceedButton = true;
      this.initMap(data.smp_lat, data.smp_lon);
      this.addMarkers(this.structure_list_map_smp, this.selected_data);
      
      const myLatLng = { lat: data.smp_lat, lng: data.smp_lon };
      const marker = new google.maps.Marker({
        position: myLatLng,
        map: this.map,
        label: {
          text: data.smp_label,
          color: 'white',
        },
        icon: this.marker_icon_FF0002,
      })
    }
    else if (data.hasOwnProperty('lat')) { 
      this.selected_data.push(data.structure_id)
      this.initMap(data.lat, data.lon);
      this.addMarkers(this.structure_list_map, this.selected_data);
      const myLatLng = { lat: data.lat, lng: data.smp_lon };
      const marker = new google.maps.Marker({
        position: myLatLng,
        map: this.map,
        label: {
          text: data.label,
          color: 'white',
        },
        icon: this.marker_icon_FF0002,
      });
    }

    
  }


  receiveTableEvent(data: any, refresh = '') {
    if (this.inputData.message == 'select new smp') {
      this.selected_existing_smp = this.inputData.selected_smp;
    }
    if(data.smp_state == 'yes'){
        this.selected_existing_smp.push(data);
        this.inputData['selected_smp'] = this.selected_existing_smp;
        this.receiveEvent(data);
    }
    else if (data.hasOwnProperty('lat')) { 
      if (refresh == 'bjl_smp') { 
        if (this.inputData.message == 'select bjl structure sd1') {
          this.inputData['selected_bjl_structure'] = data;
        }
        else{
          this.inputData['selected_smp_structure'] = data;
        }
        this.receiveEvent(data);
      }
      else {
        this.selected_existing_smp.push(data);
        this.inputData['selected_smp'] = this.selected_existing_smp;
        this.receiveEvent(data);
      }        
    }
    else if(data.smp_state == 'no' || data.smp_state == 're_eval'){
        this.selected_existing_smp.push(data);
        this.inputData['selected_smp'] = this.selected_existing_smp;
    }
    if (refresh == 'yes') {
      this.selected_existing_smp = this.inputData.selected_smp;
    }    
    
  }

  BackCalled(){
      this.appItemEvent.emit(this.inputData)
  }
}
