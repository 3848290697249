<h3 *ngIf="map_div">{{ 'jl_structure' | titleText}} {{title}}</h3>
<div *ngIf="map_div" class="card">
            <app-table                 
                table_title="Upstream Equipment" 
                [inputData]="tableData"
                (appItemEvent)="receiveTableEvent($event)" >
            </app-table>

            <app-map
                
                [inputData]="inputData"
                (OnSelectProceedMap) = "OnSelectProceedMap($event)"
            ></app-map>
</div>

<div *ngIf="success_path">
    <app-lfn-path-generartion
        [inputData]="inputData"
        table_title = {{table_title}}
    ></app-lfn-path-generartion>
</div>

<!-- spinner Component Called-->
<div *ngIf="isLoading">
  <ngx-spinner type = "ball-scale-multiple"></ngx-spinner> 
</div>