<h3 *ngIf="selectEndEquipment">{{'structure' | titleText}}</h3>
<div class="" >

<div *ngIf="selectEndEquipment" class="card">
    
  <form [formGroup]="myEquForm" (ngSubmit)="onselectStructure()">
    <div class="form-group row">
        <div class="form-group col-md-6">
            <label for="ada">Equipment</label>
            <input
                type="text"
                id="selectedStructure "
                formControlName="equipment"
                [value]="myEquForm.get('equipment')"
                name="equipment"
                
            />
        </div>
    
        <div class="form-group col-md-6">
            <label for="ada">NEC</label>
            <input
                type="text"
                id="selectedStructure "
                formControlName="nec"
                [value]="myEquForm.get('nec')"
                name="nec"
                
            />
        </div>
    </div>
    <div class="form-group row">
        <div class="form-group col-md-6">
            <label for="ada">Equipment Type</label>
            <select
                  formControlName="equpType"
                  id="selectOption"
                  class="form-control"
            >
                  <option value="" selected>Select</option>
                  <option value="pcd">PCD</option>
                  <option value="budi">BUDI</option>
                  <option value="ctl">CTL</option>
            </select>
        </div>
    </div>

    <button
      type="submit"
      [class]="myEquForm.invalid ? 'gray-button' : ''"
      [disabled]="myEquForm.invalid"
    >
      Submit
    </button>
  </form>
  
</div>
<div ngClass="{{selectEndEquipment ? 'show' : 'hide'}}">
    <div style="margin-top: 20px" id="eq_map" style="width: 100%; height: 400px"></div>
</div>

</div>

<!--Success Path Component called-->
<div *ngIf="success_path">
    <app-lfn-path-generartion
        [inputData]="inputData"
        table_title = {{table_title}}
    >
    </app-lfn-path-generartion>
</div>

<!--SuccessPathDFN Component called-->
<div *ngIf="success_path_dfn">
  <app-dfn-path-generartion
    [inputData]="inputData"
    table_title = {{table_title}}
  ></app-dfn-path-generartion>
</div>

<!--Structure  Component called-->
<div *ngIf="structure_screen" class="">
    <app-structure-screen
        [inputData]="inputData"
    ></app-structure-screen>
</div>

<div *ngIf="upstream_screen">
  <app-lfn-upstream-equipment
    [inputData]="inputData"
    table_title="Upstream Equipment"
  ></app-lfn-upstream-equipment>
</div>

<!-- spinner Component Called-->
<div *ngIf="isLoading">
  <ngx-spinner type = "ball-scale-multiple"></ngx-spinner> 
</div>
