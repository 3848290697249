const awsExports = {
    "aws_project_region": "ap-southeast-2",
    "aws_cognito_region": "ap-southeast-2",
    "aws_user_pools_id": "ap-southeast-2_RXpP1JCFp",
    "aws_user_pools_web_client_id": "ujstqoqgelkfo2m0v9fof2qs3",
    "aws_appsync_apiKey": "",
    "aws_appsync_region": "ap-southeast-2",
    "aws_appsync_authenticationType": "AMAZON_COGNITO_USER_POOLS",
    "oauth": {
        "domain":"odms-pdc.auth.ap-southeast-2.amazoncognito.com",
        "scope": ["email", "openid","profile","aws.cognito.signin.user.admin"],
        "redirectSignIn": "localhost:4200",
        "redirectSignOut": "localhost:4200",
        "responseType": "code",
        "client_id": "ujstqoqgelkfo2m0v9fof2qs3"
    }
}
;
export default awsExports;