import { SelectionModel } from "@angular/cdk/collections";
import { CommonModule } from "@angular/common";
import { Component, EventEmitter, Input, OnInit, Output } from "@angular/core";
import { MatTableDataSource, MatTableModule } from '@angular/material/table';
import { MatTooltipModule } from '@angular/material/tooltip'; 
import { ReplaceTextPipe,TitleTextPipe } from "../../../custom.pipe";

@Component({
  selector: "app-table",
  standalone: true,
  imports:[CommonModule,MatTableModule,MatTooltipModule,ReplaceTextPipe,TitleTextPipe],
  templateUrl: "./table.component.html",
  styleUrls: ["./table.component.scss"]
})

export class TableComponent implements OnInit {
  refresh() {
    throw new Error("Method not implemented.");
  }
  @Input() inputData: any;
  @Input() table_title: string = '';
  tableData: any;
  displayedColumns: string[] = [];
  dataSource!: MatTableDataSource<any>;
  selection= new SelectionModel<any>(true, []);
  selectedRow: any;
  @Input() isCheckbox: boolean = false;
  @Input() isReadio: boolean = false;

  @Output() appItemEvent = new EventEmitter<any>();
  color = "rgb(148 0 234 / 75%)"
  text_color = "white"
  text_weight="700"

  constructor() { 

  }

  ngOnInit() {
    this.initializeFunction(this.inputData);

  }

    isAllSelected() {
    const numSelected = this.selection.selected.length;
    const numRows = this.dataSource.data.length;
    this.selectedRow = this.selection.selected;
    return numSelected === numRows;
  }

  masterToggle() {
    this.isAllSelected()
      ? this.selection.clear()
      : this.dataSource.data.forEach((row) => this.selection.select(row));
  }

  submitAction(data:any) {
    this.appItemEvent.emit(data);
  }

  initializeFunction(inputData:any) {
    this.inputData = inputData;
    // Display table data based of return message condition
    this.displayedColumns = this.inputData['table_columns'];
    if (this.inputData.message == 'select bjl structure dfn' || 
      this.inputData.message == 'select bjl structure' ||
      this.inputData.message == 'select ajl structure') {
      this.tableData = this.inputData.jl_structure_list;
      }
    else if (this.inputData.message == 'select mpt structure') {
      this.tableData = this.inputData.mpt_structure_list;
    }
    else if (this.inputData.message == 'select upstream equipment adr' || this.inputData.message == 'select upstream equipment') {
      this.tableData = this.inputData.upstream_equipment_list;
    }
    else if (this.inputData.message == 'select ajl upstream equipment') {
      this.tableData = this.inputData.ajl_upstream_equipment_list;
    }
    else if (this.inputData.message == 'select mpt upstream equipment') {
      this.tableData = this.inputData.mpt_upstream_equipment_list;
    }
    else if (this.inputData.message == 'path generation successful' ||
      this.inputData.message == 'path generation successful type2' || 
      this.inputData.message == 'path generation successful dfn' ||
      this.inputData.message == 'available paths adr') {
      for (let ele of this.inputData['available_paths']) {
        let updatedRawData = ele['raw_path'];
        let updatedTrenchData = ele['raw_trench'];
        ele['raw_trench_updated'] = updatedTrenchData.join('=> ');
        ele['raw_path_updated'] = updatedRawData.join('=> ');
        
      }
      this.tableData = this.inputData['available_paths'];
      
      this.displayedColumns = this.displayedColumns.concat('raw_trench_updated');
      this.displayedColumns = this.displayedColumns.concat('raw_path_updated');
      

      // Remove raw_trench and raw_path from display column 
      this.displayedColumns.splice(this.displayedColumns.indexOf('raw_path'), 1);
      this.displayedColumns.splice(this.displayedColumns.indexOf('raw_trench'), 1);
    }
    this.displayedColumns = this.displayedColumns.concat('actions');
    
  }


}
