<div *ngIf="showTable" class = "card">
    <h3>{{'path_dfn' | titleText}}</h3>
<div *ngIf="showTable">
        <app-table         
            table_title="Available Paths" 
            [inputData]="inputData" 
            (appItemEvent)="receiveTableEvent($event)">
        </app-table>
</div>
</div>

<div *ngIf="showJlStructure">
    <app-jl-structure
        [tableData]="tableData"
        [inputData]="inputData"
        table_title={{table_title}} 
    ></app-jl-structure>
</div>

<!-- <div>
    <app-jl-structure
                [tableData]="tableData"
                [inputData]="inputData"
                table_title="Available Path"                           
    ></app-jl-structure>
</div> -->