import { Component, Output, EventEmitter, OnInit, ViewChild, ElementRef, Input } from "@angular/core";
import { FormBuilder, FormGroup, ReactiveFormsModule, Validators } from "@angular/forms";
import { ToastrService } from 'ngx-toastr';
import { ApiService } from "../../../api.service";
import { CommonModule } from "@angular/common";
import { Observable } from "rxjs";
import { NgxSpinnerService, NgxSpinnerModule } from 'ngx-spinner';


@Component({
  selector: 'app-upload-file',
  standalone: true,
  imports: [ReactiveFormsModule,
    CommonModule,
    NgxSpinnerModule
  ],
  templateUrl: './upload-file.component.html',
  styleUrl: './upload-file.component.scss'
})
export class UploadFileComponent implements OnInit {
  @Output() uploaded = new EventEmitter<string>();
  // @Input() myAdrForm!: FormGroup;
  @Input() showADRCard: boolean = false;
  @Input() inputData: any = {};
  @Input() selectedRow: any = {};
  @Output() goBackCall = new EventEmitter<string>();


  @Input() flowName: string = '';
  @ViewChild('fileInput', { static: false }) pathVariable: ElementRef<HTMLInputElement> = {} as ElementRef;
  myUploadForm: any = FormGroup;
  formData: FormData = new FormData();
  status: "initial" | "uploading" | "success" | "fail" = "initial"; // Variable to store file status
  file: File | null = null; // Variable to store file
  showUpload: boolean = true;


  constructor(private toastr: ToastrService,
    private fb: FormBuilder,
    private spinner: NgxSpinnerService,
    private apiService: ApiService) {

    this.myUploadForm = this.fb.group({
      filePath: ['', Validators.required],

    });

  }

  ngOnInit() {

  }


  // ******Upload mdu/adr file to server ///////////
  onUpload() {
    if (this.file) {
      const formData = new FormData();
      formData.append('file', this.file, this.file.name);
      const upload$ = this.apiService.uploadFile(formData);
      this.status = 'uploading';
      this.spinner.show();
      let check_filename = this.file.name.includes(this.flowName)
      if (check_filename == false) {
        this.toastr.error(
          `Invalid file name. Please follow file naming convention. `,
          'Error Message'
        );
      }
      else {
        upload$.subscribe({
          next: (response: { message: string }) => {
            if (response.message == 'MDU_ADR exists') {
              this.toastr.error('File exists! Please change the name of the file and upload again', 'Error Message');
              this.pathVariable.nativeElement.value = '';
              this.status = 'fail'

            }
            else if (response.message == 'MDU_ADR_created')
              this.status = 'success';
            this.spinner.hide();
            this.uploaded.emit(this.file?.name);

          },
          error: (error: any) => {
            this.spinner.hide();
            this.status = 'fail';

            this.toastr.error(
              `The API returned Unsuccessful response : ${error}`,
              'Error Message'
            );
          },
        });
      }
    }
    else {
      this.toastr.error('Please select file to upload', 'Error Message');

    }
  }

  selectFileToUpload(event: any) {

    if (event.target.files && event.target.files[0]) {
      const file: File = event.target.files[0];
      if (file) {
        this.status = "initial";
        this.file = file;
        console.log('size', file.size);
        // check format of file.
        if (file.type == 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' || file.type == 'application/vnd.ms-excel') {

        }
        else {
          this.toastr.error(
            `File type must be xlsx or xls`,
            'Error Message'
          );
          this.pathVariable.nativeElement.value = '';
          this.status = 'fail'
          this.showUpload = true;
        }

        // check size of file
        if (file.size > 5000000) {
          this.toastr.error(
            `File size must be within 5MB`,
            'Error Message'
          );
          this.pathVariable.nativeElement.value = '';
          this.status = 'fail'

        }

      }
    }
  }

  goBack() {
    console.log('Go Back called...')
    //this.location.back();
    this.goBackCall.emit(this.inputData);
  }

}
