

<div *ngIf="showADRCard" class="card">
    <h3 *ngIf="showADRCard">{{'adr' | titleText}}</h3>
    
    <div *ngIf="showADRCard">
        <app-upload-file [flowName]="flowName" (uploaded)="fileUploaded($event)" [showADRCard]="showADRCard"
                [inputData]="inputData" [selectedRow]="selectedRow">
        </app-upload-file>
        
        <form [formGroup]="myAdrForm" (ngSubmit)="table_function_called()">

            <div class="row">
                <div class="col-md-3 form-group">
                    <button type="submit" class="primary">
                            Submit
                    </button>
                </div>

            </div>
        </form>
    </div>

</div>
<div *ngIf="showTable" class="card">
    <h3 *ngIf="showTable">{{'path_adr' | titleText}}</h3>
    <div *ngIf="showTable">
        <app-table
            table_title="Available Paths" 
            [inputData]="inputData" 
            (appItemEvent)="receiveTableEvent($event)"
        >
        </app-table>

    </div>
</div>

<div *ngIf="showMessage" class="card">
        <app-message 
            [showMessage]="showMessage"
            [MessageToShow]="MessageToShow"
            url={{url}}
            msg={{msg}}
            filename={{filename}}
        >
        </app-message>
</div>

<!-- spinner Component Called-->
<div *ngIf="isLoading">
  <ngx-spinner type="ball-scale-multiple"></ngx-spinner>
</div>