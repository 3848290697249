<div class="form-group row">
  <div class="form-group col-md-10">
  <form
    style="margin-top: 20px; margin-bottom: 20px"
    [formGroup]="myForm"
  >
    <div class="form-group">
      <label style="display: inline" for="FJL required"
        >New Duct Required:
      </label>
      <input
        formControlName="FJLState"
        (change)="radioSelect($event)"
        type="radio"
        id="option1"
        value="Yes"
      />
      <label style="display: inline" for="Yes">YES </label>
      <input
        formControlName="FJLState"
        (change)="radioSelect($event)"
        type="radio"
        id="option2"
        value="No"
        checked
      />
      <label style="display: inline" for="No">NO</label>
    </div>
  </form>
</div>
<div class="form-group col-md-2" *ngIf="overwrite_flag_val">
  <button (click)="callBack()">Back</button>
</div>
</div>




<div *ngIf="isLoading">
    <ngx-spinner type="ball-scale-multiple"></ngx-spinner>
</div>
