<h2>ADR Approach</h2>
<div *ngIf="showMainPage" class="card">
  <h3>Application Details ADR</h3>

  <form [formGroup]="myForm" (ngSubmit)="onSubmit()">
    <div class="form-group">
      <label class="lable-css radio-inline" for="FJL required">Type of Work:
      </label>
      <input class="radio-inline" formControlName="workType" (change)="radioSelect()" type="radio" id="option1"
        value="LFN" />
      <label class="lable-css radio-inline" for="LFN">LFN </label>
      <input class="radio-inline" formControlName="workType" (change)="radioSelect()" type="radio" id="option2"
        value="DFN" />
      <label class="lable-css radio-inline" for="DFN">DFN</label>
    </div>
    <div class="form-group row">
      <div class="form-group col-md-6">
        <label class="lable-css">Tech Type:</label>
        <select formControlName="techType" id="techType" class="form-select">
          <option value="" selected>Select</option>
          <option value="BDOD">BDOD</option>
          <option value="GPON">GPON/SD2</option>
          <option value="SD1">SD1</option>
        </select>
      </div>
      <!-- <div class="form-group col-md-6">
        <label class="lable-css">Built Complexity Type:</label>
        <select formControlName="builtType" id="builtType" class="form-select">
          <option value="" selected>Select</option>
          <option value="Rapid">Rapid</option>
          <option value="Ultra">Ultra</option>
          <option value="Standard">Standard</option>
          <option value="Standard">XL</option>
        </select>
      </div> -->
      <div class="form-group col-md-6">
        <label class="lable-css">UFA Category:</label>
        <select formControlName="ufaCategory" id="ufaCategory" class="form-control">
          <option value="" selected>Select</option>
          <option value="Low">Low</option>
          <option value="Medium">Medium</option>
          <option value="High">High</option>
        </select>
      </div>
      
    </div>
    <div class="form-group row">
      
      <div class="form-group col-md-6">
        <label class="lable-css">Architecture Type:</label>
        <select formControlName="architectureType" id="architectureType" class="form-control">
          <option *ngFor="let architectureType of architectureOptions;" [ngValue]="architectureType">
            {{architectureType}}</option>

        </select>
      </div>
      <div class="form-group col-md-6">
        <label for="ada" class="lable-css">ADA ID:</label>
        <input type="text" id="ada" formControlName="adaId" name="ada" placeholder="Enter ADA ID" required />
      </div>
    </div>
    <div class="form-group row">
      <div class="form-group col-md-6">
        <label for="ada" class="lable-css">ROR ID:</label>
        <input type="text" id="ror" formControlName="rorId" pathname="ror" placeholder="Enter ROR ID" required />
      </div>
      <div class="form-group col-md-6">
        <label for="loc" class="lable-css">LOC ID:</label>
        <input type="text" id="loc" formControlName="locId" name="loc" placeholder="Enter LOC ID" required />
      </div>
    </div>
    <div class="form-group row">
      
      <!-- <div class="form-group col-md-6">
        <label for="ada" class="lable-css">S18 PATH:</label>
        <input type="text" 
        id="path" name="path"  
             
        placeholder="Enter S18 File Path" required /> -->
        <!-- formControlName="S18path" -->
      <!-- </div> -->
    </div>
    @if (showLFNSubmit) {
    <button type="submit" [class]="myForm.invalid ? 'gray-button' : ''" [disabled]="myForm.invalid">
      Submit
    </button>
    }
  </form>
  
</div>
<!-- DFN Component Called-->
<div *ngIf="showDFNCard" >
    <app-dfn-djl-flow 
      [inputData]="myForm"
      (disableMainForm)="disableMainForm()"
      
    ></app-dfn-djl-flow>
  </div>

<!-- MDU Component Called-->
<div  *ngIf="showMDUCard">
    <app-mdu-form
      [mduPayLoad]="mduPayLoad"
      [inputData] = "mduPayLoad"
    ></app-mdu-form>
</div>

<!--ADR Component called-->
<div *ngIf="showADRCard">
  <app-adr-form 
    [inputData]="adrPayLoad" 
    [showADRCard]="showADRCard"
  ></app-adr-form>
</div>
<!-- Equipment Component Called-->
<div *ngIf="showEquipmetCard">
    
  <app-lfn-equipment-flow
    [inputData]="equPayLoad" 
    [equPayLoad]="equPayLoad" 
    [showEquipmetCard]="showEquipmetCard"
    table_title="Equipment Flow" 
    ></app-lfn-equipment-flow>
</div>

<!-- Success path Component Called-->
<div *ngIf="success_path">
    <app-lfn-path-generartion 
      table_title="Available Paths" 
      [inputData]="tableData"
    ></app-lfn-path-generartion>
</div>

<div *ngIf="upstream_screen">
  <app-lfn-upstream-equipment
    [inputData]="tableData"
    table_title="Upstream Equipment"
  ></app-lfn-upstream-equipment>
</div>

<div *ngIf="showEndEquipment">
  <app-end-equipment-screen
    [inputData] = "tableData"
  >
  </app-end-equipment-screen>
</div>

<!-- spinner Component Called-->
<div *ngIf="isLoading">
  <ngx-spinner type = "ball-scale-multiple"></ngx-spinner> 
</div>