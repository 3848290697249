<h3 *ngIf="map_div">{{ 'jl_structure' | titleText}} {{title}}</h3>
<div *ngIf="map_div">
  <app-map-sd1                
                [inputData]="inputData"
                (OnSelectProceedMap) = "OnSelectProceedMap($event)"
            ></app-map-sd1>
          </div>
<div *ngIf="map_div" class="card">       

            <app-table                 
                table_title="smp_connectivity_table" 
                [inputData]="inputData"
                (appItemEvent)="receiveTableEvent($event)" 
              >
            </app-table>
</div>

<div *ngIf="select_bjl">
  <!-- <app-select-bjl-sd1                 
      table_title="selectBjl" 
      [inputData]="inputData"
    >
  </app-select-bjl-sd1> -->
  <div *ngIf="select_bjl" class = "card">
    <h3>{{'select_bjl' | titleText}}</h3>

    <div *ngIf="select_bjl" class="form-group col-md-2">
        <button (click)="callBJL()">Create New BJL</button>
    </div>

    <div *ngIf="select_bjl">
            <app-table         
                table_title="Select BJL" 
                [inputData]="inputData" 
                (appItemEvent)="receiveTableEventBJL($event)">
            </app-table>
    </div>
</div>
</div>

<div *ngIf="select_smp_screen">
          <app-map-sd1              
                [inputData]="inputData"
                (OnSelectProceedMap) = "OnSelectProceedMapSelectSMP($event)"
            ></app-map-sd1>

            <app-table                 
                table_title="selectBjl" 
                [inputData]="inputData"
                (appItemEvent)="receiveTableEventStructureSelectSMP($event)" >
            </app-table>
</div>

<div *ngIf="upstream_screen">
<h3>{{'upstream' | titleText }}</h3>
<div  class="card">
    <app-table 
      
        [inputData]="inputData"
        (appItemEvent)="receiveTableEventUpstream($event)" >
    </app-table>
</div>
</div>

<div *ngIf="final_screen" class = "card">
    <h3>{{'final_screen' | titleText }}</h3>
    <div style="margin-top: 20px; display: flex; align-items: center">
        <button (click)="receiveTableEventFinalCall()">Click To Proceed</button>
    </div>
    
    <app-table       
        [inputData]="inputData"
    >
    </app-table>
</div>

<div *ngIf="showMessage" class="card">
        <app-message 
            [showMessage]="showMessage"
            [MessageToShow]="MessageToShow"
            url={{url}}
            msg={{msg}}
            filename={{filename}}
        >
        </app-message>
</div>

<div *ngIf="showADRCard">
    <h3 *ngIf="showADRCard">{{'adr' | titleText}}</h3>
    <app-upload-file [flowName]="flowName" (uploaded)="fileUploaded($event)" [showADRCard]="showADRCard"
            [inputData]="inputData" [selectedRow]="selectedRow" >
    </app-upload-file>
    <form [formGroup]="myAdrForm" (ngSubmit)="table_function_called()">

        <div class="row">
            <div class="col-md-3 form-group">
                <button type="submit" class="primary">
                        Submit
                </button>
            </div>
        </div>
    </form>
    <app-table       
        [inputData]="inputData"
    >
    </app-table>
</div>
<!-- spinner Component Called-->
<div *ngIf="isLoading">
  <ngx-spinner type = "ball-scale-multiple"></ngx-spinner> 
</div>