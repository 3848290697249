import { Component, Input, OnInit, Output, ViewChild } from "@angular/core";
import { NgxSpinnerService, NgxSpinnerModule } from 'ngx-spinner';
import { HttpClient } from "@angular/common/http";
import { FormBuilder, FormGroup } from "@angular/forms";
import { ToastrService } from "ngx-toastr";
import { Router } from '@angular/router';
import { CommonModule } from "@angular/common";
import { ApiService } from "../../../api.service";
import { TitleTextPipe } from "../../../custom.pipe";
import { AdrFormComponent } from "../../common/adr-form/adr-form.component";
import { MapComponent } from "../../common/map/map.component";
import { StructureScreenComponent } from "../../common/structure-screen/structure-screen.component";
import { TableComponent } from "../../common/table/table.component";
import { JlStructureOverwriteComponent } from "../../common/jl-structure-overwrite/jl-structure-overwrite.component";

@Component({
  selector: "app-lfn-upstream-equipment-overwrite",
  standalone: true,
  imports: [
    TableComponent,    
    StructureScreenComponent,
    JlStructureOverwriteComponent,
    NgxSpinnerModule,
    CommonModule,TitleTextPipe,AdrFormComponent
  ],
  templateUrl: "./lfn-upstream-equipment-overwrite.component.html",
  styleUrls: ["./lfn-upstream-equipment-overwrite.component.scss"]
})

export class LfnUpstreamEquipmentOverwriteComponent implements OnInit {
  
  @Input() inputData: any;
  @Input() table_title: string = '';
  tableData: any;
  displayedColumns: string[] = [];
  isLoading: boolean = false;   
  showTable: boolean = true;
  showMap: boolean = false;
  showMessage: boolean = false;
  MessageToShow: any;
  showADRCard = false;

  @ViewChild(TableComponent)
  tableChildComponent!: TableComponent;

  @ViewChild(MapComponent)
  mapChildComponent!: MapComponent;


  showEquipmetTable: boolean = false;
  map_div: boolean = false;
  upstream_screens: boolean = true;
  structure_screen: boolean = false;

  constructor(
    private fb: FormBuilder,
    private http: HttpClient,
    private toastr: ToastrService,
    private apiService: ApiService,
    private spinner: NgxSpinnerService,
    private router:  Router
  ) {

  }

  receiveTableEvent(data: any) {

    if ('structure_id' in data) {
      this.viewProfile(data);
    }
  }

  viewProfile(data: any) {   
    // this.isLoading = true;
    // this.spinner.show();    
    this.isLoading = true;
    this.spinner.show();
    if (
      ['select mpt upstream equipment','select upstream equipment overwrite', 'select upstream equipment','select ajl upstream equipment'].includes(
        this.inputData.message
      )
    ) {
      this.inputData['selected_upstream_equipment'] = data;
      if (this.inputData.message == 'select ajl upstream equipment') {
        this.inputData['selected_ajl_upstream_equipment'] = [data];
      }
      
      this.apiService.selectUpstremEquipment(this.inputData).subscribe({
        next: (response) => {
          if (
            ['select bjl structure', 'select mpt structure', 'select ajl structure'].includes(
              response.message
            )
          ) {
            this.inputData = response;
            this.showTable = false;
            this.showEquipmetTable = false;
            this.tableData = response;
            this.tableChildComponent.initializeFunction(this.inputData);

            this.showMap = true;
            // this.mapData = data;
            this.map_div = true;
            this.upstream_screens = false;

          } else if (response.message === 'receive adr') {
            this.router.navigateByUrl('/adr');
          } else {
            this.toastr.error(
              response.message,
            'Error Message'
          );
            // this.showMessage = true;
            // this.MessageToShow = JSON.stringify(response);
          }
          this.isLoading = false;
          this.spinner.hide();
          // Handle the response data
        },
        error: (error) => {
          this.isLoading = false;
          this.spinner.hide();
          this.toastr.error(
            `The API returned Unsuccessfull response : ${error}`,
            'Error Message'
          );
        },
      });

    }
    else {
      this.isLoading = false;
      this.spinner.hide();
      this.inputData['selected_path'] = data;
    }
  }

  ngOnInit() {

  }
}
