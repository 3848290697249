import { Component, Input, OnInit, ViewChild } from "@angular/core";
import { LfnPathGenerartionComponent } from "../lfn-path-generartion/lfn-path-generartion.component";
import { TitleTextPipe } from "../../../custom.pipe";
import { NgxSpinnerModule, NgxSpinnerService } from "ngx-spinner";
import { CommonModule } from "@angular/common";
import { TableComponent } from "../../common/table/table.component";
import { MatTableDataSource } from "@angular/material/table";
import { Router } from "@angular/router";
import { ToastrService } from "ngx-toastr";
import { ApiService } from "../../../api.service";
import { LfnUpstreamEquipmentOverwriteComponent } from "../lfn-upstream-equipment-overwrite/lfn-upstream-equipment-overwrite.component";

@Component({
  selector: "app-lfn-select-bjl",
  standalone: true,
  imports: [
    TableComponent,
    CommonModule,
    NgxSpinnerModule,
    TitleTextPipe,
    LfnPathGenerartionComponent,
    LfnUpstreamEquipmentOverwriteComponent,
    LfnPathGenerartionComponent
  ],
  templateUrl: "./lfn-select-bjl.component.html",
  styleUrls: ["./lfn-select-bjl.component.scss"]
})

export class LfnSelectBjlComponent implements OnInit {
  
  @Input() inputData : any;
  tableData: any;
  displayedColumns: string[] = [];
  dataSource!: MatTableDataSource<any>;
  showBJLTable: boolean = true;
  isLoading: boolean = false;
  showMessage: boolean = false;
  MessageToShow: any;
  success_path: boolean = false;
  select_table = true;
  upstream_screen = false;
  @ViewChild(TableComponent)
  tableChildComponent!: TableComponent;
  
  constructor(private toastr: ToastrService,
    private apiService: ApiService,
    private spinner: NgxSpinnerService,
    private router: Router) { 

  }

  ngOnInit() {

  }

  callSelectBjl(data:any) {
    this.spinner.show();
    console.log('goBackCall in parent.........');
    data.message = "select bjl"
    data.table_columns= [
        "bjl_id",
        "distance",
        "double_stack"
    ]
    this.inputData = data;
    this.showBJLTable = true;
    this.success_path = false;
    this.tableChildComponent.initializeFunction(this.inputData);
    this.spinner.hide();
  }

  receiveTableEvent(data:any) {
    this.inputData['selected_bjl'] = data;
    this.isLoading = true;
    this.spinner.show()  
    this.apiService.selectBjl(this.inputData).subscribe({
      next: (response) => {
//         response={
//     "ada_id": "2HAM-13-10",
//     "loc_id": "LOC100046932216",
//     "s18_path": "C:\\Users\\sasi.s.atmakuri\\Documents\\Double Stacking Data\\2HAM-13-10",
//     "ror_id": "ROR000000493895",
//     "tech_type": "BDOD",
//     "loc_coordinate": [
//         151.747372,
//         -32.920205
//     ],
//     "loc_type": "MCU1",
//     "cadastre_id": "NCAz0000012729720",
//     "ufa_category": "Medium",
//     "architecture_type": "Type3",
//     "ada_tech_type": "FTTN",
//     "fjl_with_capacity_list": [
//         "2HAM-13-10-FJL-302",
//         "2HAM-13-10-FJL-301"
//     ],
//     "message": "path generation successful",
//     "mps_boundary": "",
//     "mdu_name": "",
//     "available_pcd_list": [],
//     "available_nec_list": [
//         {
//             "nec_id": "000000015014500862",
//             "label": "000000015014500862",
//             "lat": -32.920216,
//             "lon": 151.747449
//         }
//     ],
//     "selected_nec": "000000015014500862",
//     "equipment": "PCD",
//     "pcd_list": [
//         {
//             "id": "000000015014500862",
//             "equipment_id": "2HAM-13-10-PCD-804"
//         }
//     ],
//     "bjl_list": [
        
//         {
//             "bjl_id": "2HAM-13-10-BJL-644",
//             "distance": 140.6,
//             "double_stack": "No"
//         },
//         {
//             "bjl_id": "2HAM-13-10-BJL-642",
//             "distance": 187.6,
//             "double_stack": "No"
//         },
//         {
//             "bjl_id": "2HAM-13-10-BJL-641",
//             "distance": 205.1,
//             "double_stack": "Yes"
//         },
//         {
//             "bjl_id": "2HAM-13-10-BJL-645",
//             "distance": 250.4,
//             "double_stack": "No"
//         },
//         {
//             "bjl_id": "2HAM-13-10-BJL-647",
//             "distance": 438.1,
//             "double_stack": "Yes"
//         }
//     ],
//     "table_columns": [
//         "bjl_id",
//         "distance",
//         "double_stack"
//     ]
// }
            if (response.message === 'path generation successful' || response.message === 'path generation successful type2') {
              this.showBJLTable = false;
              this.success_path = true;
              this.upstream_screen = false;
              this.inputData = response;
            }
            else if (response.message == 'select bjl') {
              this.showBJLTable = true;
              this.success_path = false;
              this.upstream_screen = false;
              this.inputData = response;
              this.tableData = response;
              this.tableChildComponent.initializeFunction(this.inputData);
            }
            else {              
              if ('file_name' in response) {
                this.showBJLTable = false;
                this.showMessage = true;
                this.MessageToShow = response;
              } else {
                this.toastr.error(response.message, 'Error Message');
                console.log('error');
              }
            }

            this.isLoading = false;
            this.spinner.hide();
            // window.alert(response.messgae);
      },
      error: (error) => {
            this.isLoading = false;
            this.spinner.hide();
            this.toastr.error(
              `The API returned Unsuccessfull response : ${error}`,
              'Error Message'
            );
            //window.alert(`The API returned Unsuccessfull response : ${error}`);
          },
    });
  }

  callBJL() {
    this.apiService.bjlpathoverwrite(this.inputData).subscribe({
        next: (response) => {
          if (response.message == 'select upstream equipment overwrite') {
            this.upstream_screen = true;
            this.showBJLTable = false;
            this.inputData = response;
          } else {
            //this.showTable = false;
            this.toastr.error(
            `The API returned Unsuccessfull response`,
            'Error Message'
          );
          }

          this.isLoading = false;
          this.spinner.hide();
        },
        error: (error) => {
          this.isLoading = false;
          this.spinner.hide();
          this.toastr.error(
            `The API returned Unsuccessfull response : ${error}`,
            'Error Message'
          );
        },
      });
  }
}
