import { CommonModule } from "@angular/common";
import { Component, Input, OnInit, ViewChild } from "@angular/core";
import { MapComponent } from "../map/map.component";
import { TableComponent } from "../table/table.component";
import { FormGroup, FormBuilder, Validators } from "@angular/forms";
import { ToastrService } from "ngx-toastr";
import { ApiService } from "../../../api.service";
import { NgxSpinnerService, NgxSpinnerModule } from 'ngx-spinner';
import { TitleTextPipe } from "../../../custom.pipe";
import { LfnPathGenerartionOverwriteComponent } from "../../lfn-flow/lfn-path-generartion-overwrite/lfn-path-generartion-overwrite.component";

@Component({
  selector: "app-jl-structure-overwrite",
  standalone: true,
  imports: [
    TableComponent,
    CommonModule,
    MapComponent,
    LfnPathGenerartionOverwriteComponent,
    NgxSpinnerModule,TitleTextPipe
  ],
  templateUrl: "./jl-structure-overwrite.component.html",
  styleUrls: ["./jl-structure-overwrite.component.scss"]
})

export class JlStructureOverwriteComponent implements OnInit {
  
  isLoading: boolean = false;
  @Input() table_title: string = '';
  myEquipmetForm: any = FormGroup;
  @Input() tableData: any;
  @Input() showEquipmetCard: boolean = true;
  showTable: boolean = false;
  table2Data: any;
  showType2Table: boolean = false;
  showMap: boolean = false;
  mapData: any;
  showMessage: boolean = false;
  MessageToShow: any;
  @Input() inputData: any;
  
  @ViewChild(TableComponent)
  tableChildComponent!: TableComponent;

  @ViewChild(MapComponent)
  mapChildComponent!: MapComponent;

  @ViewChild(LfnPathGenerartionOverwriteComponent)
  lfnPathGeneration!: LfnPathGenerartionOverwriteComponent;

  showEquipmetTable: boolean = false;
  map_div: boolean = true;
  success_path: boolean = false;
  upstream_screens: boolean = true;
  structure_screen: boolean = false;
  title : string = '';

  constructor(private toastr: ToastrService,
    private fb: FormBuilder,
    private apiService: ApiService,
    private spinner: NgxSpinnerService
  )
  { 
      this.myEquipmetForm = this.fb.group({
            equpType: ['', Validators.required],
          });
  }
  
  ngOnInit() {
    
  if (this.inputData.message.includes(' mpt ')) {
      this.title = 'MPT';
    }
    else if (this.inputData.message.includes(' bjl ')) {
      this.title = 'BJL';      
    }
    else if (this.inputData.message.includes(' ajl ')) {
      this.title = 'AJL';      
    } 
  }
  

  receiveTableEvent(data:any) {    
    if ('structure_id' in data) {
      this.viewProfile(data);
    }
  }

  viewProfile(data: any) {  
    // on select button clicked on table it'll call map component for pin point the structure id
    this.isLoading = true;
     
    if(
            ['select bjl structure', 'select mpt structure','select ajl structure','select bjl structure dfn'].includes(
              this.inputData.message
            )
    ) {
        if ('lat' in data) {
          this.mapChildComponent.receiveTableEvent(data)
        }
    }
      else if (
      ['select mpt upstream equipment', 'select upstream equipment','select ajl upstream equipment'].includes(
        this.inputData.message
      )
    ) {
      this.inputData['selected_upstream_equipment'] = data;
      if (this.inputData.message == 'select ajl upstream equipment') {
        this.inputData['selected_ajl_upstream_equipment'] = [data];
      }
      
      this.apiService.selectUpstremEquipment(this.inputData).subscribe({
        next: (response) => {
          if(
            ['select bjl structure', 'select mpt structure','select ajl structure','select bjl structure dfn'].includes(
              response.message
            )
          ) {
            this.inputData = response;
            this.showTable = false;
            this.showEquipmetTable = false;
            this.tableData = response;
            this.tableChildComponent.initializeFunction(this.inputData);
            
            this.showMap = true;
            this.mapData = data;
            this.map_div = true;
            
          } else {
            this.toastr.error(
              response.message,
            'Error Message'
          );
            // this.showMessage = true;
            // this.MessageToShow = JSON.stringify(response);
          }
          this.isLoading = false;
          
          // Handle the response data
        },
        error: (error) => {
          this.isLoading = false;
          
          this.toastr.error(
            `The API returned Unsuccessfull response : ${error}`,
            'Error Message'
          );
          //window.alert(`The API returned Unsuccessfull response : ${error}`);
        },
      });
 
    }
    else {
      this.inputData['selected_path'] = data;
    }

    // this.inputData["available_paths"][0]= data;
  }

  OnSelectProceedMap(data: any) {
    // On Button click call jl structure api and based on response trigger next step
    
    this.isLoading = true;
    this.spinner.show();
    this.showTable = false;
    this.apiService.selectjlStructure(this.inputData).subscribe({
        next: (response) => {
        if (response.message === 'path generation successful type2' || response.message === 'path generation successful'
          
        ) {
            this.map_div = false;
            this.inputData = response;
            this.inputData['equipment_flow'] = false;
            this.upstream_screens = false;
            this.success_path = true;
            
          
        }
        else if (response.message === 'select mpt structure') {
            alert('Please select MPT structure id.');
            this.inputData = response;
            this.showTable = false;
            this.showEquipmetTable = false;
            this.tableData = response;
            this.tableChildComponent.initializeFunction(this.inputData);
            
            this.showMap = true;
            this.mapData = data;
            this.map_div = true;
            this.mapChildComponent.receiveTableEvent(this.inputData)
        }
        else if (response.message === 'select bjl structure') {
          alert('Please select BJL structure id.');
            this.inputData = response;
            this.showTable = false;
            this.showEquipmetTable = false;
            this.tableData = response;
            this.tableChildComponent.initializeFunction(this.inputData);
            
            this.showMap = true;
            this.mapData = data;
            this.map_div = true;
            this.mapChildComponent.receiveTableEvent(this.inputData)
        }
        else {
            this.toastr.error(
                response.message,
              'Error Message'
            );
            // this.showMessage = true;
            // this.MessageToShow = JSON.stringify(response);
          }

        this.isLoading = false;
        this.spinner.hide();
          // Handle the response data
        },
        error: (error) => {
          this.isLoading = false;
          this.spinner.hide();
          window.alert(`The API returned Unsuccessfull response : ${error}`);
        },
      });
    
  }
}
