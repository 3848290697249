<!-- <div class="header">
    <div class="d-flex flex-wrap align-items-center justify-content-center justify-content-lg-start">
        <div class="img-div">               
                <img class="img"
                    src="../../../../assets/images/logo.png" />                
        </div>
        <div><h4>{{title}}</h4></div>
        <div class="icon">
            <mat-icon aria-hidden="false" aria-label="Logout" fontIcon="logout" (click)="signOutCall()"></mat-icon>
        </div>
    </div>
</div> -->
<div id="header">
    <div id="logo"><img class="img"
                    src="../../../../assets/images/logo.png" /> </div>
    <div class="title cusror-hover"><h4 (click)="realodPage('home')"> ODMS</h4></div>
    
    <div id="links">
        <div class="title cusror-hover"><h4 (click)="realodPage('home')"> Home</h4></div>
        <div class="title cusror-hover"><h4 (click)="realodPage('adr')"> ADR</h4></div>
        <div class="title cusror-hover"><h4 (click)="realodPage('sd1')"> SD1</h4></div>
        <!-- <div routerLink="" class="list cusror-hover"><h4 (click)="realodPage()">Home</h4></div>
        <div routerLink="" class="list"><h4>|</h4></div> -->
        <div routerLink="" class="list cusror-hover"><h2><mat-icon aria-hidden="false" aria-label="Logout" fontIcon="logout" (click)="signOutCall()"></mat-icon></h2></div>
    </div>
</div>