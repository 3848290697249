import { CommonModule } from "@angular/common";
import { Component, Input, OnInit } from "@angular/core";
import { MatCardModule } from '@angular/material/card';
import { TitleTextPipe } from "../../../custom.pipe";

@Component({
  selector: "app-message",
  standalone: true,
  imports: [MatCardModule,
    CommonModule,TitleTextPipe
  ],
  templateUrl: "./message.component.html",
  styleUrls: ["./message.component.scss"]
})

export class MessageComponent implements OnInit {
  @Input() MessageToShow: any = {};
  @Input() showMessage: boolean = false;
  @Input() url: string = '';
  @Input() msg: string = '';
  @Input() filename: string = '';
  
  constructor() { 

  }

  ngOnInit() {
    

  }
}
