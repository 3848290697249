<h3 *ngIf="selectStructure">{{'structure' | titleText}}</h3>
<div class="" >
<div *ngIf="selectStructure" class="card">
  <form [formGroup]="myStrForm" (ngSubmit)="onselectStructure()">
    <div class="form-group">
      <label for="ada">Select Structure</label>
      <input
        type="text"
        id="selectedStructure "
        formControlName="structure"
        [value]="myStrForm.get('structure')"
        name="Structure"
        required
      />
    </div>
    <button
      type="submit"
      [class]="myStrForm.invalid ? 'gray-button' : ''"
      [disabled]="myStrForm.invalid"
    >
      Submit
    </button>
  </form>
  
</div>
<div ngClass="{{selectStructure ? 'show' : 'hide'}}">
    <div style="margin-top: 20px" id="st_map" style="width: 100%; height: 400px"></div>
</div>

</div>

<!--Success Path Component called-->
<div *ngIf="success_path">
    <app-lfn-path-generartion
        [inputData]="inputData"
        table_title = {{table_title}}
    >
    </app-lfn-path-generartion>
</div>

<!--SuccessPathDFN Component called-->
<div *ngIf="success_path_dfn">
  <app-dfn-path-generartion
    [inputData]="inputData"
    table_title = {{table_title}}
  ></app-dfn-path-generartion>
</div>

<!--ADR Component called-->
<div *ngIf="showADRCard">
  <app-adr-form 
    [inputData]="inputData" 
    [showADRCard]="showADRCard"
  ></app-adr-form>
</div>

<div *ngIf="upstream_screen">
  <app-lfn-upstream-equipment
    [inputData]="inputData"
    table_title="Upstream Equipment"
  ></app-lfn-upstream-equipment>
</div>

<div *ngIf="select_bjl">
  <app-lfn-select-bjl
    [inputData]="inputData"
  ></app-lfn-select-bjl>
</div>
<!-- spinner Component Called-->
<div *ngIf="isLoading">
  <ngx-spinner type = "ball-scale-multiple"></ngx-spinner> 
</div>
