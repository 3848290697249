import { CommonModule } from '@angular/common';
import { HttpClient } from '@angular/common/http';
import { Component } from '@angular/core';
import { ReactiveFormsModule, FormGroup, FormBuilder, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { NgxSpinnerModule, NgxSpinnerService } from 'ngx-spinner';
import { ToastrService } from 'ngx-toastr';
import { ApiService } from '../../api.service';
import { DfnDjlFlowComponent } from '../../components/dfn-flow/dfn-djl-flow/dfn-djl-flow.component';
import { SmpConnectivitySd1Component } from '../smp-connectivity-sd1/smp-connectivity-sd1.component';
import { JlStructureComponent } from '../../components/common/jl-structure/jl-structure.component';
import { SelectBjlSd1Component } from '../select-bjl-sd1/select-bjl-sd1.component';
import { UploadFileComponent } from '../../components/common/upload-file/upload-file.component';
import { SelectSmpSd1Component } from '../select-smp-sd1/select-smp-sd1.component';
import { TableComponent } from '../../components/common/table/table.component';
import { MapSd1Component } from '../map-sd1/map-sd1.component';
import { Sd1UpstreamEquipmentComponent } from '../sd1-upstream-equipment/sd1-upstream-equipment.component';

@Component({
  selector: 'app-landing-page-sd1',
  standalone: true,
  imports: [ReactiveFormsModule,
    CommonModule,
    NgxSpinnerModule,
    DfnDjlFlowComponent,
    SmpConnectivitySd1Component,
    JlStructureComponent,Sd1UpstreamEquipmentComponent,
      SelectBjlSd1Component,TableComponent,MapSd1Component,
    UploadFileComponent,SelectSmpSd1Component
  ],
  templateUrl: './landing-page-sd1.component.html',
  styleUrl: './landing-page-sd1.component.scss'
})
export class LandingPageSd1Component {
  islogin: boolean = true;
  myForm!: FormGroup;
  showDFNCard: boolean = false;
  showDFLFields: boolean = false;
  showDFLFieldsDFN: boolean = true;
  showLFNSubmit: boolean = false;
  showMainPage: boolean = true;
  isLoading: boolean = false;
  query: string = '';
  success_path: boolean = false;
  showTable: boolean = false;
  tableData: any;
  showMDUCard: boolean = false;
  mduPayLoad: any;
  showADRCard: boolean = false;
  adrPayLoad: any;
  showEquipmetCard: boolean = false;
  equPayLoad: any;
  showMessage: boolean = false;
  MessageToShow: any;
  upstream_screen: boolean = false;
  showEndEquipment: boolean = false;
  smp_connectivity_flag: boolean = false;
  bjl_structure_flag: boolean = false;
    bjl_flag: boolean = false;
    flowName: string = 'LOC ID';
file: string | null = null; 
  default: string = 'Type2';
  architectureOptions: string[] = ['Type2', 'Type3'];

  
  constructor(
    private fb: FormBuilder,
    private http: HttpClient,
    private toastr: ToastrService,
    private apiService: ApiService,
    private spinner: NgxSpinnerService,
    private router: Router
  ) {
    this.myForm = this.fb.group({
      ufaCategory: ['', Validators.required],
      architectureType: ['', Validators.required],
      rorId: ['', Validators.required],
      //locId: ['', Validators.required],
      adaId: ['', Validators.required],
      workType: ['', Validators.required],
        mps_boundary: [''],
      locId:['']
    });

    
  }


  ngOnInit() {
    
  }

    fileUploaded(event: string) {
    this.file = event;
    }
    
  onSubmit() {
    // onsubmit check if request is for LFN or DFN
    if (this.myForm.value.workType == 'DFN') {
      this.showDFNCard = true;
      this.showMainPage = false;
    } else {
      this.callCreateMapInfo();
    }
  }

  callCreateMapInfo() {
    // callCreateMapInfo call the api and based on api response initiate next flow like MDU, Equipment, etc. .
    this.isLoading = true;
    this.spinner.show();
    console.log('Form data submitted:', this.myForm.value);
    this.query = `?ufa_category=${this.myForm.value.ufaCategory}&architecture_type=${this.myForm.value.architectureType}&ror_id=${this.myForm.value.rorId}&loc_id=${this.myForm.value.locId}&ada_id=${this.myForm.value.adaId}&loc_id_file=${this.file}`;
    let mps_boundary = this.myForm.value.mps_boundary;
    if (mps_boundary != '' && mps_boundary != undefined) {
      this.query = `?ufa_category=${this.myForm.value.ufaCategory}&architecture_type=${this.myForm.value.architectureType}&ror_id=${this.myForm.value.rorId}&loc_id=${this.myForm.value.locId}&ada_id=${this.myForm.value.adaId}&mps_boundary=${mps_boundary}&loc_id_file=${this.file}`;      
      }
      
    
    
    this.apiService.initiatemapinfodesignSD1(this.query).subscribe({
      next: (response) => {
        // console.log(response)
//           response={
//     "ada_id": "3BMT-05-53",
//     "loc_id": "LOC000194788120",
//     "s18_path": "C:\\Users\\sasi.s.atmakuri\\Documents\\SD1 Samples\\3BMT-05-53",
//     "ror_id": "ND000153412",
//     "tech_type": "BDOD",
//     "loc_type": "SDU1",
//     "ufa_category": "High",
//     "architecture_type": "Type3",
//     "ada_tech_type": "FTTP",
//     "btd_type": "1G",
//     "locations_served_from_smp": [
//         {
//             "smp": "3BMT-05-53-SMP-009",
//             "smp_port": 8,
//             "location_id": "LOC000194788235",
//             "distance": 39.1,
//             "smp_label": "3BMT-05-53-SMP-009",
//             "smp_lat": -38.238822,
//             "smp_lon": 144.326449,
// 			"smp_state" :"yes"
//         },
//         {
//             "smp": "3BMT-05-53-SMP-008",
//             "smp_port": 8,
//             "location_id": "LOC000194788226",
//             "distance": 79.9,
//             "smp_label": "3BMT-05-53-SMP-008",
//             "smp_lat": -38.239169,
//             "smp_lon": 144.326395,
// 			"smp_state" :"yes"
//         },
//         {
//             "smp": "3BMT-05-53-SMP-004",
//             "smp_port": 7,
//             "location_id": "LOC000194788108",
//             "distance": 115.0,
//             "smp_label": "3BMT-05-53-SMP-004",
//             "smp_lat": -38.23919,
//             "smp_lon": 144.325426,
// 			"smp_state" :"re-eval"
//         },
//         {
//             "smp": "3BMT-05-53-SMP-004",
//             "smp_port": 8,
//             "location_id": "LOC000194788098",
//             "distance": 115.7,
//             "smp_label": "3BMT-05-53-SMP-004",
//             "smp_lat": -38.23919,
//             "smp_lon": 144.325426,
// 			"smp_state" :"re-eval"
//         },
//         {
//             "smp": "3BMT-05-53-SMP-003",
//             "smp_port": 7,
//             "location_id": "LOC000194788080",
//             "distance": 163.7,
//             "smp_label": "3BMT-05-53-SMP-003",
//             "smp_lat": -38.239595,
//             "smp_lon": 144.325365,
// 			"smp_state" :"no"
//         },
//         {
//             "smp": "3BMT-05-53-SMP-006",
//             "smp_port": 7,
//             "location_id": "LOC000194788219",
//             "distance": 173.0,
//             "smp_label": "3BMT-05-53-SMP-006",
//             "smp_lat": -38.239766,
//             "smp_lon": 144.326164,
// 			"smp_state" :"no"
//         },
//         {
//             "smp": "3BMT-05-53-SMP-003",
//             "smp_port": 8,
//             "location_id": "LOC000194788149",
//             "distance": 188.6,
//             "smp_label": "3BMT-05-53-SMP-003",
//             "smp_lat": -38.239595,
//             "smp_lon": 144.325365,
// 			"smp_state" :"no"
//         },
//         {
//             "smp": "3BMT-05-53-SMP-006",
//             "smp_port": 8,
//             "location_id": "LOC000194788203",
//             "distance": 200.1,
//             "smp_label": "3BMT-05-53-SMP-006",
//             "smp_lat": -38.239766,
//             "smp_lon": 144.326164,
// 			"smp_state" :"no"
//         },
//         {
//             "smp": "3BMT-05-53-SMP-007",
//             "smp_port": 7,
//             "location_id": "LOC000194788257",
//             "distance": 240.1,
//             "smp_label": "3BMT-05-53-SMP-007",
//             "smp_lat": -38.239805,
//             "smp_lon": 144.3265,
// 			"smp_state" :"no"
//         },
//         {
//             "smp": "3BMT-05-53-SMP-007",
//             "smp_port": 8,
//             "location_id": "LOC000194788261",
//             "distance": 242.1,
//             "smp_label": "3BMT-05-53-SMP-007",
//             "smp_lat": -38.239805,
//             "smp_lon": 144.3265,
// 			"smp_state" :"no"
//         }
//     ],
//     "table_columns": [
//         "smp",
//         "smp_port",
//         "location_id",
//         "distance"
//     ],
//     "location_cords_list": [
        
//         {
//             "loc_label": "LOC000194788261",
//             "loc_lat": -38.238381,
//             "loc_lon": 144.326855
//         },
//         {
//             "loc_label": "LOC000194788257",
//             "loc_lat": -38.238503,
//             "loc_lon": 144.326833
//         },
//         {
//             "loc_label": "LOC000194788300",
//             "loc_lat": -38.237761,
//             "loc_lon": 144.326458
//         },
//         {
//             "loc_label": "LOC000194788080",
//             "loc_lat": -38.238435,
//             "loc_lon": 144.325272
//         }
//     ],
//     "message": "select existing smp"
// }
//         response={
//     "ada_id": "3BMT-05-53",
//     "loc_id": "LOC000194788120",
//     "s18_path": "C:\\Users\\sasi.s.atmakuri\\Documents\\SD1 Samples\\3BMT-05-53",
//     "ror_id": "ND000153412",
//     "loc_type": "SDU1",
//     "ufa_category": "High",
//     "architecture_type": "Type3",
//     "ada_tech_type": "FTTP",
//     "loc_id_file": "C:\\Users\\sasi.s.atmakuri\\OneDrive - Accenture\\Desktop\\ODMS GIT REPO\\odms-backend\\code-base\\adr_data_path\\3BMT-05-53_Location.xlsx",
//     "table_columns": [
//         "upstream_equipment",
//         "splitter_id",
//         "splitter_free_ports",
//         "new_splitter",
//         "warning_message"
//     ],
//     "location_cords_list": [
//         {
//             "loc_label": "LOC000194788183",
//             "loc_lat": -38.238101,
//             "loc_lon": 144.326129
//         },
//         {
//             "loc_label": "LOC000194788177",
//             "loc_lat": -38.238085,
//             "loc_lon": 144.325973
//         },
//         {
//             "loc_label": "LOC000194788316",
//             "loc_lat": -38.237674,
//             "loc_lon": 144.326194
//         },
//         {
//             "loc_label": "LOC000194788328",
//             "loc_lat": -38.237653,
//             "loc_lon": 144.326013
//         },
//         {
//             "loc_label": "LOC000194788337",
//             "loc_lat": -38.237632,
//             "loc_lon": 144.325827
//         },
//         {
//             "loc_label": "LOC000194788203",
//             "loc_lat": -38.23813,
//             "loc_lon": 144.326426
//         },
//         {
//             "loc_label": "LOC000194788196",
//             "loc_lat": -38.238117,
//             "loc_lon": 144.326292
//         },
//         {
//             "loc_label": "LOC000194788344",
//             "loc_lat": -38.237613,
//             "loc_lon": 144.325664
//         },
//         {
//             "loc_label": "LOC000194788359",
//             "loc_lat": -38.237591,
//             "loc_lon": 144.325471
//         },
//         {
//             "loc_label": "LOC000194788363",
//             "loc_lat": -38.237302,
//             "loc_lon": 144.325498
//         },
//         {
//             "loc_label": "LOC000194788165",
//             "loc_lat": -38.238071,
//             "loc_lon": 144.325825
//         },
//         {
//             "loc_label": "LOC000194788371",
//             "loc_lat": -38.237318,
//             "loc_lon": 144.325649
//         },
//         {
//             "loc_label": "LOC000194788261",
//             "loc_lat": -38.238381,
//             "loc_lon": 144.326855
//         },
//         {
//             "loc_label": "LOC000194788257",
//             "loc_lat": -38.238503,
//             "loc_lon": 144.326833
//         },
//         {
//             "loc_label": "LOC000194788424",
//             "loc_lat": -38.236959,
//             "loc_lon": 144.326558
//         },
//         {
//             "loc_label": "LOC000194788411",
//             "loc_lat": -38.237401,
//             "loc_lon": 144.326355
//         },
//         {
//             "loc_label": "LOC000194788407",
//             "loc_lat": -38.237372,
//             "loc_lon": 144.32613
//         },
//         {
//             "loc_label": "LOC000194788392",
//             "loc_lat": -38.237359,
//             "loc_lon": 144.325992
//         },
//         {
//             "loc_label": "LOC000194788385",
//             "loc_lat": -38.237339,
//             "loc_lon": 144.32582
//         },
//         {
//             "loc_label": "LOC000194788476",
//             "loc_lat": -38.236868,
//             "loc_lon": 144.325767
//         },
//         {
//             "loc_label": "LOC000194788469",
//             "loc_lat": -38.236885,
//             "loc_lon": 144.325914
//         },
//         {
//             "loc_label": "LOC000194788482",
//             "loc_lat": -38.236849,
//             "loc_lon": 144.325605
//         },
//         {
//             "loc_label": "LOC000194788226",
//             "loc_lat": -38.238437,
//             "loc_lon": 144.326274
//         },
//         {
//             "loc_label": "LOC000194788453",
//             "loc_lat": -38.236905,
//             "loc_lon": 144.32609
//         },
//         {
//             "loc_label": "LOC000194788430",
//             "loc_lat": -38.236943,
//             "loc_lon": 144.326414
//         },
//         {
//             "loc_label": "LOC000194788448",
//             "loc_lat": -38.236925,
//             "loc_lon": 144.326263
//         },
//         {
//             "loc_label": "LOC000194788495",
//             "loc_lat": -38.236829,
//             "loc_lon": 144.325429
//         },
//         {
//             "loc_label": "LOC000194788288",
//             "loc_lat": -38.238195,
//             "loc_lon": 144.326971
//         },
//         {
//             "loc_label": "LOC000194788274",
//             "loc_lat": -38.238176,
//             "loc_lon": 144.326796
//         },
//         {
//             "loc_label": "LOC000194788219",
//             "loc_lat": -38.238314,
//             "loc_lon": 144.326297
//         },
//         {
//             "loc_label": "LOC000194788290",
//             "loc_lat": -38.237819,
//             "loc_lon": 144.326951
//         },
//         {
//             "loc_label": "LOC000194788242",
//             "loc_lat": -38.238614,
//             "loc_lon": 144.326812
//         },
//         {
//             "loc_label": "LOC000194788235",
//             "loc_lat": -38.238561,
//             "loc_lon": 144.326251
//         },
//         {
//             "loc_label": "LOC000194788300",
//             "loc_lat": -38.237761,
//             "loc_lon": 144.326458
//         }
//     ],
//     "message": "select upstream equipment sd1",
//     "selected_smp": [
//         {
//             "smp": "3BMT-05-53-SMP-011",
//             "smp_status": "new",
//             "smp_state": "yes",
//             "smp_port": "1",
//             "suggested_location_id": "LOC000194788219",
//             "distance": 8.0,
//             "smp_label": "3BMT-05-53-SMP-011",
//             "smp_lat": -38.238359,
//             "smp_lon": -38.238359
//         },
//         {
//             "smp": "3BMT-05-53-SMP-011",
//             "smp_status": "new",
//             "smp_state": "yes",
//             "smp_port": "2",
//             "suggested_location_id": "LOC000194788203",
//             "distance": 35.1,
//             "smp_label": "3BMT-05-53-SMP-011",
//             "smp_lat": -38.238359,
//             "smp_lon": -38.238359
//         },
//         {
//             "smp": "3BMT-05-53-SMP-011",
//             "smp_status": "new",
//             "smp_state": "yes",
//             "smp_port": "3",
//             "suggested_location_id": "LOC000194788235",
//             "distance": 35.9,
//             "smp_label": "3BMT-05-53-SMP-011",
//             "smp_lat": -38.238359,
//             "smp_lon": -38.238359
//         },
//         {
//             "smp": "3BMT-05-53-SMP-011",
//             "smp_status": "new",
//             "smp_state": "yes",
//             "smp_port": "4",
//             "suggested_location_id": "LOC000194788226",
//             "distance": 38.2,
//             "smp_label": "3BMT-05-53-SMP-011",
//             "smp_lat": -38.238359,
//             "smp_lon": -38.238359
//         },
//         {
//             "smp": "3BMT-05-53-SMP-011",
//             "smp_status": "new",
//             "smp_state": "yes",
//             "smp_port": "5",
//             "suggested_location_id": "LOC000194788257",
//             "distance": 46.9,
//             "smp_label": "3BMT-05-53-SMP-011",
//             "smp_lat": -38.238359,
//             "smp_lon": -38.238359
//         },
//         {
//             "smp": "3BMT-05-53-SMP-011",
//             "smp_status": "new",
//             "smp_state": "yes",
//             "smp_port": "6",
//             "suggested_location_id": "LOC000194788261",
//             "distance": 48.9,
//             "smp_label": "3BMT-05-53-SMP-011",
//             "smp_lat": -38.238359,
//             "smp_lon": -38.238359
//         },
//         {
//             "smp": "3BMT-05-53-SMP-011",
//             "smp_status": "new",
//             "smp_state": "yes",
//             "smp_port": "7",
//             "suggested_location_id": "LOC000194788242",
//             "distance": 49.2,
//             "smp_label": "3BMT-05-53-SMP-011",
//             "smp_lat": -38.238359,
//             "smp_lon": -38.238359
//         },
//         {
//             "smp": "3BMT-05-53-SMP-011",
//             "smp_status": "new",
//             "smp_state": "yes",
//             "smp_port": "8",
//             "suggested_location_id": "LOC000194788300",
//             "distance": 86.0,
//             "smp_label": "3BMT-05-53-SMP-011",
//             "smp_lat": -38.238359,
//             "smp_lon": -38.238359
//         },
//         {
//             "smp": "3BMT-05-53-SMP-010",
//             "smp_status": "new",
//             "smp_state": "yes",
//             "smp_port": "1",
//             "suggested_location_id": "LOC000194788108",
//             "distance": 10.5,
//             "smp_label": "3BMT-05-53-SMP-010",
//             "smp_lat": -38.238317,
//             "smp_lon": -38.238317
//         },
//         {
//             "smp": "3BMT-05-53-SMP-010",
//             "smp_status": "new",
//             "smp_state": "yes",
//             "smp_port": "2",
//             "suggested_location_id": "LOC000194788098",
//             "distance": 11.2,
//             "smp_label": "3BMT-05-53-SMP-010",
//             "smp_lat": -38.238317,
//             "smp_lon": -38.238317
//         },
//         {
//             "smp": "3BMT-05-53-SMP-010",
//             "smp_status": "new",
//             "smp_state": "yes",
//             "smp_port": "3",
//             "suggested_location_id": "LOC000194788080",
//             "distance": 13.5,
//             "smp_label": "3BMT-05-53-SMP-010",
//             "smp_lat": -38.238317,
//             "smp_lon": -38.238317
//         },
//         {
//             "smp": "3BMT-05-53-SMP-010",
//             "smp_status": "new",
//             "smp_state": "yes",
//             "smp_port": "4",
//             "suggested_location_id": "LOC000194788149",
//             "distance": 38.4,
//             "smp_label": "3BMT-05-53-SMP-010",
//             "smp_lat": -38.238317,
//             "smp_lon": -38.238317
//         },
//         {
//             "smp": "3BMT-05-53-SMP-010",
//             "smp_status": "new",
//             "smp_state": "yes",
//             "smp_port": "5",
//             "suggested_location_id": "LOC000194788154",
//             "distance": 39.4,
//             "smp_label": "3BMT-05-53-SMP-010",
//             "smp_lat": -38.238317,
//             "smp_lon": -38.238317
//         },
//         {
//             "smp": "3BMT-05-53-SMP-010",
//             "smp_status": "new",
//             "smp_state": "yes",
//             "smp_port": "6",
//             "suggested_location_id": "LOC000194788131",
//             "distance": 40.7,
//             "smp_label": "3BMT-05-53-SMP-010",
//             "smp_lat": -38.238317,
//             "smp_lon": -38.238317
//         },
//         {
//             "smp": "3BMT-05-53-SMP-010",
//             "smp_status": "new",
//             "smp_state": "yes",
//             "smp_port": "7",
//             "suggested_location_id": "LOC000194788120",
//             "distance": 53.1,
//             "smp_label": "3BMT-05-53-SMP-010",
//             "smp_lat": -38.238317,
//             "smp_lon": -38.238317
//         },
//         {
//             "smp": "3BMT-05-53-SMP-010",
//             "smp_status": "new",
//             "smp_state": "yes",
//             "smp_port": "8",
//             "suggested_location_id": "LOC000194788112",
//             "distance": 55.4,
//             "smp_label": "3BMT-05-53-SMP-010",
//             "smp_lat": -38.238317,
//             "smp_lon": -38.238317
//         }
//     ],
//     "selected_bjl": {
//         "bjl_id": "3BMT-05-53-BJL-001",
//         "bjl_structure_id": "3BMT-05-53-PIT-008",
//         "bjl_spare_port": "4",
//         "fjl_splitter_id": "3BMT-05-53-SPL-103:4",
//         "new_smp_size": "8",
//         "sjr_trace": {
//             "0_equip": "3BMT-05-53-BJL-001",
//             "0_port": "4",
//             "1_port": "3",
//             "1_equip": "3BMT-05-53-FJL-001_3BMT-05-53-SPL-103",
//             "1_cable": "3BMT-05-53-FSL-001",
//             "upstream_connectivity_flag": true
//         }
//     },
//     "bjl_with_capacity": [],
//     "new_smp_list": [
//         {
//             "id": "3BMT-05-53-SMP-010",
//             "smp_status": "new",
//             "structure_id": "3BMT-05-53-PIT-227",
//             "free_ports": [
//                 "1",
//                 "2",
//                 "3",
//                 "4",
//                 "5",
//                 "6",
//                 "7",
//                 "8"
//             ],
//             "bjl_id": "3BMT-05-53-BJL-001",
//             "spare_port": "3",
//             "splitter_port": "3BMT-05-53-SPL-103:4"
//         },
//         {
//             "id": "3BMT-05-53-SMP-011",
//             "smp_status": "new",
//             "structure_id": "3BMT-05-53-PIT-222",
//             "free_ports": [
//                 "1",
//                 "2",
//                 "3",
//                 "4",
//                 "5",
//                 "6",
//                 "7",
//                 "8"
//             ],
//             "bjl_id": "3BMT-05-53-BJL-001",
//             "spare_port": "4",
//             "splitter_port": "3BMT-05-53-SPL-103:4"
//         }
//     ],
//     "selected_smp_structure": {
//         "bjl_id": "3BMT-05-53-BJL-001",
//         "bjl_structure_id": "3BMT-05-53-PIT-008",
//         "structure_to_bjl_distance": 325.7,
//         "structure_id": "3BMT-05-53-PIT-222",
//         "potential_smp_structure_id": "3BMT-05-53-PIT-222",
//         "label": "3BMT-05-53-PIT-222",
//         "lat": -38.238359,
//         "lon": 144.326501
//     },
//     "location_cords_list_served": [
//         {
//             "loc_label": "LOC000194788149",
//             "loc_lat": -38.238388,
//             "loc_lon": 144.325891
//         },
//         {
//             "loc_label": "LOC000194788131",
//             "loc_lat": -38.238505,
//             "loc_lon": 144.325869
//         },
//         {
//             "loc_label": "LOC000194788154",
//             "loc_lat": -38.238273,
//             "loc_lon": 144.325912
//         },
//         {
//             "loc_label": "LOC000194788120",
//             "loc_lat": -38.237932,
//             "loc_lon": 144.325429
//         },
//         {
//             "loc_label": "LOC000194788112",
//             "loc_lat": -38.238039,
//             "loc_lon": 144.325347
//         },
//         {
//             "loc_label": "LOC000194788108",
//             "loc_lat": -38.238175,
//             "loc_lon": 144.325321
//         },
//         {
//             "loc_label": "LOC000194788098",
//             "loc_lat": -38.238306,
//             "loc_lon": 144.325297
//         },
//         {
//             "loc_label": "LOC000194788080",
//             "loc_lat": -38.238435,
//             "loc_lon": 144.325272
//         }
//     ],
//     "upstream_equipment_list": [
//         {
//             "upstream_equipment": "3BMT-05-53-FJL-001",
//             "splitter_id": "3BMT-05-53-SPL-104",
//             "splitter_free_ports": "['4']",
//             "new_splitter": "No",
//             "warning_message": ""
//         }
//     ]
// }
        if (response.message === 'select existing smp') {
          this.smp_connectivity_flag = true;
          this.showMainPage = false;
          this.tableData = response;
        } else if (response.message === 'select bjl sd1') {
          this.showMainPage = false;
          this.tableData = response;
          this.bjl_flag = true;
        } else if (response.message === 'select upstream equipment sd1') {          
          this.tableData = response;
          this.bjl_flag = false;
          this.showMainPage = false;
          this.bjl_structure_flag = true;
        }
        else {
          if ('file_name' in response) {
            this.showMessage = true;
            this.MessageToShow = response;
          } else {
            this.toastr.error(response.message, 'Error Message');
            console.log('error');
          }
        }
        this.isLoading = false;
        this.spinner.hide();
        
      },
      error: (error) => {
        this.isLoading = false;
        this.spinner.hide();
        this.toastr.error(
          `The API returned Unsuccessfull response : ${error}`,
          'Error Message'
        );
      },
    });
  }

  radioSelect() {
    // Based on radio button enable and disable Architecture Type dropdown type2 value
    console.log('radioSelect');
    if (this.myForm.value.workType == 'DFN') {
      this.showDFNCard = true;
      this.showLFNSubmit = false;
      // this.selectedProperty = this.options[1];
      this.myForm.controls['architectureType'].setValue('Type3');
      this.myForm.controls['architectureType'].disable({ onlySelf: true });
    }
    else {
      this.showDFNCard = false;
      this.showLFNSubmit = true;
      this.myForm.value.architectureType = '';
      this.myForm.controls['architectureType'].setValue('');
      this.myForm.controls['architectureType'].enable({ onlySelf: true });
    }
  }

  disableMainForm() {
    // Disable main form page from DFN flow
    this.showMainPage = false;
  }

  downloadFile() {
    // Path to the file in the assets folder
    const fileUrl = 'assets/sample_file/ada_Location.xlsx';

    // Create an invisible anchor element
    const anchor = document.createElement('a');
    anchor.href = fileUrl;
    anchor.download = 'ADA_Location.xlsx'; // Set the file name for the download

    // Trigger a click event on the anchor to start the download
    anchor.click();
  }

  OnSelectProceedMap(data: any) { }
  receiveTableEventStructure(data: any) { }
  
  receiveTableEventUpstream(data:any){}
}
