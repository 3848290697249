<div  
  *ngIf="showCheckboxTable"
  class=""
>
  <button
    *ngIf="showCheckboxTable"
    type="submit"
    (click)="OnSelectTableProceed()"
  >
    Click To Proceed Table
  </button>
  <button type="button" class="secondary back" (click)="goBack()">
                Back
              </button>

<table
  mat-table
  [dataSource]="dataSource"
  class="mat-elevation-z8"
  matSort
>
  <ng-container matColumnDef="select">
    <th mat-header-cell *matHeaderCellDef>
      <mat-checkbox
        (change)="$event ? masterToggle() : null"
        [checked]="selection.hasValue() && isAllSelected()"
        [indeterminate]="selection.hasValue() && !isAllSelected()"
      ></mat-checkbox>
    </th>
    <td mat-cell *matCellDef="let row">
      <mat-checkbox
        (click)="$event.stopPropagation()"
        (change)="selection.toggle(row)"
        [checked]="selection.isSelected(row)"
      ></mat-checkbox>
    </td>
  </ng-container>
  <ng-container matColumnDef="Paths">
    <th mat-header-cell *matHeaderCellDef mat-sort-header>Paths</th>
    <td mat-cell *matCellDef="let row">{{ row.Paths }}</td>
  </ng-container>
  <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
  <tr
    mat-row
    *matRowDef="let row; columns: displayedColumns"
    (click)="selection.toggle(row)"
  ></tr>
</table>

</div>


