import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
    name: 'replaceText',
    standalone: true
})
export class ReplaceTextPipe implements PipeTransform {
    transform(value: string): any {
        value = value.replace(/_/g, ' ');
    return value 
  }
}

const titleText:any = {
    'path': 'Available Path',
    'path_dfn': 'DFN Available Path',
    'path_adr': 'ADR Available Path',
    'equipment': 'Select Equipment',
    'upstream': 'Select Upstream Equipment',
    'mdu': 'Upload MDU File',
    'success': 'Generated S18 File',
    'structure': 'Select Structure Point',
    'jl_structure': 'Select structure placement for ',
    'adr': 'Upload ADR File',
    'checkbox': 'Duct creation',
    'upstream-overwrite': 'Select Upstream Equipment - Overwrite',
    'select_bjl' : 'Select BJL',
    'alternate_path' : 'Alternate Path',
    'final_screen' : 'Final Screen'
}
@Pipe({
    name: 'titleText',
    standalone: true
})
export class TitleTextPipe implements PipeTransform {
    transform(value: string): any {
        value = titleText[value];
    return value 
  }
}