<div *ngIf="upstream_screen">
<h3>{{'upstream' | titleText }}</h3>
<div  class="card">
    <app-table 
      
        [inputData]="inputData"
        (appItemEvent)="receiveTableEventUpstream($event)" >
    </app-table>
</div>
</div>

<div *ngIf="select_smp_screen">
    <app-select-smp-sd1
        [inputData]="inputData"
    ></app-select-smp-sd1>
</div>