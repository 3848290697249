<div>
    <app-upload-file [flowName]="flowName" (uploaded)="fileUploaded($event)" [showADRCard]="showADRCard"
            [inputData]="inputData" [selectedRow]="selectedRow">
    </app-upload-file>
    
    <form [formGroup]="myAdrForm" (ngSubmit)="table_function_called()">

        <div class="row">
            <div class="col-md-3 form-group">
                <button type="submit" class="primary">
                        Submit
                </button>
            </div>
            <div class="col-md-3 form-group float-end" *ngIf="flowName == 'ADR'">
                <button type="button" class="secondary" (click)="goBack()">
                    Back
                </button>
            </div>

        </div>
    </form>
    
</div>