<form [formGroup]="myUploadForm">
    <div class="col-md-3 form-group float-end" *ngIf="flowName == 'ADR'">
        <button type="button" class="secondary" (click)="goBack()">
            Back
        </button>
    </div>
    <div class="row">
        <div class="col-md-12 form-group files">

            <label class="adr_label" for="mdu path">{{flowName}} PATH:</label><br />
            <input type="file" id="path" formControlName="filePath" name="path" placeholder="Select a File"
                (change)="selectFileToUpload($event)" class="form-control" #fileInput />
        </div>
    </div>
    <div>
                <section [ngSwitch]="flowName">
                    <p *ngSwitchCase="'ADR'"><b>*Please follow file naming convention as ada_name_ADR.xlsx (2NSY-02-02_ADR.xlsx)</b></p>
                    <p *ngSwitchCase="'MDU'"><b>*Please follow file naming convention as MPS_BOUNDRY_ID.xlsx (4TNS-04-00-MPS-111.xlsx)</b></p>
                    <p *ngSwitchCase="'SD1 ADR'"><b>*Please follow file naming convention as ada_name_ADR.xlsx (2NSY-02-02_ADR.xlsx). <br />*Create a sheet with name as ADR_ID Field from below table and add the respective ADR contents to the sheet.</b></p>
                </section>
            </div>
    
    <div class="row">
        <div class="col-md-12 form-group">
            <div *ngIf="file">
                <section class="file-info">
                    File details:
                    <ul>
                        <li>Name: {{file.name}}</li>
                        <li>Type: {{file.type}}</li>
                        <li>Size: {{file.size}} bytes</li>
                    </ul>
                </section>
            </div>

            <div class="col-md-12 form-group" *ngIf="showUpload">
                <button (click)="onUpload()" class="primary">Upload file</button>
            </div>
            <div class="col-md-6 form-group">
                <section [ngSwitch]="status">
                    <p *ngSwitchCase="'uploading'">⏳ Uploading...</p>
                    <p *ngSwitchCase="'success'">✅ Done!</p>
                    <p *ngSwitchCase="'fail'">❌ Error!</p>
                    <p *ngSwitchDefault>😶 Waiting to upload...</p>
                </section>
            </div>

        </div>
    </div>
</form>