import { HttpClient } from "@angular/common/http";
import { Component, EventEmitter, Input, OnInit, Output } from "@angular/core";
import { FormGroup, FormBuilder, ReactiveFormsModule } from "@angular/forms";
import { NgxSpinnerModule, NgxSpinnerService } from "ngx-spinner";
import { ToastrService } from "ngx-toastr";
import { ApiService } from "../../../api.service";
import { CommonModule } from "@angular/common";
import { TitleTextPipe } from "../../../custom.pipe";
import { MessageComponent } from "../message/message.component";
import { UploadFileComponent } from "../upload-file/upload-file.component";

@Component({
  selector: "app-adr-form",
  standalone: true,
  imports: [
    CommonModule,
    NgxSpinnerModule,
    MessageComponent, TitleTextPipe,
    UploadFileComponent,
    ReactiveFormsModule
  ],
  templateUrl: "./adr-form.component.html",
  styleUrls: ["./adr-form.component.scss"]
})

export class AdrFormComponent implements OnInit {
  myAdrForm!: FormGroup;
  //@Input() 
  @Input() showADRCard: boolean = false;
  @Input() inputData: any = {};
  selectedRow: any = {};
  @Output() goBackCall = new EventEmitter<string>();


  selectedFiles: any;
  isLoading: boolean = false;
  showMessage: boolean = false;
  MessageToShow: any;
  currentFile?: File;
  progress = 0;
  message = '';
  url: string = '';
  filename: string = '';
  msg: string = '';
  flowName: string = 'ADR';

  fileName = 'Select File';
  

  file: string = ''; // Variable to store file
  constructor(
    private toastr: ToastrService,
    private apiService: ApiService,
    private http: HttpClient,
    private fb: FormBuilder,
    private spinner: NgxSpinnerService
  ) {

    this.myAdrForm = this.fb.group({
      ADRpath: [''],
    });
  }

  ngOnInit() {

  }

  fileUploaded(event: string) {
    this.file = event;
  }

  table_function_called() {
    this.isLoading = true;
    this.spinner.show();
    if (this.selectedRow && this.selectedRow.length > 0) {
      let new_route_list = [];
      for (let ele of this.selectedRow) {
        new_route_list.push(ele['Paths']);
      }
      this.inputData['new_route_list'] = new_route_list;
    }
    this.inputData['adr_path'] = this.myAdrForm.value.ADRpath;
    this.inputData['adr_file_name'] = this.file;
    
      this.inputData['available_paths'] = this.inputData['available_paths'];
      if (this.inputData.message == 'receive adr') {
        this.apiService.receiveAdrcall(this.inputData).subscribe({
          next: (response) => {
            this.showADRCard = false;

            if (
              response.message.indexOf(
                "Tool generated path doesn't match with ADR"
              ) != -1
            ) {
              this.url = response.url;
              this.filename = response.file_name;
              this.msg = response.message;
              this.showMessage = true;
              this.MessageToShow = JSON.stringify(response);
            } else if (
              response.message.indexOf('Pre-signed url has been generated') == -1
            ) {
              this.url = response.url;
              this.filename = response.file_name;
              this.msg = response.message;
              this.showMessage = true;
              this.MessageToShow = JSON.stringify(response);
            } else {
              this.url = response.url;
              this.filename = response.file_name;
              this.msg = response.message;
              this.showMessage = true;
              this.MessageToShow = JSON.stringify(response);
            }
            this.isLoading = false;
            this.spinner.hide();
            // window.alert(response.messgae);
          },
          error: (error) => {
            this.isLoading = false;
            this.spinner.hide();
            this.toastr.error(
              `The API returned Unsuccessfull response : ${error}`,
              'Error Message'
            );
            //window.alert(`The API returned Unsuccessfull response : ${error}`);
          },
        });
      }
      else {
        this.apiService.onadrcall(this.inputData).subscribe({
          next: (response) => {
            this.showADRCard = false;

            if (
              response.message.indexOf(
                "Tool generated path doesn't match with ADR"
              ) != -1
            ) {
              this.url = response.url;
              this.filename = response.file_name;
              this.msg = response.message;
              this.showMessage = true;
              this.MessageToShow = JSON.stringify(response);
            } else if (
              response.message.indexOf('Pre-signed url has been generated') == -1
            ) {
              this.url = response.url;
              this.filename = response.file_name;
              this.msg = response.message;
              this.showMessage = true;
              this.MessageToShow = JSON.stringify(response);
            } else {
              this.url = response.url;
              this.filename = response.file_name;
              this.msg = response.message;
              this.showMessage = true;
              this.MessageToShow = JSON.stringify(response);
            }
            this.isLoading = false;
            this.spinner.hide();
            // window.alert(response.messgae);
          },
          error: (error) => {
            this.isLoading = false;
            this.spinner.hide();
            this.toastr.error(
              `The API returned Unsuccessfull response : ${error}`,
              'Error Message'
            );
            //window.alert(`The API returned Unsuccessfull response : ${error}`);
          },
        });
      }
    
  }

  goBack() {
    console.log('Go Back called...')
    //this.location.back();
    this.goBackCall.emit(this.inputData);
  }

}
