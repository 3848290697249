    <div class=""> 
      <div class="card_form" *ngIf="showEquipmetCard"> 
        <form [formGroup]="myEquipmetForm" (ngSubmit)="onEuipCallForm()">
          <div class="form-group row">
            <div class="form-group col-md-2"></div>
            <div class="form-group col-md-6">
              <label class="lable-css">Select a Equipmet Type</label>
            </div>
          </div>
          <div class="form-group row">
            <div class="form-group col-md-2"></div>
            <div class="form-group col-md-6">
                <select
                  formControlName="equpType"
                  id="selectOption"
                  class="form-control"
                >
                  <option value="" selected>Select</option>
                  <option value="pcd">PCD</option>
                  <option value="budi">BUDI</option>
                  <option value="ctl">CTL</option>
                </select>
              </div>
            </div>
          
          <div class=" form-group row"><br /></div>
          <div class="form-group row">
            <div class=" form-group col-md-2"></div>
            <div class=" form-group col-md-6">
              <button
                type="submit"
                [class]="myEquipmetForm.invalid ? 'gray-button' : ''"
                [disabled]="myEquipmetForm.invalid"
              >
                Submit
              </button>
            </div>
            </div>
          
        </form>
      </div>

      
    </div> 