<div *ngIf="showBJLTable" class = "card">
    <h3>{{'select_bjl' | titleText}}</h3>
<div *ngIf="showBJLTable" class="form-group col-md-2">
  <button (click)="callBJL()">Create New BJL</button>
</div>
<div *ngIf="showBJLTable">
        <app-table         
            table_title="Select BJL" 
            [inputData]="inputData" 
            (appItemEvent)="receiveTableEvent($event)">
        </app-table>
</div>
</div>

<div *ngIf="upstream_screen">
  <app-lfn-upstream-equipment-overwrite
    [inputData]="inputData"
    table_title="Upstream Equipment"
  ></app-lfn-upstream-equipment-overwrite>
</div>

<!--Success path Component called-->
<div *ngIf="success_path">
    <app-lfn-path-generartion
        [inputData]="inputData"
        table_title = "bjl_flag"
        (callSelectBjl) = "callSelectBjl($event)"
    ></app-lfn-path-generartion>
</div>


<!-- <div *ngIf="showJlStructure">
    <app-jl-structure
        [tableData]="tableData"
        [inputData]="inputData"
        table_title={{table_title}} 
    ></app-jl-structure>
</div> -->
<!-- spinner Component Called-->
<div *ngIf="isLoading">
  <ngx-spinner type = "ball-scale-multiple"></ngx-spinner> 
</div>