<div *ngIf="selectStructure" class="[card,content]">
  <form [formGroup]="myStrForm" (ngSubmit)="onselectStructure()">
    <div class="form-group">
      <label for="ada">Select Structure</label>
      <input
        type="text"
        id="selectedStructure "
        formControlName="structure"
        [value]="myStrForm.get('structure')"
        name="Structure"
        required
      />
    </div>
    <button
      type="submit"
      [class]="myStrForm.invalid ? 'gray-button' : ''"
      [disabled]="myStrForm.invalid"
    >
      Submit
    </button>
  </form>
</div>
<div style="margin-top: 20px" id="map" style="width: 100%; height: 400px"></div>
<div
  
  style="margin-top: 20px; display: flex; align-items: center"
>
  <button type="submit" (click)="OnSelectProceed()">Click To Proceed</button>
</div>





