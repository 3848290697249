import { CommonModule } from "@angular/common";
import { Component, Input, OnInit } from "@angular/core";
import { FormGroup, ReactiveFormsModule } from "@angular/forms";

@Component({
  selector: "app-duct-form",
  standalone: true,
  imports:[CommonModule,ReactiveFormsModule],
  templateUrl: "./duct-form.component.html",
  styleUrls: ["./duct-form.component.scss"]
})

export class DuctFormComponent implements OnInit {
  @Input() showTable: boolean = false;
  @Input() showButton: boolean = false;
  @Input() myForm!: FormGroup;

  constructor() {}

  ngOnInit() {}
  radioSelect(data: any) {
    //update radio button value in myForm variable
    console.log(
      this.myForm.value.FJLState
    );
  }
  
}
