import { CommonModule } from "@angular/common";
import { Component, EventEmitter, Input, OnInit, Output } from "@angular/core";
import { FormGroup, ReactiveFormsModule } from "@angular/forms";
import { NgxSpinnerModule, NgxSpinnerService } from "ngx-spinner";
// import { LfnUpstreamEquipmentComponent } from "../../lfn-flow/lfn-upstream-equipment/lfn-upstream-equipment.component";
import { HttpClient } from "@angular/common/http";
import { ToastrService } from "ngx-toastr";
import { ApiService } from "../../../api.service";


@Component({
  selector: "app-duct-form",
  standalone: true,
  imports: [CommonModule, ReactiveFormsModule,
    NgxSpinnerModule,
    
  ],
  templateUrl: "./duct-form.component.html",
  styleUrls: ["./duct-form.component.scss"]
})

export class DuctFormComponent implements OnInit {
  @Input() showTable: boolean = false;
  @Input() showButton: boolean = false;
  @Input() myForm!: FormGroup;
  @Input() inputData: any;
  @Input() overwrite_flag: boolean = false;
  @Output() callBjlPathOverwrite = new EventEmitter<any>();
  upstream_screen = false;
  url: any;
  filename: any;
  msg: any;
  showMessage: boolean = false;
  MessageToShow: string = '';
  isLoading: boolean = false;
  overwrite_flag_val: boolean = false;

  constructor(
    private http: HttpClient,
    private toastr: ToastrService,
    private apiService: ApiService,
    private spinner: NgxSpinnerService) {}

  ngOnInit() {
    if (this.overwrite_flag == true) {
      this.overwrite_flag_val = true;
    }
  }
  radioSelect(data: any) {
    //update radio button value in myForm variable
    console.log(
      this.myForm.value.FJLState
    );
  }
  
  callBack() {
    this.callBjlPathOverwrite.emit(this.inputData);
    
  }
}
