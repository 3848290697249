import { SelectionModel } from "@angular/cdk/collections";
import { CommonModule } from "@angular/common";
import { HttpClient } from "@angular/common/http";
import { Component, EventEmitter, Input, OnInit, Output } from "@angular/core";
import {MatCheckboxModule} from '@angular/material/checkbox';
import { MatTableDataSource, MatTableModule } from "@angular/material/table";
import { ApiService } from "../../../api.service";
@Component({
  selector: "app-table-checkbox",
  standalone: true,
  imports: [
    MatCheckboxModule,
    CommonModule,
    MatTableModule
    
  ],
  templateUrl: "./table-checkbox.component.html",
  styleUrls: ["./table-checkbox.component.scss"]
})

export class TableCheckboxComponent implements OnInit {
  @Input() dataSource!: MatTableDataSource<any>;
  @Input() displayedColumns: string[] = [];
  @Input() inputData: any = {};
  @Input() showCheckboxTable: boolean = false;
  selection = new SelectionModel<any>(true, []);
  @Output() goBackCall = new EventEmitter<string>();
  @Output() OnAdrCall = new EventEmitter<string>();
  selectedRow: any;
  
  isLoading: boolean = false;
  
  constructor(
    private apiService: ApiService,
    private http: HttpClient
  ) { 

  }

  ngOnInit() {

  }

  isAllSelected() {
    const numSelected = this.selection.selected.length;
    const numRows = this.dataSource.data.length;
    this.selectedRow = this.selection.selected;
    return numSelected === numRows;
  }

  masterToggle() {
    this.isAllSelected()
      ? this.selection.clear()
      : this.dataSource.data.forEach((row) => this.selection.select(row));
  }

  OnSelectTableProceed() {
    this.isLoading = true;
    
    if (this.selectedRow && this.selectedRow.length > 0) {
      let new_route_list = [];
      for (const ele of this.selectedRow) {
        new_route_list.push(ele['Paths']);
      }
      this.inputData['new_route_list'] = new_route_list;
    }
    this.inputData['available_paths'] = this.inputData['available_paths'];
    this.OnAdrCall.emit(this.inputData);
    
  }

  goBack() {
    this.goBackCall.emit(this.inputData);
  }

}
