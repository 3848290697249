
<table
  mat-table
  [dataSource]="tableData"
  class="table table-striped-columns table-bordered"
>
  <ng-container
    [matColumnDef]="column"
    *ngFor="let column of displayedColumns"
    class="table-dark"
  >
    @if(column != 'actions'){
    <th mat-header-cell 
    *matHeaderCellDef 
    [style.background-color]="color" 
    [style.color]="text_color"
    [style.font-weight]="text_weight"
    >
    {{ column | replaceText | titlecase }}
        
    </th>
    <td mat-cell *matCellDef="let element">
      {{ element[column] }}
    </td>
    }
    <th mat-header-cell 
    *matHeaderCellDef 
    [style.background-color]="color"
    [style.color]="text_color"
    [style.font-weight]="text_weight"
    >
    {{ column | titlecase }}
    </th>
    <td mat-cell *matCellDef="let row">
      <button
        mat-flat-button
        (click)="submitAction(row)"
      >
        Select
      </button>
    </td>
  </ng-container>

  <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
  <tr mat-row *matRowDef="let row; columns: displayedColumns" 
  [ngStyle]="row.excluded && {'display': 'none'}"></tr>
</table>