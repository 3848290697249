import { SelectionModel } from "@angular/cdk/collections";
import { Component, EventEmitter, Input, OnInit, Output, ViewChild } from "@angular/core";
import { MatTableDataSource } from "@angular/material/table";
import { TableComponent } from "../../common/table/table.component";
import { FormBuilder, FormGroup, Validators, ReactiveFormsModule } from "@angular/forms";
import { CommonModule } from "@angular/common";
import { DuctFormComponent } from "../../common/duct-form/duct-form.component";
import { HttpClient } from "@angular/common/http";
import { ToastrService } from "ngx-toastr";
import { ApiService } from "../../../api.service";
import { TableCheckboxComponent } from "../../common/table-checkbox/table-checkbox.component";
import { NgxSpinnerService, NgxSpinnerModule } from 'ngx-spinner';
import { MessageComponent } from "../../common/message/message.component";
import { TitleTextPipe } from "../../../custom.pipe";
import { UploadFileComponent } from "../../common/upload-file/upload-file.component"

@Component({
  selector: "app-lfn-path-generartion",
  standalone: true,
  imports: [
    TableComponent,
    CommonModule,
    DuctFormComponent,
    TableCheckboxComponent,
    NgxSpinnerModule,
    MessageComponent, TitleTextPipe,
    UploadFileComponent,
    ReactiveFormsModule
    
    
  ],
  templateUrl: "./lfn-path-generartion.component.html",
  styleUrls: ["./lfn-path-generartion.component.scss"]
})

export class LfnPathGenerartionComponent implements OnInit {
  @Input() inputData: any;
  @Input() table_title: string = '';
  flowName: string = 'ADR';

  tableData: any;
  displayedColumns: string[] = [];
  dataSource!: MatTableDataSource<any>;
  selection = new SelectionModel<any>(true, []);
  selectedRow: any;
  @Input() isCheckbox: boolean = false;
  @Input() isReadio: boolean = false;
  @Output() callSelectBjl = new EventEmitter<any>();

  myForm: FormGroup;
  myAdrForm!: FormGroup;
  @Output() appItemEvent = new EventEmitter<any>();
  @ViewChild(TableComponent)
  tableChildComponent!: TableComponent;


  showDFNCard: boolean = false;
  showMainPage: boolean = true;
  isLoading: boolean = false;
  query: string = '';
  showTable: boolean = true;
  showCheckboxTable: boolean = false;

  showMDUCard: boolean = false;
  mduPayLoad: any;
  showEquipmetCard: boolean = false;
  equPayLoad: any;
  showMessage: boolean = false;
  MessageToShow: any;
  showADRCard: boolean = false;
  url: string = '';
  filename: string = '';
  msg: string = '';  
  overwrite_flag = false;
  back_button_flag = false;
  alternate_path = false;


  file: string | null = null; // Variable to store file
  isBack: boolean = false;

  constructor(private fb: FormBuilder,
    private http: HttpClient,
    private toastr: ToastrService,
    private apiService: ApiService,
    private spinner: NgxSpinnerService
  ) {
    this.myForm = this.fb.group({
      FJLState: ['No', Validators.required],
    });
    this.myAdrForm = this.fb.group({
      ADRpath: [''],
    });

  }

  ngOnInit() {
    if (this.inputData['bjl_overwrite_flag'] == true) {
      this.overwrite_flag = true;
    }
    if (this.table_title == 'bjl_flag') {
      this.back_button_flag = true;
    }
  }

  fileUploaded(event: string) {
    this.file = event;
  }


  receiveTableEvent(data: any) {
    /* receiveTableEvent receive data from table select button and based on scenario trigger next flow
     like select path or checkbox table */
    this.isLoading = true;
    this.spinner.show();
    if (this.myForm.value.FJLState == 'Yes') {
      this.inputData['selected_path'] = data;
      this.showTable = false;
      let rawtableData = data.raw_path;
      let modifiedPath = [];
      for (let i = 0; i < rawtableData.length - 1; i++) {
        modifiedPath.push({
          Paths: rawtableData[i] + ' - ' + rawtableData[i + 1],
        });
      }
      this.displayedColumns = ['Paths', 'select'];
      this.dataSource = new MatTableDataSource<any>(modifiedPath);
      this.showCheckboxTable = true;

      console.log(modifiedPath);
      this.spinner.hide();
      this.isLoading = false;
    } else {
      this.inputData['selected_path'] = data;

      this.apiService.onSelectPath(this.inputData).subscribe({
        next: (response) => {
          if (response.message == 'upload adr') {
            this.showADRCard = true;
            this.showTable = false;
          } else {
            this.showTable = false;
            this.url = response.url;
            this.filename = response.file_name;
            this.msg = response.message;
            this.showMessage = true;
            this.MessageToShow = JSON.stringify(response);
          }

          this.isLoading = false;
          this.spinner.hide();
        },
        error: (error) => {
          this.isLoading = false;
          this.spinner.hide();
          this.toastr.error(
            `The API returned Unsuccessfull response : ${error}`,
            'Error Message'
          );
        },
      });
    }
    // }
  }


  OnAdrCall(data: any) {
    this.showADRCard = true;
    this.showTable = false;
    this.showCheckboxTable = false;
  }

  alternatePath(data: any){
    this.isLoading = true;
    this.spinner.show();
    this.inputData['alternate_path'] = data;
    
    this.apiService.onAlternatePath(this.inputData).subscribe({
      next: (response) => {
        this.showADRCard = false;
        if (
          response.message.indexOf(
            "Tool generated path doesn't match with ADR"
          ) != -1
        ) {
          this.alternate_path = false;
          this.url = response.url;
          this.filename = response.file_name;
          this.msg = response.message;
          this.showMessage = true;
          this.MessageToShow = JSON.stringify(response);
        } else if (response.message == 'Pre-signed url has been generated') {
          this.alternate_path = false;   
          this.showMessage = true; 
          this.url = response.url;
          this.filename = response.file_name;
          this.msg = response.message;
          this.MessageToShow = JSON.stringify(response);
        }
        else {
          this.alternate_path = false;
          this.url = response.url;
          this.filename = response.file_name;
          this.msg = response.message;
          this.showMessage = true;
          this.MessageToShow = JSON.stringify(response);
        }
        this.isLoading = false;
        this.spinner.hide();
      },
      error: (error) => {
        this.isLoading = false;
        this.spinner.hide();
        this.toastr.error(
          `The API returned Unsuccessfull response : ${error}`,
          'Error Message'
        );
      }
    });
  }

  table_function_called() {
    this.isLoading = true;
    this.spinner.show();
    if (this.selectedRow && this.selectedRow.length > 0) {
      let new_route_list = [];
      for (let ele of this.selectedRow) {
        new_route_list.push(ele['Paths']);
      }
      this.inputData['new_route_list'] = new_route_list;
    }
    this.inputData['adr_path'] = this.myAdrForm.value.ADRpath;
    this.inputData['adr_file_name'] = this.file;

    this.inputData['available_paths'] = this.inputData['available_paths'];
    this.apiService.onadrcall(this.inputData).subscribe({
      next: (response) => {
        this.showADRCard = false;

        if (
          response.message.indexOf(
            "Tool generated path doesn't match with ADR"
          ) != -1
        ) {
          this.url = response.url;
          this.filename = response.file_name;
          this.msg = response.message;
          this.showMessage = true;
          this.MessageToShow = JSON.stringify(response);
        } else if (response.message == 'Pre-signed url has been generated') {
          this.url = response.url;
          this.filename = response.file_name;
          this.msg = response.message;
          this.showMessage = true;
          this.MessageToShow = JSON.stringify(response);
        }
        else if(response.message == 'alternate path'){
            this.showADRCard = false;
            this.alternate_path = true;    
            this.inputData =  response;  
            //this.tableChildComponent.initializeFunction(this.inputData);
        } 
        else {
          this.url = response.url;
          this.filename = response.file_name;
          this.msg = response.message;
          this.showMessage = true;
          this.MessageToShow = JSON.stringify(response);
        }
        this.isLoading = false;
        this.spinner.hide();
        // window.alert(response.messgae);
      },
      error: (error) => {
        this.isLoading = false;
        this.spinner.hide();
        this.toastr.error(
          `The API returned Unsuccessfull response : ${error}`,
          'Error Message'
        );
        //window.alert(`The API returned Unsuccessfull response : ${error}`);
      },
    });
  }


  goBackCall(data: any) {
    this.spinner.show();
    console.log('goBackCall in parent.........');
    this.isBack = true;
    this.inputData = data;
    this.showTable = true;
    this.showADRCard = false;
    this.showCheckboxTable = false;
    this.tableChildComponent.initializeFunction(this.inputData);
    this.spinner.hide();
  }

  callBjlPathOverwrite(data: any) {
    this.callSelectBjl.emit(this.inputData);
  }
 
}
