<h3 *ngIf="showTable">{{'path' | titleText}}</h3>
<div *ngIf="showTable" class="card">
    <app-duct-form 
        *ngIf="showTable"
        [myForm]="myForm">
    </app-duct-form>

    <div *ngIf="showTable">
        <app-table         
            table_title="Available Paths" 
            [inputData]="inputData" 
            (appItemEvent)="receiveTableEvent($event)">
        </app-table>
    </div> 
    
</div>
<div *ngIf="showCheckboxTable">
        
    <app-table-checkbox
        [dataSource]="dataSource"
        [displayedColumns]="displayedColumns"
        [inputData]="inputData"
        [showCheckboxTable]="showCheckboxTable"
        (goBackCall)="goBackCall($event)"
        (OnAdrCall)="OnAdrCall($event)"
    ></app-table-checkbox>


</div>

<div *ngIf="showADRCard">
    <!-- <app-adr-form
            [myAdrForm]="myAdrForm"
            [showADRCard]="showADRCard"
            [inputData]="inputData"
            [selectedRow]="selectedRow"
            (goBackCall)="goBackCall($event)"
    >
    </app-adr-form> -->
    <app-upload-file [flowName]="flowName" (uploaded)="fileUploaded($event)" [showADRCard]="showADRCard"
            [inputData]="inputData" [selectedRow]="selectedRow" (goBackCall)="goBackCall($event)">
    </app-upload-file>
    <form [formGroup]="myAdrForm" (ngSubmit)="table_function_called()">

        <div class="row">
            <div class="col-md-3 form-group">
                <button type="submit" class="primary">
                        Submit
                </button>
            </div>

        </div>
    </form>
    
</div>
<div *ngIf="showMessage" class="card">
        <app-message 
            [showMessage]="showMessage"
            [MessageToShow]="MessageToShow"
            url={{url}}
            msg={{msg}}
            filename={{filename}}
        >
        </app-message>
</div>
<div *ngIf="isLoading">
    <ngx-spinner type="ball-scale-multiple"></ngx-spinner>
</div>