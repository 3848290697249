<div *ngIf="showEquipmetCard">
    <h3>{{'equipment' | titleText}}</h3>
    <div class="card">
        <app-equipment-form 
            *ngIf="showEquipmetCard"
            [equPayLoad]="equPayLoad" 
            [showEquipmetCard]="showEquipmetCard"
            (onEuipCall)="onEuipCall($event)"
        ></app-equipment-form>           

    </div>    
</div>

<!--Upstream equipement Component called-->
<div *ngIf="showTable">
    <app-lfn-upstream-equipment            
            [inputData]="tableData"
            table_title={{table_title}}
    ></app-lfn-upstream-equipment> 
</div>    

<!--Success path Component called-->
<div *ngIf="success_path">
    <app-lfn-path-generartion
        [inputData]="inputData"
        table_title = "Upstream Equipment"
    ></app-lfn-path-generartion>
</div>

<!--Structure  Component called-->
<div *ngIf="structure_screen" class="">
        <app-structure-screen
            [inputData]="inputData"
            table_title={{table_title}}
        ></app-structure-screen>
</div>

<!--ADR Component called-->
<div *ngIf="showADRCard">
  <app-adr-form 
    [inputData]="inputData" 
    [showADRCard]="showADRCard"
  ></app-adr-form>
</div>

<!-- spinner Component Called-->
<div *ngIf="isLoading">
  <ngx-spinner type = "ball-scale-multiple"></ngx-spinner> 
</div>

