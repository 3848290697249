import { HttpClient } from "@angular/common/http";
import { Component, EventEmitter, HostListener, Input, OnInit, Output, SimpleChanges } from "@angular/core";
import { FormBuilder, FormGroup, ReactiveFormsModule, Validators } from "@angular/forms";
import { ApiService } from "../../../api.service";
import { CommonModule } from "@angular/common";
import { LfnPathGenerartionComponent } from "../../lfn-flow/lfn-path-generartion/lfn-path-generartion.component";
import { NgxSpinnerService,NgxSpinnerModule } from 'ngx-spinner';
import { DfnPathGenerartionComponent } from "../../dfn-flow/dfn-path-generartion/dfn-path-generartion.component";
import { TitleTextPipe } from "../../../custom.pipe";
import { ToastrService } from "ngx-toastr";
import { AdrFormComponent } from "../adr-form/adr-form.component";
import { LfnUpstreamEquipmentComponent } from "../../lfn-flow/lfn-upstream-equipment/lfn-upstream-equipment.component";
import { Router } from '@angular/router';
import { StructureScreenComponent } from "../structure-screen/structure-screen.component";

declare var google: any;

@Component({
  selector: "app-end-equipment-screen",
  standalone: true,
  imports: [ReactiveFormsModule,
    CommonModule,
    LfnPathGenerartionComponent,
    NgxSpinnerModule,
    DfnPathGenerartionComponent,
    TitleTextPipe,
    AdrFormComponent,
    LfnUpstreamEquipmentComponent,    
    StructureScreenComponent
  ],
  templateUrl: "./end-equipment-screen.component.html",
  styleUrls: ["./end-equipment-screen.component.scss"]
})

export class EndEquipmentScreenComponent implements OnInit {
  
  markerData: any = [];
  
  selectEndEquipment = true;
  MessageToShow = '';
  showMessage: boolean = false;
  enableProceedButton: boolean = false;
  isLoading: boolean = false;
  myEquForm: FormGroup;
  success_path: boolean = false;
  success_path_dfn: boolean = false;
  showADRCard = false;
  upstream_screen: boolean = false;
  select_equipment_flag = false;
  structure_screen = false
  
  @Input() inputData: any;
  @Input() refreshVariable: any = '';
  @Input() table_title :string = '';
  @Output() appItemEvent = new EventEmitter<any>();
  map: any;
  
  structure_list = [
      {
        lat: -37.847361,
        lon: 144.991863,
        label: '000000000000000000',
      },
  ];
  markers: any[] = [];
  // structure_list:any;
  marker_icon_FF0000 = {
    url: 'data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABUAAAAiCAYAAACwaJKDAAAABmJLR0QA/wD/AP+gvaeTAAACBklEQVRIia3VzUtUURgH4GdG/AiyZZShtWgXUbSIFtGqRYtqWRLhXyBYf0K6MaJQ2gRtayHtijYpleHKSCgIcRHoIiOSKEzLKea0OOeqTfPlzPzg5Qwz9zz3nXPvPTeneo7gNA4gjyI+Ygbva8z9L2cxi9BHOE+4msY+gliz6biayWE0R7GfMEcoEkJJzRH6CbnY+WiaVxEc6yY8KQOVq8eE7tj1WCV4qIswUyeY1QyhK8JDpWAP1m7vEMzqTkTXkrOZkYOEQoNogXAowiPE2wQuDqC9nktZJu0YSE72XRs2phrsMqup2OkG2vLpRB19DXaZJc3vQHv294Um0e3z8yigsNQkmuYXUMie5/npJtE0fz55YLiXsNHELdUbV2B4+4n2Y/Vmg+itCK4m558MdhBe7hCcJnRGdLDS0ox3E17XCb4h7IngeLX1zuFhD2G5BriytY4Tqmx9WXbh3Tnl99KsLkdwAbtrgVmO4/eDCuCkzd3/TL1glru9hF8lYJFwMoKPdgrCXqzfL0GfR7CIo42gcO9YCXopolONgnAC4W0Cv9l8dVxpBoWFGwmdiOC6Glc8X+3HlKeT6cOzOLzAjyaaBBc602ZzOHZ6vVkQ9kl7Qi6ip1qBwpdrEfwjPnFVU8+awuKrOC7hZ6vQlQ9baM3Ui379HsfVVqKf07jcSvRTGhfrOfgvIP3ECS77BDoAAAAASUVORK5CYII=',
    labelOrigin: new google.maps.Point(10, 11),
  };

  marker_icon_FF0001 = {
    url: '/assets/images/home.png',
    labelOrigin: new google.maps.Point(10, 11),
  };
  marker_icon_FF0002 = {
    url: '/assets/images/highligted_icon.png',
    labelOrigin: new google.maps.Point(10, 11),
  };
  marker_icon_PCD = {
    url: '/assets/images/pcd.png',
    labelOrigin: new google.maps.Point(10, 11),
  };
  marker_icon_NEC = {
    url: '/assets/images/nec.png',
    labelOrigin: new google.maps.Point(10, 11),
  };

  
  
  constructor(
    private http: HttpClient,
    private fb: FormBuilder,
    private apiService: ApiService,
    private spinner: NgxSpinnerService,
    private toastr: ToastrService,
    private router: Router
  ) { 
    this.myEquForm = this.fb.group({
      equipment: [''],
      nec: [''],
      equpType: []
    });
  }


  ngOnInit() {

    this.structure_list = this.inputData.available_pcd_list;
    if (this.inputData.message == 'select end equipment') {
      //this.selectEndEquipment = false;
      this.structure_list = this.inputData.available_pcd_list;
      for (let data in this.inputData.available_nec_list) {
        console.log(data);
        let structure_list_home:any = {
          lat:this.inputData.available_nec_list[data]['lat'],
          lon: this.inputData.available_nec_list[data]['lon'],
          label: this.inputData.available_nec_list[data]['label'],
          type: 'nec'
        }
        this.structure_list.push(structure_list_home);
      }
      
    }
    
    let structure_list_home:any = {
      lat:this.inputData.loc_coordinate[1],
      lon: this.inputData.loc_coordinate[0],
      label: this.inputData.loc_id,
      excluded:true
    }
    this.structure_list.push(structure_list_home);

    this.initMap(
      this.structure_list[this.structure_list.length - 1].lat,
      this.structure_list[this.structure_list.length - 1].lon
    );
    this.addMarkers(this.structure_list, 'default');
  }

  ngOnChanges(changes: SimpleChanges) {
    if (changes['refreshVariable']) {
      this.receiveEvent(changes['refreshVariable'].currentValue);
      // Add your code to be executed when myVariable changes
    }
  }
  addMarkers(structure_list: any, selectedLabel: any) {
    for (let ele of structure_list) {
      const data = {
        position: new google.maps.LatLng(ele.lat, ele.lon),
        label: ele.label,
        ll_type:ele.type
      };
      this.markerData.push(data);
    }

    // Create markers and attach click event listeners
    this.markerData.forEach((data: { position: any; label: any; ll_type: any }) => {
      let marker: any;
      if (data.label.indexOf('LOC') != -1) {
        marker = new google.maps.Marker({
          position: data.position,
          label: {
            text: data.label,
            color: 'white',
          },
          icon: this.marker_icon_FF0001,
          map: this.map,
        });
      } else if (data.ll_type == 'nec') {
        marker = new google.maps.Marker({
          position: data.position,
          label: {
            text: data.label,
            color: 'red',
          },
          icon: this.marker_icon_NEC,
          map: this.map,
        });
      }
      else if (data.label == selectedLabel) {
        marker = new google.maps.Marker({
          position: data.position,
          label: data.label,
          icon: this.marker_icon_FF0002,
          map: this.map,
        });
      } else {
        marker = new google.maps.Marker({
          position: data.position,
          label: {
            text: data.label,
            color: 'white',
          },
          icon: this.marker_icon_PCD,
          map: this.map,
        });
      }

      // Add a click event listener to each marker
      marker.addListener('click', () => {
        // Handle the click event here, e.g., display the label
        this.handleMarker(marker);
        console.log('Marker clicked with label:', marker.getLabel());
      });

      // Push the marker object to the array
      this.markers.push(marker);
    });
  }

  handleMarker(_marker: any) {
    let label = _marker.getLabel();    
    if (label.color == 'white') {
      this.inputData['selected_end_equipment'] = label.text;
      this.myEquForm.controls['equipment'].setValue(label.text);
      this.myEquForm.controls['nec'].setValue('');
      this.myEquForm.controls['nec'].disable({ onlySelf: true });
      this.select_equipment_flag = false;
    }
    else {
      this.inputData['selected_nec'] = label.text;
      this.myEquForm.controls['nec'].setValue(label.text);
      this.myEquForm.controls['equipment'].setValue('');
      this.myEquForm.controls['equipment'].disable({ onlySelf: true });
      this.select_equipment_flag = true;
    }    
  }

  initMap(lat: any, long: any): void {
    console.log(lat, long );
    this.map = new google.maps.Map(document.getElementById('eq_map'), {
      center: { lat: lat, lng: long },
      zoom: 20,
      mapTypeId: 'satellite'
    });

    const marker = new google.maps.Marker({
      position: { lat: 37.7749, lng: -122.4194 },
      map: this.map,
      title: 'Marker Title',
    });
  }
  @HostListener('window:message', ['$event'])
  handleMessage(event: MessageEvent) {
    if (event.data.type === 'markerClick') {
      const label = event.data.label;
      // this.setFormValue(label)
      this.inputData['structure_id'] = label.text;
      console.log('Map clicked at:', this.inputData);
    }
  }
 
  onselectStructure() {
    // onselectStructure :- 
    this.isLoading = true;
    this.spinner.show();
    console.log(this.inputData);
    this.inputData['selected_nec'] = this.myEquForm.value.nec;
    //this.inputData['selected_end_equipment'] = this.myEquForm.value.equipment;
    
    if (this.myEquForm.value.equpType != null)
    {
      this.inputData['equipment'] = this.myEquForm.value.equpType;
    }
    else {
      this.inputData['equipment'] = '';
    }
    
    this.apiService.selectEndEquipment(this.inputData).subscribe({
      next: (response) => {
        if (response.message === 'path generation successful' || response.message === 'path generation successful type2') {
          this.selectEndEquipment = false;
          this.success_path = true;
          this.inputData = response;
        }
        else if (response.message === 'path generation successful dfn') {
          this.selectEndEquipment = false;
          this.success_path_dfn = true;
          this.inputData = response;
        }        
        else if (response.message === 'select upstream equipment') {
          this.showMessage = true;
          this.upstream_screen = true;
          this.inputData = response;
          this.selectEndEquipment = false;
        }
        else if (response.message === 'select structure') {
          this.structure_screen = true;
          this.inputData = response;
          this.selectEndEquipment = false;
        }
        else {
          this.toastr.error(
              response.message,
            'Error Message'
          );
          // this.showMessage = true;
          // this.MessageToShow = JSON.stringify(response);
        }

        this.isLoading = false;
        this.spinner.hide();
        this.showMessage = false;
        // Handle the response data
      },

      error: (error) => {
        this.isLoading = false;
        this.spinner.hide();
        window.alert(`The API returned Unsuccessfull response : ${error}`);
      },
    });
  }

  receiveEvent(data: any) {
    
  }

}
