import { Component, Input, OnInit, ViewChild, ElementRef } from "@angular/core";
import { FormBuilder, FormGroup, ReactiveFormsModule, Validators } from "@angular/forms";
import { ToastrService } from 'ngx-toastr';
import { ApiService } from "../../../api.service";
import { CommonModule } from "@angular/common";
import { Observable } from "rxjs";
import { LfnPathGenerartionComponent } from "../../lfn-flow/lfn-path-generartion/lfn-path-generartion.component";
import { LfnEquipmentFlowComponent } from "../../lfn-flow/lfn-equipment-flow/lfn-equipment-flow.component";
import { NgxSpinnerService, NgxSpinnerModule } from 'ngx-spinner';
import { HttpClient } from "@angular/common/http";
import { TitleTextPipe } from "../../../custom.pipe";
import { UploadFileComponent } from "../upload-file/upload-file.component";
import { AdrFormComponent } from "../adr-form/adr-form.component";
import { Router } from '@angular/router';
import { LfnUpstreamEquipmentComponent } from "../../lfn-flow/lfn-upstream-equipment/lfn-upstream-equipment.component";
import { StructureScreenComponent } from "../../../components/common/structure-screen/structure-screen.component";
import { EndEquipmentScreenComponent } from "../end-equipment-screen/end-equipment-screen.component";

@Component({
  selector: "app-mdu-form",
  standalone: true,
  imports: [ReactiveFormsModule,
    CommonModule,
    LfnPathGenerartionComponent,
    LfnEquipmentFlowComponent,
    NgxSpinnerModule,TitleTextPipe,
    UploadFileComponent,
    AdrFormComponent,
    LfnUpstreamEquipmentComponent,
    StructureScreenComponent,
    EndEquipmentScreenComponent
  ],
  templateUrl: "./mdu-form.component.html",
  styleUrls: ["./mdu-form.component.scss"],
})

export class MduFormComponent implements OnInit {
  isLoading: boolean = false;
  @ViewChild('fileInput', { static: false }) pathVariable: ElementRef<HTMLInputElement> = {} as ElementRef;
  myUploadForm: any = FormGroup;
  @Input() mduPayLoad: any = FormGroup;

  myMduForm: any = FormGroup;
  file: string | null = null; // Variable to store file
  flowName: string = 'MDU';

  tableData: any;
  showTable: boolean = false;
  showMDUCard: boolean = true;
  table2Data: any;
  showType2Table: boolean = false;
  showEquipmetCard: boolean = false;
  equPayLoad: any;
  showMessage: boolean = false;
  MessageToShow: any;
  showUpload: boolean = true;
  fileName = 'Select File';
  // fileInfos?: Observable<any>;
  success_path: boolean = false;
  equipment_screen = false;
  show_adr_form = false;
  upstream_screen = false;
  structure_screen = false;
  showEndEquipment = false;
  @Input() inputData: any = FormGroup;

  
  constructor(private toastr: ToastrService,
    private fb: FormBuilder,
    private spinner: NgxSpinnerService,
    private apiService: ApiService,
    private router: Router
  ) {
    this.myMduForm = this.fb.group({
      MPSBoundary: ['', Validators.required],
    });
    this.myUploadForm = this.fb.group({
      filePath: ['', Validators.required],

    });
  }

  ngOnInit() {

  }

  fileUploaded(event: string) {
    this.file = event;
  }


  onMduCall() {
    this.isLoading = true;
    this.spinner.show();
    this.mduPayLoad['mps_boundary'] = this.myMduForm.value.MPSBoundary;
    this.mduPayLoad['mdu_name'] = this.file;
    this.apiService.uploadmduospAdr(this.mduPayLoad).subscribe({
      next: (response: { message: string; }) => {
        
        if (response.message === 'path generation successful' || response.message === 'path generation successful type2') {
          this.tableData = response;
          this.showTable = true;
          this.showMDUCard = false;
          this.success_path = true;
          this.inputData = response;
        }
        else if (response.message === 'select upstream equipment adr') {
          this.showMDUCard = false;
          this.showTable = true;
          this.tableData = response;
          this.showEquipmetCard = false;
          this.upstream_screen = true;
        }
        else if (response.message === 'select equipment') {
          this.equipment_screen = true;
          this.showMDUCard = false;
          this.equPayLoad = response;
          this.showEquipmetCard = true;
          this.inputData = response;
        }
          else if (response.message === 'select structure') {
          this.showEquipmetCard = false;
          this.showMDUCard = false;
          this.showTable = false;
          this.equipment_screen = false;
          this.structure_screen = true;
          this.inputData = response;
        }
        else if (response.message === 'select end equipment') {
          this.showEquipmetCard = false;
          this.showMDUCard = false;
          this.showTable = false;
          this.equipment_screen = false;
          this.showEndEquipment = true;
          this.inputData = response;          
        }
        else if (response.message === 'receive adr') {
          this.router.navigateByUrl('/adr');         
        }
        else {
          
            this.toastr.error(response.message, 'Error Message');
          
        }
        this.isLoading = false;
        this.spinner.hide();
        // Handle the response data
      },
      error: (error: any) => {
        this.isLoading = false;
        this.spinner.hide();
        this.toastr.error(
          `The API returned Unsuccessfull response : ${error}`,
          'Error Message'
        );
        //window.alert(`The API returned Unsuccessfull response : ${error}`);
      },
    });
  }

  goBackCall(data:any) {
    
  }
}
