import { Component, Input, OnInit, ViewChild } from "@angular/core";
import { FormGroup, FormBuilder, Validators, ReactiveFormsModule, FormControl } from "@angular/forms";
import { ToastrService } from 'ngx-toastr';
import { ApiService } from "../../api.service";
import { MduFormComponent } from "../common/mdu-form/mdu-form.component";
import { CommonModule } from "@angular/common";
import { HttpClient } from "@angular/common/http";
import { LfnPathGenerartionComponent } from "../lfn-flow/lfn-path-generartion/lfn-path-generartion.component";
import { NgxSpinnerService, NgxSpinnerModule } from 'ngx-spinner';
import { LfnEquipmentFlowComponent } from "../lfn-flow/lfn-equipment-flow/lfn-equipment-flow.component";
import { DfnDjlFlowComponent } from "../dfn-flow/dfn-djl-flow/dfn-djl-flow.component";
import { EquipmentFormComponent } from "../common/equipment-form/equipment-form.component";
import { LfnUpstreamEquipmentComponent } from "../lfn-flow/lfn-upstream-equipment/lfn-upstream-equipment.component";
import { AdrFormComponent } from "../common/adr-form/adr-form.component";
import { EndEquipmentScreenComponent } from "../common/end-equipment-screen/end-equipment-screen.component";


@Component({
  selector: "app-landing-page",
  standalone: true,
  imports: [ReactiveFormsModule,
    MduFormComponent,
    LfnEquipmentFlowComponent,
    CommonModule,
    LfnPathGenerartionComponent,
    NgxSpinnerModule,
    DfnDjlFlowComponent,
    EquipmentFormComponent,
    LfnUpstreamEquipmentComponent,
    AdrFormComponent,
    EndEquipmentScreenComponent
  ],
  templateUrl: "./landing-page.component.html",
  styleUrls: ["./landing-page.component.scss"]
})

export class LandingPageComponentADR implements OnInit {
  islogin: boolean = true;
  myForm!: FormGroup;
  showDFNCard: boolean = false;
  showLFNSubmit: boolean = false;
  showMainPage: boolean = true;
  isLoading: boolean = false;
  query: string = '';
  success_path: boolean = false;
  showTable: boolean = false;
  tableData: any;
  showMDUCard: boolean = false;
  mduPayLoad: any;
  showADRCard: boolean = false;
  adrPayLoad: any;
  showEquipmetCard: boolean = false;
  equPayLoad: any;
  showMessage: boolean = false;
  MessageToShow: any;
  upstream_screen: boolean = false;
  showEndEquipment = false;

  default: string = 'Type2';
  architectureOptions: string[] = ['Type2', 'Type3'];

  
  constructor(
    private fb: FormBuilder,
    private http: HttpClient,
    private toastr: ToastrService,
    private apiService: ApiService,
    private spinner: NgxSpinnerService
  ) {
    this.myForm = this.fb.group({
      techType: ['', Validators.required],
      //builtType: ['', Validators.required],
      ufaCategory: ['', Validators.required],
      architectureType: ['Type2', Validators.required],
      //S18path: [],
      rorId: ['', Validators.required],
      locId: ['', Validators.required],
      adaId: ['', Validators.required],
      workType: ['', Validators.required]
    });

    
  }


  ngOnInit() {
    
  }

  onSubmit() {
    // onsubmit check if request is for LFN or DFN
    if (this.myForm.value.workType == 'DFN') {
      this.showDFNCard = true;
      this.showMainPage = false;
    } else {
      this.callCreateMapInfo();
    }
  }

  callCreateMapInfo() {
    // callCreateMapInfo call the api and based on api response initiate next flow like MDU, Equipment, etc. .
    this.isLoading = true;
    this.spinner.show();
    //this.query = `?tech_type=${this.myForm.value.techType}&built_type=${this.myForm.value.builtType}&ufa_category=${this.myForm.value.ufaCategory}&architecture_type=${this.myForm.value.architectureType}&s18_path=${this.myForm.value.S18path}&ror_id=${this.myForm.value.rorId}&loc_id=${this.myForm.value.locId}&ada_id=${this.myForm.value.adaId}`;
    this.query = `?tech_type=${this.myForm.value.techType}&ufa_category=${this.myForm.value.ufaCategory}&architecture_type=${this.myForm.value.architectureType}&ror_id=${this.myForm.value.rorId}&loc_id=${this.myForm.value.locId}&ada_id=${this.myForm.value.adaId}`;
    console.log('Form data submitted:', this.myForm.value);
    this.apiService.initiatemapinfodesignadr(this.query).subscribe({
      next: (response) => {
        console.log(response)
        
        if (response.message === 'upload mdu osp') {
          this.showMDUCard = true;
          this.showMainPage = false;
          this.mduPayLoad = response;
        } else if (response.message === 'select equipment') {
          this.showMainPage = false;
          this.equPayLoad = response;
          console.log(this.equPayLoad)
          this.showEquipmetCard = true;
        } else if (response.message === 'select upstream equipment adr') {
          
          this.tableData = response;
          this.showEquipmetCard = false;
          this.showMainPage = false;
          this.upstream_screen = true;
        }
        else if (response.message === 'path generation successful' ||
                response.message === 'path generation successful type2') {
          this.tableData = response;
          this.success_path = true;
          this.showMainPage = false;
          this.showMDUCard = false;
          this.upstream_screen = false;
        }else if (response.message === 'receive adr') {
          this.showADRCard = true;
          this.showMainPage = false;
          this.adrPayLoad = response;
        } else if (response.message === 'select end equipment') {
          this.tableData = response;
          this.showEquipmetCard = false;
          this.showMainPage = false;
          this.showEndEquipment = true;
        } else {
          if ('file_name' in response) {
            this.showMessage = true;
            this.MessageToShow = response;
          } else {
            this.toastr.error(response.message, 'Error Message');
            console.log('error');
          }
        }
        this.isLoading = false;
        this.spinner.hide();
        
      },
      error: (error) => {
        this.isLoading = false;
        this.spinner.hide();
        this.toastr.error(
          `The API returned Unsuccessfull response : ${error}`,
          'Error Message'
        );
      },
    });
  }

  radioSelect() {
    // Based on radio button enable and disable Architecture Type dropdown type2 value
    console.log('radioSelect');
    if (this.myForm.value.workType == 'DFN') {
      this.showDFNCard = true;
      this.showLFNSubmit = false;
      // this.selectedProperty = this.options[1];
      this.myForm.controls['architectureType'].setValue('Type3');
      this.myForm.controls['architectureType'].disable({ onlySelf: true });
    }
    else {
      this.showDFNCard = false;
      this.showLFNSubmit = true;
      this.myForm.value.architectureType = 'Type2';
      this.myForm.controls['architectureType'].setValue('Type2');
      this.myForm.controls['architectureType'].enable({ onlySelf: true });
    }
  }

  disableMainForm() {
    // Disable main form page from DFN flow
    this.showMainPage = false;
  }

}
