import { Component, Input, OnInit } from "@angular/core";
import { TableComponent } from "../../components/common/table/table.component";
import { CommonModule } from "@angular/common";
import { MapSd1Component } from "../map-sd1/map-sd1.component";
import { NgxSpinnerModule, NgxSpinnerService } from "ngx-spinner";
import { TitleTextPipe } from "../../custom.pipe";
import { ToastrService } from "ngx-toastr";
import { FormBuilder } from "@angular/forms";
import { ApiService } from "../../api.service";
import { SelectSmpSd1Component } from "../select-smp-sd1/select-smp-sd1.component";

@Component({
  selector: "app-sd1-upstream-equipment",
  standalone: true,
  imports: [
    TableComponent,
    CommonModule,
    MapSd1Component,SelectSmpSd1Component,
    NgxSpinnerModule, TitleTextPipe
  ],
  templateUrl: "./sd1-upstream-equipment.component.html",
  styleUrls: ["./sd1-upstream-equipment.component.scss"]
})

export class Sd1UpstreamEquipmentComponent implements OnInit {
  @Input() inputData: any;
  isLoading: boolean = false;
  select_bjl: boolean = false;
  upstream_screen: boolean = true;
  select_smp_screen: boolean = false;
  tableData: any;
  showMessage: boolean = false;
  MessageToShow: any;
  
  constructor(private toastr: ToastrService,
    private fb: FormBuilder,
    private apiService: ApiService,
    private spinner: NgxSpinnerService) { 

  }

  ngOnInit() {

  }
  receiveTableEventUpstream(data: any) {
    this.inputData['selected_upstream_equipment'] = data;
    this.isLoading = true;
    this.spinner.show();
    this.apiService.selectUpstreamStructure(this.inputData).subscribe({
      next: (response) => {

            if (response.message === 'select bjl structure sd1') {
              this.select_bjl = false;
              this.upstream_screen = false;
              this.inputData = response;
              this.select_smp_screen = true;
              this.tableData = response;
            }
            
            else {              
              if ('file_name' in response) {
                this.select_bjl = false;
                this.upstream_screen = false;
                this.showMessage = true;
                this.MessageToShow = response;
              } else {
                this.toastr.error(response.message, 'Error Message');
                console.log('error');
              }
            }

            this.isLoading = false;
            this.spinner.hide();
            // window.alert(response.messgae);
      }
      ,
      error: (error) => {
            this.isLoading = false;
            this.spinner.hide();
            this.toastr.error(
              `The API returned Unsuccessfull response : ${error}`,
              'Error Message'
            );
            //window.alert(`The API returned Unsuccessfull response : ${error}`);
          },
    });
  }
}
