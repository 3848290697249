import { CommonModule } from "@angular/common";
import { Component, Input, OnInit, ViewChild } from "@angular/core";
import { NgxSpinnerModule, NgxSpinnerService } from "ngx-spinner";
import { MapSd1Component } from "../map-sd1/map-sd1.component";
import { TableComponent } from "../../components/common/table/table.component";
import { TitleTextPipe } from "../../custom.pipe";
import { FormGroup, FormBuilder, Validators,ReactiveFormsModule } from "@angular/forms";
import { ToastrService } from "ngx-toastr";
import { ApiService } from "../../api.service";
import { LfnPathGenerartionComponent } from "../../components/lfn-flow/lfn-path-generartion/lfn-path-generartion.component";
import { MessageComponent } from "../../components/common/message/message.component";
import {UploadFileComponent} from "../../components/common/upload-file/upload-file.component"


@Component({
  selector: "app-smp-connectivity-sd1",
  standalone: true,
  imports: [
    TableComponent,ReactiveFormsModule,
    CommonModule,
    MapSd1Component,MessageComponent,
    NgxSpinnerModule, TitleTextPipe,UploadFileComponent
  ],
  templateUrl: "./smp-connectivity-sd1.component.html",
  styleUrls: ["./smp-connectivity-sd1.component.scss"]
})

export class SmpConnectivitySd1Component implements OnInit {
  smp_connectivity_table: boolean = false;
  
  isLoading: boolean = false;
  myEquipmetForm: any = FormGroup;
  @Input() tableData: any;
  showTable: boolean = false;
  table2Data: any;
  showType2Table: boolean = false;
  showMap: boolean = false;
  mapData: any;
  showMessage: boolean = false;
  MessageToShow: any;
  url: string = '';
  filename: string = '';
  msg: string = ''; 
  @Input() inputData: any;
  
  @ViewChild(TableComponent)
  tableChildComponent!: TableComponent;

  @ViewChild(MapSd1Component)
  mapChildComponent!: MapSd1Component;

  @ViewChild(LfnPathGenerartionComponent)
  lfnPathGeneration!: LfnPathGenerartionComponent;

  showEquipmetTable: boolean = false;
  map_div: boolean = true;
  success_path: boolean = false;
  upstream_screen: boolean = false;
  select_bjl: boolean = false;
  title: string = '';
  select_smp_screen: boolean = false;
  final_screen: boolean = false;
  showADRCard: boolean = false;
  selectedRow: any;
  flowName:string='SD1 ADR';
  myAdrForm!: FormGroup;
  alternate_path: boolean = false;
  file: string | null = null;

  constructor(private toastr: ToastrService,
    private fb: FormBuilder,
    private apiService: ApiService,
    private spinner: NgxSpinnerService
  )
  { 
      this.myEquipmetForm = this.fb.group({
            equpType: ['', Validators.required],
      });
      this.myAdrForm = this.fb.group({
        ADRpath: [''],
      });
  }
  
  ngOnInit() {
    
  if (this.inputData.message.includes(' mpt ')) {
      this.title = 'MPT';
    }
    else if (this.inputData.message.includes(' bjl ')) {
      this.title = 'BJL';      
    }
    else if (this.inputData.message.includes(' ajl ')) {
      this.title = 'AJL';      
    }
    else if (this.inputData.message.includes(' smp')) {
      this.title = 'SMP';      
    }
  }
  


  fileUploaded(event: string) {
    this.file = event;
  }

  receiveTableEvent(data:any) {    
    this.viewProfile(data);
    
  }

  viewProfile(data: any) {  
    // on select button clicked on table it'll call map component for pin point the structure id
    this.isLoading = true;
     
    if(
            ['select bjl structure sd1', 'select existing smp','select new smp'].includes(
              this.inputData.message
            )
    ) {
        if ('smp_lat' in data) {
          this.mapChildComponent.receiveTableEvent(data)
        }
    }
      else if (
      ['select mpt upstream equipment','select ajl upstream equipment'].includes(
        this.inputData.message
      )
    ) {
      this.inputData['selected_upstream_equipment'] = data;
      if (this.inputData.message == 'select ajl upstream equipment') {
        this.inputData['selected_ajl_upstream_equipment'] = [data];
      }
      
      this.apiService.selectUpstremEquipment(this.inputData).subscribe({
        next: (response) => {
          if(
            ['select bjl structure sd1', 'select mpt structure','select smp structure'].includes(
              response.message
            )
          ) {
            this.inputData = response;
            this.showTable = false;
            this.showEquipmetTable = false;
            this.tableData = response;
            this.tableChildComponent.initializeFunction(this.inputData);
            
            this.showMap = true;
            this.mapData = data;
            this.map_div = true;
            
          } else {
            this.toastr.error(
              response.message,
            'Error Message'
          );
            // this.showMessage = true;
            // this.MessageToShow = JSON.stringify(response);
          }
          this.isLoading = false;
          
          // Handle the response data
        },
        error: (error) => {
          this.isLoading = false;
          
          this.toastr.error(
            `The API returned Unsuccessfull response : ${error}`,
            'Error Message'
          );
          //window.alert(`The API returned Unsuccessfull response : ${error}`);
        },
      });
 
    }
    else {
      this.inputData['selected_path'] = data;
    }

    // this.inputData["available_paths"][0]= data;
  }

  OnSelectProceedMap(data: any) {
    // On Button click call jl structure api and based on response trigger next step
    
      this.isLoading = true;
      this.spinner.show();
      this.showTable = false;
      let smp_state_count = 0;
      let smp_state_count_error = 0;
      if ('locations_served_from_smp' in this.inputData) {
          for (let i = 0; i < this.inputData.locations_served_from_smp.length; i++){
                if ('smp_state' in this.inputData.locations_served_from_smp[i]) {
                    smp_state_count++;
                }
                else {
                  smp_state_count_error++;
                    //this.inputData.locations_served_from_smp[i]['smp_state'] = 'no';
                }
            }
      }
      if(smp_state_count_error>0){
        this.toastr.error(
                'select all mandatory fields.',
              'Error Message'
            );
        this.isLoading = false;
        this.spinner.hide();
      }
      else{
    
    this.apiService.selectSmpLocationSD1(this.inputData).subscribe({
      next: (response) => {
        // response = {
        //         "ada_id": "3BMT-05-53",
        //         "loc_id": "LOC000194788120",
        //         "ror_id": "ND000153412",
        //         "loc_type": "SDU1",
        //         "ufa_category": "low",
        //         "architecture_type": "Type3",
        //         "ada_tech_type": "FTTP",
        //         "loc_id_file": "/home/ec2-user/odms/code-base/adr_data_path/3BMT-05-53_Location.xlsx",
        //         "selected_smp": [
        //             {
        //                 "smp_id": "3BMT-05-53-SMP-010",
        //                 "smp_state": "Yes",
        //                 "smp_structure_id": "3BMT-05-53-PIT-227",
        //                 "location_id": "LOC000194788108",
        //                 "smp_port": "1",
        //                 "distance": 10.5
        //             },
        //             {
        //                 "smp_id": "3BMT-05-53-SMP-010",
        //                 "smp_state": "Yes",
        //                 "smp_structure_id": "3BMT-05-53-PIT-227",
        //                 "location_id": "LOC000194788098",
        //                 "smp_port": "2",
        //                 "distance": 11.2
        //             },
        //             {
        //                 "smp_id": "3BMT-05-53-SMP-010",
        //                 "smp_state": "Yes",
        //                 "smp_structure_id": "3BMT-05-53-PIT-227",
        //                 "location_id": "LOC000194788080",
        //                 "smp_port": "3",
        //                 "distance": 13.5
        //             },
        //             {
        //                 "smp_id": "3BMT-05-53-SMP-010",
        //                 "smp_state": "Yes",
        //                 "smp_structure_id": "3BMT-05-53-PIT-227",
        //                 "location_id": "LOC000194788149",
        //                 "smp_port": "4",
        //                 "distance": 38.4
        //             },
        //             {
        //                 "smp_id": "3BMT-05-53-SMP-010",
        //                 "smp_state": "Yes",
        //                 "smp_structure_id": "3BMT-05-53-PIT-227",
        //                 "location_id": "LOC000194788154",
        //                 "smp_port": "5",
        //                 "distance": 39.4
        //             },
        //             {
        //                 "smp_id": "3BMT-05-53-SMP-010",
        //                 "smp_state": "Yes",
        //                 "smp_structure_id": "3BMT-05-53-PIT-227",
        //                 "location_id": "LOC000194788131",
        //                 "smp_port": "6",
        //                 "distance": 40.7
        //             },
        //             {
        //                 "smp_id": "3BMT-05-53-SMP-010",
        //                 "smp_state": "Yes",
        //                 "smp_structure_id": "3BMT-05-53-PIT-227",
        //                 "location_id": "LOC000194788120",
        //                 "smp_port": "7",
        //                 "distance": 53.1
        //             },
        //             {
        //                 "smp_id": "3BMT-05-53-SMP-010",
        //                 "smp_state": "Yes",
        //                 "smp_structure_id": "3BMT-05-53-PIT-227",
        //                 "location_id": "LOC000194788112",
        //                 "smp_port": "8",
        //                 "distance": 55.4
        //             },
        //             {
        //                 "smp_id": "3BMT-05-53-SMP-011",
        //                 "smp_state": "Yes",
        //                 "smp_structure_id": "3BMT-05-53-PIT-221",
        //                 "location_id": "LOC000194788235",
        //                 "smp_port": "1",
        //                 "distance": 9.8
        //             },
        //             {
        //                 "smp_id": "3BMT-05-53-SMP-011",
        //                 "smp_state": "Yes",
        //                 "smp_structure_id": "3BMT-05-53-PIT-221",
        //                 "location_id": "LOC000194788226",
        //                 "smp_port": "2",
        //                 "distance": 12.1
        //             },
        //             {
        //                 "smp_id": "3BMT-05-53-SMP-011",
        //                 "smp_state": "Yes",
        //                 "smp_structure_id": "3BMT-05-53-PIT-221",
        //                 "location_id": "LOC000194788219",
        //                 "smp_port": "3",
        //                 "distance": 34.1
        //             },
        //             {
        //                 "smp_id": "3BMT-05-53-SMP-011",
        //                 "smp_state": "Yes",
        //                 "smp_structure_id": "3BMT-05-53-PIT-221",
        //                 "location_id": "LOC000194788203",
        //                 "smp_port": "4",
        //                 "distance": 61.2
        //             },
        //             {
        //                 "smp_id": "3BMT-05-53-SMP-011",
        //                 "smp_state": "Yes",
        //                 "smp_structure_id": "3BMT-05-53-PIT-221",
        //                 "location_id": "LOC000194788257",
        //                 "smp_port": "5",
        //                 "distance": 73.0
        //             },
        //             {
        //                 "smp_id": "3BMT-05-53-SMP-011",
        //                 "smp_state": "Yes",
        //                 "smp_structure_id": "3BMT-05-53-PIT-221",
        //                 "location_id": "LOC000194788261",
        //                 "smp_port": "6",
        //                 "distance": 75.0
        //             },
        //             {
        //                 "smp_id": "3BMT-05-53-SMP-011",
        //                 "smp_state": "Yes",
        //                 "smp_structure_id": "3BMT-05-53-PIT-221",
        //                 "location_id": "LOC000194788242",
        //                 "smp_port": "7",
        //                 "distance": 75.3
        //             },
        //             {
        //                 "smp_id": "3BMT-05-53-SMP-011",
        //                 "smp_state": "Yes",
        //                 "smp_structure_id": "3BMT-05-53-PIT-221",
        //                 "location_id": "LOC000194788300",
        //                 "smp_port": "8",
        //                 "distance": 112.1
        //             },
        //             {
        //                 "smp_id": "3BMT-05-53-SMP-012",
        //                 "smp_state": "Yes",
        //                 "smp_structure_id": "3BMT-05-53-PIT-217",
        //                 "location_id": "LOC000194788316",
        //                 "smp_port": "1",
        //                 "distance": 21.0
        //             },
        //             {
        //                 "smp_id": "3BMT-05-53-SMP-012",
        //                 "smp_state": "Yes",
        //                 "smp_structure_id": "3BMT-05-53-PIT-217",
        //                 "location_id": "LOC000194788183",
        //                 "smp_port": "2",
        //                 "distance": 43.5
        //             },
        //             {
        //                 "smp_id": "3BMT-05-53-SMP-012",
        //                 "smp_state": "Yes",
        //                 "smp_structure_id": "3BMT-05-53-PIT-217",
        //                 "location_id": "LOC000194788196",
        //                 "smp_port": "3",
        //                 "distance": 44.4
        //             },
        //             {
        //                 "smp_id": "3BMT-05-53-SMP-012",
        //                 "smp_state": "Yes",
        //                 "smp_structure_id": "3BMT-05-53-PIT-217",
        //                 "location_id": "LOC000194788177",
        //                 "smp_port": "4",
        //                 "distance": 45.3
        //             },
        //             {
        //                 "smp_id": "3BMT-05-53-SMP-012",
        //                 "smp_state": "Yes",
        //                 "smp_structure_id": "3BMT-05-53-PIT-217",
        //                 "location_id": "LOC000194788328",
        //                 "smp_port": "5",
        //                 "distance": 49.4
        //             },
        //             {
        //                 "smp_id": "3BMT-05-53-SMP-012",
        //                 "smp_state": "Yes",
        //                 "smp_structure_id": "3BMT-05-53-PIT-217",
        //                 "location_id": "LOC000194788337",
        //                 "smp_port": "6",
        //                 "distance": 51.7
        //             },
        //             {
        //                 "smp_id": "3BMT-05-53-SMP-012",
        //                 "smp_state": "Yes",
        //                 "smp_structure_id": "3BMT-05-53-PIT-217",
        //                 "location_id": "LOC000194788290",
        //                 "smp_port": "7",
        //                 "distance": 65.1
        //             },
        //             {
        //                 "smp_id": "3BMT-05-53-SMP-012",
        //                 "smp_state": "Yes",
        //                 "smp_structure_id": "3BMT-05-53-PIT-217",
        //                 "location_id": "LOC000194788165",
        //                 "smp_port": "8",
        //                 "distance": 70.8
        //             },
        //             {
        //                 "smp_id": "3BMT-05-53-SMP-013",
        //                 "smp_state": "Yes",
        //                 "smp_structure_id": "3BMT-05-53-PIT-216",
        //                 "location_id": "LOC000194788359",
        //                 "smp_port": "1",
        //                 "distance": 58.8
        //             },
        //             {
        //                 "smp_id": "3BMT-05-53-SMP-013",
        //                 "smp_state": "Yes",
        //                 "smp_structure_id": "3BMT-05-53-PIT-216",
        //                 "location_id": "LOC000194788344",
        //                 "smp_port": "2",
        //                 "distance": 61.1
        //             },
        //             {
        //                 "smp_id": "3BMT-05-53-SMP-013",
        //                 "smp_state": "Yes",
        //                 "smp_structure_id": "3BMT-05-53-PIT-216",
        //                 "location_id": "LOC000194788288",
        //                 "smp_port": "3",
        //                 "distance": 125.6
        //             },
        //             {
        //                 "smp_id": "3BMT-05-53-SMP-013",
        //                 "smp_state": "Yes",
        //                 "smp_structure_id": "3BMT-05-53-PIT-216",
        //                 "location_id": "LOC000194788274",
        //                 "smp_port": "4",
        //                 "distance": 127.8
        //             },
        //             {
        //                 "smp_id": "3BMT-05-53-SMP-013",
        //                 "smp_state": "Yes",
        //                 "smp_structure_id": "3BMT-05-53-PIT-216",
        //                 "location_id": "LOC000194788371",
        //                 "smp_port": "5",
        //                 "distance": 172.6
        //             },
        //             {
        //                 "smp_id": "3BMT-05-53-SMP-013",
        //                 "smp_state": "Yes",
        //                 "smp_structure_id": "3BMT-05-53-PIT-216",
        //                 "location_id": "LOC000194788363",
        //                 "smp_port": "6",
        //                 "distance": 174.9
        //             },
        //             {
        //                 "smp_id": "3BMT-05-53-SMP-013",
        //                 "smp_state": "Yes",
        //                 "smp_structure_id": "3BMT-05-53-PIT-216",
        //                 "location_id": "LOC000194788476",
        //                 "smp_port": "7",
        //                 "distance": 204.4
        //             },
        //             {
        //                 "smp_id": "3BMT-05-53-SMP-013",
        //                 "smp_state": "Yes",
        //                 "smp_structure_id": "3BMT-05-53-PIT-216",
        //                 "location_id": "LOC000194788469",
        //                 "smp_port": "8",
        //                 "distance": 206.7
        //             },
        //             {
        //                 "smp_id": "3BMT-05-53-SMP-014",
        //                 "smp_state": "Yes",
        //                 "smp_structure_id": "3BMT-05-53-PIT-214",
        //                 "location_id": "LOC000194788482",
        //                 "smp_port": "1",
        //                 "distance": 52.3
        //             },
        //             {
        //                 "smp_id": "3BMT-05-53-SMP-014",
        //                 "smp_state": "Yes",
        //                 "smp_structure_id": "3BMT-05-53-PIT-214",
        //                 "location_id": "LOC000194788495",
        //                 "smp_port": "2",
        //                 "distance": 54.3
        //             },
        //             {
        //                 "smp_id": "3BMT-05-53-SMP-014",
        //                 "smp_state": "Yes",
        //                 "smp_structure_id": "3BMT-05-53-PIT-214",
        //                 "location_id": "LOC000194788407",
        //                 "smp_port": "3",
        //                 "distance": 55.3
        //             },
        //             {
        //                 "smp_id": "3BMT-05-53-SMP-014",
        //                 "smp_state": "Yes",
        //                 "smp_structure_id": "3BMT-05-53-PIT-214",
        //                 "location_id": "LOC000194788411",
        //                 "smp_port": "4",
        //                 "distance": 57.7
        //             },
        //             {
        //                 "smp_id": "3BMT-05-53-SMP-014",
        //                 "smp_state": "Yes",
        //                 "smp_structure_id": "3BMT-05-53-PIT-214",
        //                 "location_id": "LOC000194788392",
        //                 "smp_port": "5",
        //                 "distance": 65.4
        //             },
        //             {
        //                 "smp_id": "3BMT-05-53-SMP-014",
        //                 "smp_state": "Yes",
        //                 "smp_structure_id": "3BMT-05-53-PIT-214",
        //                 "location_id": "LOC000194788385",
        //                 "smp_port": "6",
        //                 "distance": 67.7
        //             },
        //             {
        //                 "smp_id": "3BMT-05-53-SMP-014",
        //                 "smp_state": "Yes",
        //                 "smp_structure_id": "3BMT-05-53-PIT-214",
        //                 "location_id": "LOC000194788448",
        //                 "smp_port": "7",
        //                 "distance": 79.0
        //             },
        //             {
        //                 "smp_id": "3BMT-05-53-SMP-014",
        //                 "smp_state": "Yes",
        //                 "smp_structure_id": "3BMT-05-53-PIT-214",
        //                 "location_id": "LOC000194788453",
        //                 "smp_port": "8",
        //                 "distance": 81.3
        //             },
        //             {
        //                 "smp_id": "3BMT-05-53-SMP-015",
        //                 "smp_state": "Yes",
        //                 "smp_structure_id": "3BMT-05-53-PIT-213",
        //                 "location_id": "LOC000194788430",
        //                 "smp_port": "1",
        //                 "distance": 48.6
        //             },
        //             {
        //                 "smp_id": "3BMT-05-53-SMP-015",
        //                 "smp_state": "Yes",
        //                 "smp_structure_id": "3BMT-05-53-PIT-213",
        //                 "location_id": "LOC000194788424",
        //                 "smp_port": "2",
        //                 "distance": 50.5
        //             }
        //         ],
        //         "location_cords_list": [],
        //         "table_columns": [
        //             "smp_id",
        //             "smp_state",
        //             "location_id",
        //             "distance"
        //         ],
        //         "message": "sd1 process completed",
        //         "location_cords_list_served": [
        //             {
        //                 "loc_label": "LOC000194788183",
        //                 "loc_lat": -38.238101,
        //                 "loc_lon": 144.326129
        //             },
        //             {
        //                 "loc_label": "LOC000194788149",
        //                 "loc_lat": -38.238388,
        //                 "loc_lon": 144.325891
        //             },
        //             {
        //                 "loc_label": "LOC000194788131",
        //                 "loc_lat": -38.238505,
        //                 "loc_lon": 144.325869
        //             },
        //             {
        //                 "loc_label": "LOC000194788177",
        //                 "loc_lat": -38.238085,
        //                 "loc_lon": 144.325973
        //             },
        //             {
        //                 "loc_label": "LOC000194788316",
        //                 "loc_lat": -38.237674,
        //                 "loc_lon": 144.326194
        //             },
        //             {
        //                 "loc_label": "LOC000194788328",
        //                 "loc_lat": -38.237653,
        //                 "loc_lon": 144.326013
        //             },
        //             {
        //                 "loc_label": "LOC000194788337",
        //                 "loc_lat": -38.237632,
        //                 "loc_lon": 144.325827
        //             },
        //             {
        //                 "loc_label": "LOC000194788203",
        //                 "loc_lat": -38.23813,
        //                 "loc_lon": 144.326426
        //             },
        //             {
        //                 "loc_label": "LOC000194788196",
        //                 "loc_lat": -38.238117,
        //                 "loc_lon": 144.326292
        //             },
        //             {
        //                 "loc_label": "LOC000194788344",
        //                 "loc_lat": -38.237613,
        //                 "loc_lon": 144.325664
        //             },
        //             {
        //                 "loc_label": "LOC000194788359",
        //                 "loc_lat": -38.237591,
        //                 "loc_lon": 144.325471
        //             },
        //             {
        //                 "loc_label": "LOC000194788363",
        //                 "loc_lat": -38.237302,
        //                 "loc_lon": 144.325498
        //             },
        //             {
        //                 "loc_label": "LOC000194788154",
        //                 "loc_lat": -38.238273,
        //                 "loc_lon": 144.325912
        //             },
        //             {
        //                 "loc_label": "LOC000194788165",
        //                 "loc_lat": -38.238071,
        //                 "loc_lon": 144.325825
        //             },
        //             {
        //                 "loc_label": "LOC000194788371",
        //                 "loc_lat": -38.237318,
        //                 "loc_lon": 144.325649
        //             },
        //             {
        //                 "loc_label": "LOC000194788261",
        //                 "loc_lat": -38.238381,
        //                 "loc_lon": 144.326855
        //             },
        //             {
        //                 "loc_label": "LOC000194788257",
        //                 "loc_lat": -38.238503,
        //                 "loc_lon": 144.326833
        //             },
        //             {
        //                 "loc_label": "LOC000194788424",
        //                 "loc_lat": -38.236959,
        //                 "loc_lon": 144.326558
        //             },
        //             {
        //                 "loc_label": "LOC000194788411",
        //                 "loc_lat": -38.237401,
        //                 "loc_lon": 144.326355
        //             },
        //             {
        //                 "loc_label": "LOC000194788407",
        //                 "loc_lat": -38.237372,
        //                 "loc_lon": 144.32613
        //             },
        //             {
        //                 "loc_label": "LOC000194788392",
        //                 "loc_lat": -38.237359,
        //                 "loc_lon": 144.325992
        //             },
        //             {
        //                 "loc_label": "LOC000194788385",
        //                 "loc_lat": -38.237339,
        //                 "loc_lon": 144.32582
        //             },
        //             {
        //                 "loc_label": "LOC000194788476",
        //                 "loc_lat": -38.236868,
        //                 "loc_lon": 144.325767
        //             },
        //             {
        //                 "loc_label": "LOC000194788469",
        //                 "loc_lat": -38.236885,
        //                 "loc_lon": 144.325914
        //             },
        //             {
        //                 "loc_label": "LOC000194788482",
        //                 "loc_lat": -38.236849,
        //                 "loc_lon": 144.325605
        //             },
        //             {
        //                 "loc_label": "LOC000194788226",
        //                 "loc_lat": -38.238437,
        //                 "loc_lon": 144.326274
        //             },
        //             {
        //                 "loc_label": "LOC000194788453",
        //                 "loc_lat": -38.236905,
        //                 "loc_lon": 144.32609
        //             },
        //             {
        //                 "loc_label": "LOC000194788430",
        //                 "loc_lat": -38.236943,
        //                 "loc_lon": 144.326414
        //             },
        //             {
        //                 "loc_label": "LOC000194788448",
        //                 "loc_lat": -38.236925,
        //                 "loc_lon": 144.326263
        //             },
        //             {
        //                 "loc_label": "LOC000194788495",
        //                 "loc_lat": -38.236829,
        //                 "loc_lon": 144.325429
        //             },
        //             {
        //                 "loc_label": "LOC000194788288",
        //                 "loc_lat": -38.238195,
        //                 "loc_lon": 144.326971
        //             },
        //             {
        //                 "loc_label": "LOC000194788274",
        //                 "loc_lat": -38.238176,
        //                 "loc_lon": 144.326796
        //             },
        //             {
        //                 "loc_label": "LOC000194788219",
        //                 "loc_lat": -38.238314,
        //                 "loc_lon": 144.326297
        //             },
        //             {
        //                 "loc_label": "LOC000194788290",
        //                 "loc_lat": -38.237819,
        //                 "loc_lon": 144.326951
        //             },
        //             {
        //                 "loc_label": "LOC000194788120",
        //                 "loc_lat": -38.237932,
        //                 "loc_lon": 144.325429
        //             },
        //             {
        //                 "loc_label": "LOC000194788112",
        //                 "loc_lat": -38.238039,
        //                 "loc_lon": 144.325347
        //             },
        //             {
        //                 "loc_label": "LOC000194788108",
        //                 "loc_lat": -38.238175,
        //                 "loc_lon": 144.325321
        //             },
        //             {
        //                 "loc_label": "LOC000194788098",
        //                 "loc_lat": -38.238306,
        //                 "loc_lon": 144.325297
        //             },
        //             {
        //                 "loc_label": "LOC000194788242",
        //                 "loc_lat": -38.238614,
        //                 "loc_lon": 144.326812
        //             },
        //             {
        //                 "loc_label": "LOC000194788235",
        //                 "loc_lat": -38.238561,
        //                 "loc_lon": 144.326251
        //             },
        //             {
        //                 "loc_label": "LOC000194788300",
        //                 "loc_lat": -38.237761,
        //                 "loc_lon": 144.326458
        //             },
        //             {
        //                 "loc_label": "LOC000194788080",
        //                 "loc_lat": -38.238435,
        //                 "loc_lon": 144.325272
        //             }
        //         ],
        //         "selected_bjl": {
        //             "bjl_id": "3BMT-05-53-BJL-003",
        //             "bjl_structure_id": "3BMT-05-53-PIT-223",
        //             "bjl_spare_port": "4",
        //             "new_smp_size": "8",
        //             "new_splitter": "No",
        //             "sjr_trace": {
        //                 "0_equip": "3BMT-05-53-BJL-003",
        //                 "0_port": "4",
        //                 "1_cable": "3BMT-05-53-FSL-003",
        //                 "1_equip": "3BMT-05-53-FJL-001_3BMT-05-53-SPL-105",
        //                 "1_port": "3",
        //                 "upstream_connectivity_flag": true
        //             },
        //             "comment": "",
        //             "warning_message": ""
        //         },
        //         "bjl_with_capacity": [],
        //         "new_smp_list": [
        //             {
        //                 "smp_id": "3BMT-05-53-SMP-010",
        //                 "smp_status": "new",
        //                 "sss_cable_id": "3BMT-05-53-SSS-010",
        //                 "structure_id": "3BMT-05-53-PIT-227",
        //                 "free_ports": [
        //                     "1",
        //                     "2",
        //                     "3",
        //                     "4",
        //                     "5",
        //                     "6",
        //                     "7",
        //                     "8"
        //                 ],
        //                 "bjl_id": "3BMT-05-53-BJL-001",
        //                 "sjr_trace": {
        //                     "0_cable": "3BMT-05-53-SSS-010",
        //                     "0_equip": "3BMT-05-53-BJL-001",
        //                     "0_port": "3",
        //                     "1_cable": "3BMT-05-53-FSL-001",
        //                     "1_equip": "3BMT-05-53-FJL-001_3BMT-05-53-SPL-103",
        //                     "1_port": "2",
        //                     "upstream_connectivity_flag": true
        //                 },
        //                 "sjr_connectivity_flag": true
        //             },
        //             {
        //                 "smp_id": "3BMT-05-53-SMP-011",
        //                 "smp_status": "new",
        //                 "sss_cable_id": "3BMT-05-53-SSS-011",
        //                 "structure_id": "3BMT-05-53-PIT-221",
        //                 "free_ports": [
        //                     "1",
        //                     "2",
        //                     "3",
        //                     "4",
        //                     "5",
        //                     "6",
        //                     "7",
        //                     "8"
        //                 ],
        //                 "bjl_id": "3BMT-05-53-BJL-001",
        //                 "sjr_trace": {
        //                     "0_cable": "3BMT-05-53-SSS-011",
        //                     "0_equip": "3BMT-05-53-BJL-001",
        //                     "0_port": "4",
        //                     "1_cable": "3BMT-05-53-FSL-001",
        //                     "1_equip": "3BMT-05-53-FJL-001_3BMT-05-53-SPL-103",
        //                     "1_port": "3",
        //                     "upstream_connectivity_flag": true
        //                 },
        //                 "sjr_connectivity_flag": true
        //             },
        //             {
        //                 "smp_id": "3BMT-05-53-SMP-012",
        //                 "smp_status": "new",
        //                 "sss_cable_id": "3BMT-05-53-SSS-012",
        //                 "structure_id": "3BMT-05-53-PIT-217",
        //                 "free_ports": [
        //                     "1",
        //                     "2",
        //                     "3",
        //                     "4",
        //                     "5",
        //                     "6",
        //                     "7",
        //                     "8"
        //                 ],
        //                 "bjl_id": "3BMT-05-53-BJL-003",
        //                 "sjr_trace": {
        //                     "0_cable": "3BMT-05-53-SSS-012",
        //                     "0_equip": "3BMT-05-53-BJL-003",
        //                     "0_port": "1",
        //                     "1_cable": "3BMT-05-53-FSL-003",
        //                     "1_equip": "3BMT-05-53-FJL-001_3BMT-05-53-SPL-104",
        //                     "1_port": "4"
        //                 },
        //                 "sjr_connectivity_flag": true
        //             },
        //             {
        //                 "smp_id": "3BMT-05-53-SMP-013",
        //                 "smp_status": "new",
        //                 "sss_cable_id": "3BMT-05-53-SSS-013",
        //                 "structure_id": "3BMT-05-53-PIT-216",
        //                 "free_ports": [
        //                     "1",
        //                     "2",
        //                     "3",
        //                     "4",
        //                     "5",
        //                     "6",
        //                     "7",
        //                     "8"
        //                 ],
        //                 "bjl_id": "3BMT-05-53-BJL-003",
        //                 "sjr_trace": {
        //                     "0_cable": "3BMT-05-53-SSS-013",
        //                     "0_equip": "3BMT-05-53-BJL-003",
        //                     "0_port": "2",
        //                     "1_cable": "3BMT-05-53-FSL-003",
        //                     "1_equip": "3BMT-05-53-FJL-001_3BMT-05-53-SPL-105",
        //                     "1_port": "1",
        //                     "upstream_connectivity_flag": true,
        //                     "new_splitter": "Yes",
        //                     "fsd_port": "5",
        //                     "fsd_cable": "3BMT-05-53-FSD-001",
        //                     "comment": "New Splitter: 3BMT-05-53-SPL-105 will be created in FJL: 3BMT-05-53-FJL-001",
        //                     "warning_message": ""
        //                 },
        //                 "sjr_connectivity_flag": true
        //             },
        //             {
        //                 "smp_id": "3BMT-05-53-SMP-014",
        //                 "smp_status": "new",
        //                 "sss_cable_id": "3BMT-05-53-SSS-014",
        //                 "structure_id": "3BMT-05-53-PIT-214",
        //                 "free_ports": [
        //                     "1",
        //                     "2",
        //                     "3",
        //                     "4",
        //                     "5",
        //                     "6",
        //                     "7",
        //                     "8"
        //                 ],
        //                 "bjl_id": "3BMT-05-53-BJL-003",
        //                 "sjr_trace": {
        //                     "0_cable": "3BMT-05-53-SSS-014",
        //                     "0_equip": "3BMT-05-53-BJL-003",
        //                     "0_port": "3",
        //                     "1_cable": "3BMT-05-53-FSL-003",
        //                     "1_equip": "3BMT-05-53-FJL-001_3BMT-05-53-SPL-105",
        //                     "1_port": "2",
        //                     "upstream_connectivity_flag": true
        //                 },
        //                 "sjr_connectivity_flag": true
        //             },
        //             {
        //                 "smp_id": "3BMT-05-53-SMP-015",
        //                 "smp_status": "new",
        //                 "sss_cable_id": "3BMT-05-53-SSS-015",
        //                 "structure_id": "3BMT-05-53-PIT-213",
        //                 "free_ports": [
        //                     "1",
        //                     "2",
        //                     "3",
        //                     "4",
        //                     "5",
        //                     "6",
        //                     "7",
        //                     "8"
        //                 ],
        //                 "bjl_id": "3BMT-05-53-BJL-003",
        //                 "sjr_trace": {
        //                     "0_cable": "3BMT-05-53-SSS-015",
        //                     "0_equip": "3BMT-05-53-BJL-003",
        //                     "0_port": "4",
        //                     "1_cable": "3BMT-05-53-FSL-003",
        //                     "1_equip": "3BMT-05-53-FJL-001_3BMT-05-53-SPL-105",
        //                     "1_port": "3",
        //                     "upstream_connectivity_flag": true
        //                 },
        //                 "sjr_connectivity_flag": true
        //             }
        //         ],
        //         "new_bjl_list": [
        //             [
        //                 "3BMT-05-53-BJL-003",
        //                 "3BMT-05-53-FSL-003",
        //                 "3BMT-05-53-PIT-223"
        //             ]
        //         ],
        //         "selected_smp_structure": {
        //             "bjl_id": "3BMT-05-53-BJL-003",
        //             "bjl_structure_id": "3BMT-05-53-PIT-223",
        //             "structure_to_bjl_distance": 287.8,
        //             "structure_id": "3BMT-05-53-PIT-213",
        //             "potential_smp_structure_id": "3BMT-05-53-PIT-213",
        //             "label": "3BMT-05-53-PIT-213",
        //             "lat": -38.237205,
        //             "lon": 144.326181
        //         },
        //         "smp_structure_list": [
        //             {
        //                 "bjl_id": "3BMT-05-53-BJL-003",
        //                 "bjl_structure_id": "3BMT-05-53-PIT-223",
        //                 "structure_to_bjl_distance": 28.1,
        //                 "structure_id": "3BMT-05-53-PIT-222",
        //                 "potential_smp_structure_id": "3BMT-05-53-PIT-222",
        //                 "label": "3BMT-05-53-PIT-222",
        //                 "lat": -38.238359,
        //                 "lon": 144.326501
        //             },
        //             {
        //                 "bjl_id": "3BMT-05-53-BJL-003",
        //                 "bjl_structure_id": "3BMT-05-53-PIT-223",
        //                 "structure_to_bjl_distance": 39.1,
        //                 "structure_id": "3BMT-05-53-PIT-217",
        //                 "potential_smp_structure_id": "3BMT-05-53-PIT-217",
        //                 "label": "3BMT-05-53-PIT-217",
        //                 "lat": -38.23785,
        //                 "lon": 144.32644
        //             },
        //             {
        //                 "bjl_id": "3BMT-05-53-BJL-003",
        //                 "bjl_structure_id": "3BMT-05-53-PIT-223",
        //                 "structure_to_bjl_distance": 54.2,
        //                 "structure_id": "3BMT-05-53-PIT-221",
        //                 "potential_smp_structure_id": "3BMT-05-53-PIT-221",
        //                 "label": "3BMT-05-53-PIT-221",
        //                 "lat": -38.238593,
        //                 "lon": 144.326464
        //             },
        //             {
        //                 "bjl_id": "3BMT-05-53-BJL-003",
        //                 "bjl_structure_id": "3BMT-05-53-PIT-223",
        //                 "structure_to_bjl_distance": 79.8,
        //                 "structure_id": "3BMT-05-53-PIT-216",
        //                 "potential_smp_structure_id": "3BMT-05-53-PIT-216",
        //                 "label": "3BMT-05-53-PIT-216",
        //                 "lat": -38.237796,
        //                 "lon": 144.325965
        //             },
        //             {
        //                 "bjl_id": "3BMT-05-53-BJL-003",
        //                 "bjl_structure_id": "3BMT-05-53-PIT-223",
        //                 "structure_to_bjl_distance": 89.2,
        //                 "structure_id": "3BMT-05-53-PIT-218",
        //                 "potential_smp_structure_id": "3BMT-05-53-PIT-218",
        //                 "label": "3BMT-05-53-PIT-218",
        //                 "lat": -38.23792,
        //                 "lon": 144.32704
        //             },
        //             {
        //                 "bjl_id": "3BMT-05-53-BJL-003",
        //                 "bjl_structure_id": "3BMT-05-53-PIT-223",
        //                 "structure_to_bjl_distance": 126.0,
        //                 "structure_id": "3BMT-05-53-PIT-215",
        //                 "potential_smp_structure_id": "3BMT-05-53-PIT-215",
        //                 "label": "3BMT-05-53-PIT-215",
        //                 "lat": -38.237733,
        //                 "lon": 144.325424
        //             },
        //             {
        //                 "bjl_id": "3BMT-05-53-BJL-003",
        //                 "bjl_structure_id": "3BMT-05-53-PIT-223",
        //                 "structure_to_bjl_distance": 157.0,
        //                 "structure_id": "3BMT-05-53-PIT-229",
        //                 "potential_smp_structure_id": "3BMT-05-53-PIT-229",
        //                 "label": "3BMT-05-53-PIT-229",
        //                 "lat": -38.237585,
        //                 "lon": 144.325213
        //             },
        //             {
        //                 "bjl_id": "3BMT-05-53-BJL-003",
        //                 "bjl_structure_id": "3BMT-05-53-PIT-223",
        //                 "structure_to_bjl_distance": 163.8,
        //                 "structure_id": "3BMT-05-53-PIT-228",
        //                 "potential_smp_structure_id": "3BMT-05-53-PIT-228",
        //                 "label": "3BMT-05-53-PIT-228",
        //                 "lat": -38.237937,
        //                 "lon": 144.325601
        //             },
        //             {
        //                 "bjl_id": "3BMT-05-53-BJL-003",
        //                 "bjl_structure_id": "3BMT-05-53-PIT-223",
        //                 "structure_to_bjl_distance": 199.0,
        //                 "structure_id": "3BMT-05-53-PIT-230",
        //                 "potential_smp_structure_id": "3BMT-05-53-PIT-230",
        //                 "label": "3BMT-05-53-PIT-230",
        //                 "lat": -38.23721,
        //                 "lon": 144.325272
        //             },
        //             {
        //                 "bjl_id": "3BMT-05-53-BJL-003",
        //                 "bjl_structure_id": "3BMT-05-53-PIT-223",
        //                 "structure_to_bjl_distance": 205.2,
        //                 "structure_id": "3BMT-05-53-PIT-227",
        //                 "potential_smp_structure_id": "3BMT-05-53-PIT-227",
        //                 "label": "3BMT-05-53-PIT-227",
        //                 "lat": -38.238317,
        //                 "lon": 144.325541
        //             },
        //             {
        //                 "bjl_id": "3BMT-05-53-BJL-003",
        //                 "bjl_structure_id": "3BMT-05-53-PIT-223",
        //                 "structure_to_bjl_distance": 244.4,
        //                 "structure_id": "3BMT-05-53-PIT-214",
        //                 "potential_smp_structure_id": "3BMT-05-53-PIT-214",
        //                 "label": "3BMT-05-53-PIT-214",
        //                 "lat": -38.237148,
        //                 "lon": 144.325684
        //             },
        //             {
        //                 "bjl_id": "3BMT-05-53-BJL-003",
        //                 "bjl_structure_id": "3BMT-05-53-PIT-223",
        //                 "structure_to_bjl_distance": 287.8,
        //                 "structure_id": "3BMT-05-53-PIT-213",
        //                 "potential_smp_structure_id": "3BMT-05-53-PIT-213",
        //                 "label": "3BMT-05-53-PIT-213",
        //                 "lat": -38.237205,
        //                 "lon": 144.326181
        //             }
        //         ],
        //         "locations_served_from_smp": [
        //             {
        //                 "smp_id": "3BMT-05-53-SMP-015",
        //                 "smp_status": "new",
        //                 "smp_port": "1",
        //                 "suggested_location_id": "LOC000194788430",
        //                 "distance": 48.6,
        //                 "smp_label": "3BMT-05-53-SMP-015",
        //                 "smp_lat": -38.237205,
        //                 "smp_lon": 144.326181
        //             },
        //             {
        //                 "smp_id": "3BMT-05-53-SMP-015",
        //                 "smp_status": "new",
        //                 "smp_port": "2",
        //                 "suggested_location_id": "LOC000194788424",
        //                 "distance": 50.5,
        //                 "smp_label": "3BMT-05-53-SMP-015",
        //                 "smp_lat": -38.237205,
        //                 "smp_lon": 144.326181
        //             }
        //         ]
        // }
        if (response.message === "select bjl sd1") {
            this.map_div = false;
            this.inputData = response;
            this.select_bjl = true;   
            this.tableData = response;
        }
        else if (response.message == "select existing smp") {
          this.inputData = response;
          this.tableData = response;
            this.tableChildComponent.initializeFunction(this.inputData);            
            this.showMap = true;
            this.mapData = data;
            this.map_div = true;
            this.mapChildComponent.receiveTableEvent(this.inputData,'yes');
        }
        else if (response.message === 'select upstream equipment sd1') {
              this.map_div = false;
              this.select_bjl = false;
              this.upstream_screen = true;
              this.inputData = response;
              this.tableData = response;
              this.tableChildComponent.initializeFunction(this.inputData);
        }
        else if (response.message == 'sd1 process completed') {
              this.map_div = false;
              this.select_smp_screen = false;
              this.upstream_screen = false;
              this.select_bjl = false;
              this.final_screen = true;
              this.tableData = response;
              this.inputData = response;
        }
        else {
            this.toastr.error(
                response.message,
              'Error Message'
            );
          }

        this.isLoading = false;
        this.spinner.hide();
      },
        error: (error) => {
          this.isLoading = false;
          this.spinner.hide();
          window.alert(`The API returned Unsuccessfull response : ${error}`);
        },
      });
    
    }
    }

  receiveTableEventBJL(data:any) {
    this.inputData['selected_bjl'] = data;
    this.isLoading = true;
    this.spinner.show()  
    this.apiService.selectBjlSD1(this.inputData).subscribe({
      next: (response) => {

            if (response.message === 'select smp structure') {
              this.select_bjl = false;
              this.upstream_screen = false;
              this.inputData = response;
              this.select_smp_screen = true;
              this.tableData = response;
            }
            else if (response.message == 'select bjl sd1') {
              this.select_bjl = true;
              this.upstream_screen = false;
              this.inputData = response;
              this.tableData = response;
              this.tableChildComponent.initializeFunction(this.inputData);
            }
              else if (response.message == 'select upstream equipment sd1') {
              this.select_bjl = false;
              this.upstream_screen = true;
              this.inputData = response;
              this.tableData = response;
              this.tableChildComponent.initializeFunction(this.inputData);
            }
            else {              
              if ('file_name' in response) {
                this.select_bjl = false;
                this.upstream_screen = false;
                this.showMessage = true;
                this.MessageToShow = response;
              } else {
                this.toastr.error(response.message, 'Error Message');
                console.log('error');
              }
            }

            this.isLoading = false;
            this.spinner.hide();
            // window.alert(response.messgae);
      }
      ,
      error: (error) => {
            this.isLoading = false;
            this.spinner.hide();
            this.toastr.error(
              `The API returned Unsuccessfull response : ${error}`,
              'Error Message'
            );
            //window.alert(`The API returned Unsuccessfull response : ${error}`);
          },
    });
  }

  callBJL() {
    this.apiService.bjlpathoverwrite(this.inputData).subscribe({
        next: (response) => {
          if (response.message == 'select upstream equipment overwrite') {
            ////this.upstream_screen = true;
            this.select_bjl = false;
            this.inputData = response;
            this.tableData = response;
          } else {
            //this.showTable = false;
            this.toastr.error(
            `The API returned Unsuccessfull response`,
            'Error Message'
          );
          }

          this.isLoading = false;
          this.spinner.hide();
        },
        error: (error) => {
          this.isLoading = false;
          this.spinner.hide();
          this.toastr.error(
            `The API returned Unsuccessfull response : ${error}`,
            'Error Message'
          );
        },
      });
  }

  OnSelectProceedMapSelectSMP(data: any) {
    this.isLoading = true;
      this.spinner.show()  
    
    this.apiService.selectSmpStructure(this.inputData,this.inputData.message).subscribe({
      next: (response) => {
        
            if (response.message === 'select smp structure') {
              this.select_bjl = false;
              this.upstream_screen = false;
              this.inputData = response;
              this.tableData = response;
              this.select_smp_screen = true;
              this.tableChildComponent.initializeFunction(this.inputData);
              this.mapChildComponent.receiveTableEvent(this.inputData);
            }
            else if (response.message == 'select bjl structure') {
              this.select_bjl = false;
              this.upstream_screen = false;
              this.inputData = response;
              this.tableData = response;
              this.select_smp_screen = true;
              this.tableChildComponent.initializeFunction(this.inputData);
              this.mapChildComponent.receiveTableEvent(this.inputData);
            }
            else if (response.message == 'select new smp') {
              this.map_div = true;
              this.select_smp_screen = false;
              this.upstream_screen = false;
              this.select_bjl = false;
              this.tableData = response;
              this.inputData = response;
            }
              else if (response.message == 'sd1 process completed') {
              this.map_div = false;
              this.select_smp_screen = false;
              this.upstream_screen = false;
              this.select_bjl = false;
              this.final_screen = true;
              this.tableData = response;
              this.inputData = response;
              }
            else {              
              if ('file_name' in response) {
                this.select_bjl = false;
                this.upstream_screen = false;
                this.showMessage = true;
                this.MessageToShow = response;
              } else {
                this.toastr.error(response.message, 'Error Message');
                console.log('error');
              }
            }

            this.isLoading = false;
            this.spinner.hide();
      }
      ,
      error: (error) => {
            this.isLoading = false;
            this.spinner.hide();
            this.toastr.error(
              `The API returned Unsuccessfull response : ${error}`,
              'Error Message'
            );
          },
    });
  }

  receiveTableEventStructureSelectSMP(data: any) {
    this.mapChildComponent.receiveTableEvent(data,'bjl_smp')
  }

  receiveTableEventUpstream(data: any) {
    this.inputData['selected_upstream_equipment'] = data;
    this.isLoading = true;
    this.spinner.show();
    this.apiService.selectUpstreamStructure(this.inputData).subscribe({
      next: (response) => {

            if (response.message === 'select bjl structure sd1') {
              this.select_bjl = false;
              this.upstream_screen = false;
              this.inputData = response;
              this.select_smp_screen = true;
              this.tableData = response;
            }
            
            else {              
              if ('file_name' in response) {
                this.select_bjl = false;
                this.upstream_screen = false;
                this.showMessage = true;
                this.MessageToShow = response;
              } else {
                this.toastr.error(response.message, 'Error Message');
                console.log('error');
              }
            }

            this.isLoading = false;
            this.spinner.hide();
            // window.alert(response.messgae);
      }
      ,
      error: (error) => {
            this.isLoading = false;
            this.spinner.hide();
            this.toastr.error(
              `The API returned Unsuccessfull response : ${error}`,
              'Error Message'
            );
            //window.alert(`The API returned Unsuccessfull response : ${error}`);
          },
    });
  }

  receiveTableEventFinalCall() {
    this.isLoading = true;
      this.spinner.show()  
    
    this.apiService.generateSd1Output(this.inputData).subscribe({
      next: (response) => {
          //   response = {
          //     "ada_id": "3BMT-05-53",
          //     "loc_id": "LOC000194788120",
          //     "s18_path": "C:\\Users\\sasi.s.atmakuri\\Documents\\SD1 Samples\\3BMT-05-53",
          //     "ror_id": "ND000153412",
          //     "loc_type": "SDU1",
          //     "ufa_category": "low",
          //     "architecture_type": "Type3",
          //     "ada_tech_type": "FTTP",
          //     "loc_id_file": "C:\\Users\\sasi.s.atmakuri\\OneDrive - Accenture\\Desktop\\ODMS GIT REPO\\odms-backend\\code-base\\adr_data_path\\3BMT-05-53_Location.xlsx",
          //     "selected_smp": [
          //         {
          //             "smp_id": "3BMT-05-53-SMP-010",
          //             "smp_state": "Yes",
          //             "smp_structure_id": "3BMT-05-53-PIT-227",
          //             "location_id": "LOC000194788108",
          //             "smp_port": "1",
          //             "distance": 10.5
          //         },
          //         {
          //             "smp_id": "3BMT-05-53-SMP-010",
          //             "smp_state": "Yes",
          //             "smp_structure_id": "3BMT-05-53-PIT-227",
          //             "location_id": "LOC000194788098",
          //             "smp_port": "2",
          //             "distance": 11.2
          //         },
          //         {
          //             "smp_id": "3BMT-05-53-SMP-010",
          //             "smp_state": "Yes",
          //             "smp_structure_id": "3BMT-05-53-PIT-227",
          //             "location_id": "LOC000194788080",
          //             "smp_port": "3",
          //             "distance": 13.5
          //         },
          //         {
          //             "smp_id": "3BMT-05-53-SMP-010",
          //             "smp_state": "Yes",
          //             "smp_structure_id": "3BMT-05-53-PIT-227",
          //             "location_id": "LOC000194788149",
          //             "smp_port": "4",
          //             "distance": 38.4
          //         },
          //         {
          //             "smp_id": "3BMT-05-53-SMP-010",
          //             "smp_state": "Yes",
          //             "smp_structure_id": "3BMT-05-53-PIT-227",
          //             "location_id": "LOC000194788154",
          //             "smp_port": "5",
          //             "distance": 39.4
          //         },
          //         {
          //             "smp_id": "3BMT-05-53-SMP-010",
          //             "smp_state": "Yes",
          //             "smp_structure_id": "3BMT-05-53-PIT-227",
          //             "location_id": "LOC000194788131",
          //             "smp_port": "6",
          //             "distance": 40.7
          //         },
          //         {
          //             "smp_id": "3BMT-05-53-SMP-010",
          //             "smp_state": "Yes",
          //             "smp_structure_id": "3BMT-05-53-PIT-227",
          //             "location_id": "LOC000194788120",
          //             "smp_port": "7",
          //             "distance": 53.1
          //         },
          //         {
          //             "smp_id": "3BMT-05-53-SMP-010",
          //             "smp_state": "Yes",
          //             "smp_structure_id": "3BMT-05-53-PIT-227",
          //             "location_id": "LOC000194788112",
          //             "smp_port": "8",
          //             "distance": 55.4
          //         },
          //         {
          //             "smp_id": "3BMT-05-53-SMP-011",
          //             "smp_state": "Yes",
          //             "smp_structure_id": "3BMT-05-53-PIT-221",
          //             "location_id": "LOC000194788235",
          //             "smp_port": "1",
          //             "distance": 9.8
          //         },
          //         {
          //             "smp_id": "3BMT-05-53-SMP-011",
          //             "smp_state": "Yes",
          //             "smp_structure_id": "3BMT-05-53-PIT-221",
          //             "location_id": "LOC000194788226",
          //             "smp_port": "2",
          //             "distance": 12.1
          //         },
          //         {
          //             "smp_id": "3BMT-05-53-SMP-011",
          //             "smp_state": "Yes",
          //             "smp_structure_id": "3BMT-05-53-PIT-221",
          //             "location_id": "LOC000194788219",
          //             "smp_port": "3",
          //             "distance": 34.1
          //         },
          //         {
          //             "smp_id": "3BMT-05-53-SMP-011",
          //             "smp_state": "Yes",
          //             "smp_structure_id": "3BMT-05-53-PIT-221",
          //             "location_id": "LOC000194788203",
          //             "smp_port": "4",
          //             "distance": 61.2
          //         },
          //         {
          //             "smp_id": "3BMT-05-53-SMP-011",
          //             "smp_state": "Yes",
          //             "smp_structure_id": "3BMT-05-53-PIT-221",
          //             "location_id": "LOC000194788257",
          //             "smp_port": "5",
          //             "distance": 73.0
          //         },
          //         {
          //             "smp_id": "3BMT-05-53-SMP-011",
          //             "smp_state": "Yes",
          //             "smp_structure_id": "3BMT-05-53-PIT-221",
          //             "location_id": "LOC000194788261",
          //             "smp_port": "6",
          //             "distance": 75.0
          //         },
          //         {
          //             "smp_id": "3BMT-05-53-SMP-011",
          //             "smp_state": "Yes",
          //             "smp_structure_id": "3BMT-05-53-PIT-221",
          //             "location_id": "LOC000194788242",
          //             "smp_port": "7",
          //             "distance": 75.3
          //         },
          //         {
          //             "smp_id": "3BMT-05-53-SMP-011",
          //             "smp_state": "Yes",
          //             "smp_structure_id": "3BMT-05-53-PIT-221",
          //             "location_id": "LOC000194788300",
          //             "smp_port": "8",
          //             "distance": 112.1
          //         },
          //         {
          //             "smp_id": "3BMT-05-53-SMP-012",
          //             "smp_state": "Yes",
          //             "smp_structure_id": "3BMT-05-53-PIT-217",
          //             "location_id": "LOC000194788316",
          //             "smp_port": "1",
          //             "distance": 21.0
          //         },
          //         {
          //             "smp_id": "3BMT-05-53-SMP-012",
          //             "smp_state": "Yes",
          //             "smp_structure_id": "3BMT-05-53-PIT-217",
          //             "location_id": "LOC000194788183",
          //             "smp_port": "2",
          //             "distance": 43.5
          //         },
          //         {
          //             "smp_id": "3BMT-05-53-SMP-012",
          //             "smp_state": "Yes",
          //             "smp_structure_id": "3BMT-05-53-PIT-217",
          //             "location_id": "LOC000194788196",
          //             "smp_port": "3",
          //             "distance": 44.4
          //         },
          //         {
          //             "smp_id": "3BMT-05-53-SMP-012",
          //             "smp_state": "Yes",
          //             "smp_structure_id": "3BMT-05-53-PIT-217",
          //             "location_id": "LOC000194788177",
          //             "smp_port": "4",
          //             "distance": 45.3
          //         },
          //         {
          //             "smp_id": "3BMT-05-53-SMP-012",
          //             "smp_state": "Yes",
          //             "smp_structure_id": "3BMT-05-53-PIT-217",
          //             "location_id": "LOC000194788328",
          //             "smp_port": "5",
          //             "distance": 49.4
          //         },
          //         {
          //             "smp_id": "3BMT-05-53-SMP-012",
          //             "smp_state": "Yes",
          //             "smp_structure_id": "3BMT-05-53-PIT-217",
          //             "location_id": "LOC000194788337",
          //             "smp_port": "6",
          //             "distance": 51.7
          //         },
          //         {
          //             "smp_id": "3BMT-05-53-SMP-012",
          //             "smp_state": "Yes",
          //             "smp_structure_id": "3BMT-05-53-PIT-217",
          //             "location_id": "LOC000194788290",
          //             "smp_port": "7",
          //             "distance": 65.1
          //         },
          //         {
          //             "smp_id": "3BMT-05-53-SMP-012",
          //             "smp_state": "Yes",
          //             "smp_structure_id": "3BMT-05-53-PIT-217",
          //             "location_id": "LOC000194788165",
          //             "smp_port": "8",
          //             "distance": 70.8
          //         },
          //         {
          //             "smp_id": "3BMT-05-53-SMP-013",
          //             "smp_state": "Yes",
          //             "smp_structure_id": "3BMT-05-53-PIT-216",
          //             "location_id": "LOC000194788359",
          //             "smp_port": "1",
          //             "distance": 58.8
          //         },
          //         {
          //             "smp_id": "3BMT-05-53-SMP-013",
          //             "smp_state": "Yes",
          //             "smp_structure_id": "3BMT-05-53-PIT-216",
          //             "location_id": "LOC000194788344",
          //             "smp_port": "2",
          //             "distance": 61.1
          //         },
          //         {
          //             "smp_id": "3BMT-05-53-SMP-013",
          //             "smp_state": "Yes",
          //             "smp_structure_id": "3BMT-05-53-PIT-216",
          //             "location_id": "LOC000194788288",
          //             "smp_port": "3",
          //             "distance": 125.6
          //         },
          //         {
          //             "smp_id": "3BMT-05-53-SMP-013",
          //             "smp_state": "Yes",
          //             "smp_structure_id": "3BMT-05-53-PIT-216",
          //             "location_id": "LOC000194788274",
          //             "smp_port": "4",
          //             "distance": 127.8
          //         },
          //         {
          //             "smp_id": "3BMT-05-53-SMP-013",
          //             "smp_state": "Yes",
          //             "smp_structure_id": "3BMT-05-53-PIT-216",
          //             "location_id": "LOC000194788371",
          //             "smp_port": "5",
          //             "distance": 172.6
          //         },
          //         {
          //             "smp_id": "3BMT-05-53-SMP-013",
          //             "smp_state": "Yes",
          //             "smp_structure_id": "3BMT-05-53-PIT-216",
          //             "location_id": "LOC000194788363",
          //             "smp_port": "6",
          //             "distance": 174.9
          //         },
          //         {
          //             "smp_id": "3BMT-05-53-SMP-013",
          //             "smp_state": "Yes",
          //             "smp_structure_id": "3BMT-05-53-PIT-216",
          //             "location_id": "LOC000194788476",
          //             "smp_port": "7",
          //             "distance": 204.4
          //         },
          //         {
          //             "smp_id": "3BMT-05-53-SMP-013",
          //             "smp_state": "Yes",
          //             "smp_structure_id": "3BMT-05-53-PIT-216",
          //             "location_id": "LOC000194788469",
          //             "smp_port": "8",
          //             "distance": 206.7
          //         },
          //         {
          //             "smp_id": "3BMT-05-53-SMP-014",
          //             "smp_state": "Yes",
          //             "smp_structure_id": "3BMT-05-53-PIT-214",
          //             "location_id": "LOC000194788482",
          //             "smp_port": "1",
          //             "distance": 52.3
          //         },
          //         {
          //             "smp_id": "3BMT-05-53-SMP-014",
          //             "smp_state": "Yes",
          //             "smp_structure_id": "3BMT-05-53-PIT-214",
          //             "location_id": "LOC000194788495",
          //             "smp_port": "2",
          //             "distance": 54.3
          //         },
          //         {
          //             "smp_id": "3BMT-05-53-SMP-014",
          //             "smp_state": "Yes",
          //             "smp_structure_id": "3BMT-05-53-PIT-214",
          //             "location_id": "LOC000194788407",
          //             "smp_port": "3",
          //             "distance": 55.3
          //         },
          //         {
          //             "smp_id": "3BMT-05-53-SMP-014",
          //             "smp_state": "Yes",
          //             "smp_structure_id": "3BMT-05-53-PIT-214",
          //             "location_id": "LOC000194788411",
          //             "smp_port": "4",
          //             "distance": 57.7
          //         },
          //         {
          //             "smp_id": "3BMT-05-53-SMP-014",
          //             "smp_state": "Yes",
          //             "smp_structure_id": "3BMT-05-53-PIT-214",
          //             "location_id": "LOC000194788392",
          //             "smp_port": "5",
          //             "distance": 65.4
          //         },
          //         {
          //             "smp_id": "3BMT-05-53-SMP-014",
          //             "smp_state": "Yes",
          //             "smp_structure_id": "3BMT-05-53-PIT-214",
          //             "location_id": "LOC000194788385",
          //             "smp_port": "6",
          //             "distance": 67.7
          //         },
          //         {
          //             "smp_id": "3BMT-05-53-SMP-014",
          //             "smp_state": "Yes",
          //             "smp_structure_id": "3BMT-05-53-PIT-214",
          //             "location_id": "LOC000194788448",
          //             "smp_port": "7",
          //             "distance": 79.0
          //         },
          //         {
          //             "smp_id": "3BMT-05-53-SMP-014",
          //             "smp_state": "Yes",
          //             "smp_structure_id": "3BMT-05-53-PIT-214",
          //             "location_id": "LOC000194788453",
          //             "smp_port": "8",
          //             "distance": 81.3
          //         },
          //         {
          //             "smp_id": "3BMT-05-53-SMP-015",
          //             "smp_state": "Yes",
          //             "smp_structure_id": "3BMT-05-53-PIT-213",
          //             "location_id": "LOC000194788430",
          //             "smp_port": "1",
          //             "distance": 48.6
          //         },
          //         {
          //             "smp_id": "3BMT-05-53-SMP-015",
          //             "smp_state": "Yes",
          //             "smp_structure_id": "3BMT-05-53-PIT-213",
          //             "location_id": "LOC000194788424",
          //             "smp_port": "2",
          //             "distance": 50.5
          //         }
          //     ],
          //     "location_cords_list": [],
          //     "table_columns": [
          //         "adr_id","start_equipment","start_structure_id","end_equipment","end_structure_id","pit_path","trench_path"
          //     ],
          //     "adr_table":[{
          //       "adr_id":"001",
          //         "start_equipment":"abc",
          //         "start_structure_id":"abc_001",
          //         "end_equipment":"xyz",
          //         "end_structure_id":"xyz:001",
          //         "pit_path":"efqfcqwdqw",
          //         "trench_path":"reh45hreger"
          //     }],
          //     "message": "upload adr",

          //     "location_cords_list_served": [
          //         {
          //             "loc_label": "LOC000194788183",
          //             "loc_lat": -38.238101,
          //             "loc_lon": 144.326129
          //         },
          //         {
          //             "loc_label": "LOC000194788149",
          //             "loc_lat": -38.238388,
          //             "loc_lon": 144.325891
          //         },
          //         {
          //             "loc_label": "LOC000194788131",
          //             "loc_lat": -38.238505,
          //             "loc_lon": 144.325869
          //         },
          //         {
          //             "loc_label": "LOC000194788177",
          //             "loc_lat": -38.238085,
          //             "loc_lon": 144.325973
          //         },
          //         {
          //             "loc_label": "LOC000194788316",
          //             "loc_lat": -38.237674,
          //             "loc_lon": 144.326194
          //         },
          //         {
          //             "loc_label": "LOC000194788328",
          //             "loc_lat": -38.237653,
          //             "loc_lon": 144.326013
          //         },
          //         {
          //             "loc_label": "LOC000194788337",
          //             "loc_lat": -38.237632,
          //             "loc_lon": 144.325827
          //         },
          //         {
          //             "loc_label": "LOC000194788203",
          //             "loc_lat": -38.23813,
          //             "loc_lon": 144.326426
          //         },
          //         {
          //             "loc_label": "LOC000194788196",
          //             "loc_lat": -38.238117,
          //             "loc_lon": 144.326292
          //         },
          //         {
          //             "loc_label": "LOC000194788344",
          //             "loc_lat": -38.237613,
          //             "loc_lon": 144.325664
          //         },
          //         {
          //             "loc_label": "LOC000194788359",
          //             "loc_lat": -38.237591,
          //             "loc_lon": 144.325471
          //         },
          //         {
          //             "loc_label": "LOC000194788363",
          //             "loc_lat": -38.237302,
          //             "loc_lon": 144.325498
          //         },
          //         {
          //             "loc_label": "LOC000194788154",
          //             "loc_lat": -38.238273,
          //             "loc_lon": 144.325912
          //         },
          //         {
          //             "loc_label": "LOC000194788165",
          //             "loc_lat": -38.238071,
          //             "loc_lon": 144.325825
          //         },
          //         {
          //             "loc_label": "LOC000194788371",
          //             "loc_lat": -38.237318,
          //             "loc_lon": 144.325649
          //         },
          //         {
          //             "loc_label": "LOC000194788261",
          //             "loc_lat": -38.238381,
          //             "loc_lon": 144.326855
          //         },
          //         {
          //             "loc_label": "LOC000194788257",
          //             "loc_lat": -38.238503,
          //             "loc_lon": 144.326833
          //         },
          //         {
          //             "loc_label": "LOC000194788424",
          //             "loc_lat": -38.236959,
          //             "loc_lon": 144.326558
          //         },
          //         {
          //             "loc_label": "LOC000194788411",
          //             "loc_lat": -38.237401,
          //             "loc_lon": 144.326355
          //         },
          //         {
          //             "loc_label": "LOC000194788407",
          //             "loc_lat": -38.237372,
          //             "loc_lon": 144.32613
          //         },
          //         {
          //             "loc_label": "LOC000194788392",
          //             "loc_lat": -38.237359,
          //             "loc_lon": 144.325992
          //         },
          //         {
          //             "loc_label": "LOC000194788385",
          //             "loc_lat": -38.237339,
          //             "loc_lon": 144.32582
          //         },
          //         {
          //             "loc_label": "LOC000194788476",
          //             "loc_lat": -38.236868,
          //             "loc_lon": 144.325767
          //         },
          //         {
          //             "loc_label": "LOC000194788469",
          //             "loc_lat": -38.236885,
          //             "loc_lon": 144.325914
          //         },
          //         {
          //             "loc_label": "LOC000194788482",
          //             "loc_lat": -38.236849,
          //             "loc_lon": 144.325605
          //         },
          //         {
          //             "loc_label": "LOC000194788226",
          //             "loc_lat": -38.238437,
          //             "loc_lon": 144.326274
          //         },
          //         {
          //             "loc_label": "LOC000194788453",
          //             "loc_lat": -38.236905,
          //             "loc_lon": 144.32609
          //         },
          //         {
          //             "loc_label": "LOC000194788430",
          //             "loc_lat": -38.236943,
          //             "loc_lon": 144.326414
          //         },
          //         {
          //             "loc_label": "LOC000194788448",
          //             "loc_lat": -38.236925,
          //             "loc_lon": 144.326263
          //         },
          //         {
          //             "loc_label": "LOC000194788495",
          //             "loc_lat": -38.236829,
          //             "loc_lon": 144.325429
          //         },
          //         {
          //             "loc_label": "LOC000194788288",
          //             "loc_lat": -38.238195,
          //             "loc_lon": 144.326971
          //         },
          //         {
          //             "loc_label": "LOC000194788274",
          //             "loc_lat": -38.238176,
          //             "loc_lon": 144.326796
          //         },
          //         {
          //             "loc_label": "LOC000194788219",
          //             "loc_lat": -38.238314,
          //             "loc_lon": 144.326297
          //         },
          //         {
          //             "loc_label": "LOC000194788290",
          //             "loc_lat": -38.237819,
          //             "loc_lon": 144.326951
          //         },
          //         {
          //             "loc_label": "LOC000194788120",
          //             "loc_lat": -38.237932,
          //             "loc_lon": 144.325429
          //         },
          //         {
          //             "loc_label": "LOC000194788112",
          //             "loc_lat": -38.238039,
          //             "loc_lon": 144.325347
          //         },
          //         {
          //             "loc_label": "LOC000194788108",
          //             "loc_lat": -38.238175,
          //             "loc_lon": 144.325321
          //         },
          //         {
          //             "loc_label": "LOC000194788098",
          //             "loc_lat": -38.238306,
          //             "loc_lon": 144.325297
          //         },
          //         {
          //             "loc_label": "LOC000194788242",
          //             "loc_lat": -38.238614,
          //             "loc_lon": 144.326812
          //         },
          //         {
          //             "loc_label": "LOC000194788235",
          //             "loc_lat": -38.238561,
          //             "loc_lon": 144.326251
          //         },
          //         {
          //             "loc_label": "LOC000194788300",
          //             "loc_lat": -38.237761,
          //             "loc_lon": 144.326458
          //         },
          //         {
          //             "loc_label": "LOC000194788080",
          //             "loc_lat": -38.238435,
          //             "loc_lon": 144.325272
          //         }
          //     ],
          //     "selected_bjl": {
          //         "bjl_id": "3BMT-05-53-BJL-003",
          //         "bjl_structure_id": "3BMT-05-53-PIT-223",
          //         "bjl_spare_port": "4",
          //         "new_smp_size": "8",
          //         "new_splitter": "No",
          //         "sjr_trace": {
          //             "0_equip": "3BMT-05-53-BJL-003",
          //             "0_port": "4",
          //             "1_cable": "3BMT-05-53-FSL-003",
          //             "1_equip": "3BMT-05-53-FJL-001_3BMT-05-53-SPL-105",
          //             "1_port": "3",
          //             "upstream_connectivity_flag": true
          //         },
          //         "comment": "",
          //         "warning_message": ""
          //     },
          //     "bjl_with_capacity": [],
          //     "new_smp_list": [
          //         {
          //             "smp_id": "3BMT-05-53-SMP-010",
          //             "smp_status": "new",
          //             "sss_cable_id": "3BMT-05-53-SSS-010",
          //             "structure_id": "3BMT-05-53-PIT-227",
          //             "free_ports": [
          //                 "1",
          //                 "2",
          //                 "3",
          //                 "4",
          //                 "5",
          //                 "6",
          //                 "7",
          //                 "8"
          //             ],
          //             "bjl_id": "3BMT-05-53-BJL-001",
          //             "sjr_trace": {
          //                 "0_cable": "3BMT-05-53-SSS-010",
          //                 "0_equip": "3BMT-05-53-BJL-001",
          //                 "0_port": "3",
          //                 "1_cable": "3BMT-05-53-FSL-001",
          //                 "1_equip": "3BMT-05-53-FJL-001_3BMT-05-53-SPL-103",
          //                 "1_port": "2",
          //                 "upstream_connectivity_flag": true
          //             },
          //             "sjr_connectivity_flag": true
          //         },
          //         {
          //             "smp_id": "3BMT-05-53-SMP-011",
          //             "smp_status": "new",
          //             "sss_cable_id": "3BMT-05-53-SSS-011",
          //             "structure_id": "3BMT-05-53-PIT-221",
          //             "free_ports": [
          //                 "1",
          //                 "2",
          //                 "3",
          //                 "4",
          //                 "5",
          //                 "6",
          //                 "7",
          //                 "8"
          //             ],
          //             "bjl_id": "3BMT-05-53-BJL-001",
          //             "sjr_trace": {
          //                 "0_cable": "3BMT-05-53-SSS-011",
          //                 "0_equip": "3BMT-05-53-BJL-001",
          //                 "0_port": "4",
          //                 "1_cable": "3BMT-05-53-FSL-001",
          //                 "1_equip": "3BMT-05-53-FJL-001_3BMT-05-53-SPL-103",
          //                 "1_port": "3",
          //                 "upstream_connectivity_flag": true
          //             },
          //             "sjr_connectivity_flag": true
          //         },
          //         {
          //             "smp_id": "3BMT-05-53-SMP-012",
          //             "smp_status": "new",
          //             "sss_cable_id": "3BMT-05-53-SSS-012",
          //             "structure_id": "3BMT-05-53-PIT-217",
          //             "free_ports": [
          //                 "1",
          //                 "2",
          //                 "3",
          //                 "4",
          //                 "5",
          //                 "6",
          //                 "7",
          //                 "8"
          //             ],
          //             "bjl_id": "3BMT-05-53-BJL-003",
          //             "sjr_trace": {
          //                 "0_cable": "3BMT-05-53-SSS-012",
          //                 "0_equip": "3BMT-05-53-BJL-003",
          //                 "0_port": "1",
          //                 "1_cable": "3BMT-05-53-FSL-003",
          //                 "1_equip": "3BMT-05-53-FJL-001_3BMT-05-53-SPL-104",
          //                 "1_port": "4"
          //             },
          //             "sjr_connectivity_flag": true
          //         },
          //         {
          //             "smp_id": "3BMT-05-53-SMP-013",
          //             "smp_status": "new",
          //             "sss_cable_id": "3BMT-05-53-SSS-013",
          //             "structure_id": "3BMT-05-53-PIT-216",
          //             "free_ports": [
          //                 "1",
          //                 "2",
          //                 "3",
          //                 "4",
          //                 "5",
          //                 "6",
          //                 "7",
          //                 "8"
          //             ],
          //             "bjl_id": "3BMT-05-53-BJL-003",
          //             "sjr_trace": {
          //                 "0_cable": "3BMT-05-53-SSS-013",
          //                 "0_equip": "3BMT-05-53-BJL-003",
          //                 "0_port": "2",
          //                 "1_cable": "3BMT-05-53-FSL-003",
          //                 "1_equip": "3BMT-05-53-FJL-001_3BMT-05-53-SPL-105",
          //                 "1_port": "1",
          //                 "upstream_connectivity_flag": true,
          //                 "new_splitter": "Yes",
          //                 "fsd_port": "5",
          //                 "fsd_cable": "3BMT-05-53-FSD-001",
          //                 "comment": "New Splitter: 3BMT-05-53-SPL-105 will be created in FJL: 3BMT-05-53-FJL-001",
          //                 "warning_message": ""
          //             },
          //             "sjr_connectivity_flag": true
          //         },
          //         {
          //             "smp_id": "3BMT-05-53-SMP-014",
          //             "smp_status": "new",
          //             "sss_cable_id": "3BMT-05-53-SSS-014",
          //             "structure_id": "3BMT-05-53-PIT-214",
          //             "free_ports": [
          //                 "1",
          //                 "2",
          //                 "3",
          //                 "4",
          //                 "5",
          //                 "6",
          //                 "7",
          //                 "8"
          //             ],
          //             "bjl_id": "3BMT-05-53-BJL-003",
          //             "sjr_trace": {
          //                 "0_cable": "3BMT-05-53-SSS-014",
          //                 "0_equip": "3BMT-05-53-BJL-003",
          //                 "0_port": "3",
          //                 "1_cable": "3BMT-05-53-FSL-003",
          //                 "1_equip": "3BMT-05-53-FJL-001_3BMT-05-53-SPL-105",
          //                 "1_port": "2",
          //                 "upstream_connectivity_flag": true
          //             },
          //             "sjr_connectivity_flag": true
          //         },
          //         {
          //             "smp_id": "3BMT-05-53-SMP-015",
          //             "smp_status": "new",
          //             "sss_cable_id": "3BMT-05-53-SSS-015",
          //             "structure_id": "3BMT-05-53-PIT-213",
          //             "free_ports": [
          //                 "1",
          //                 "2",
          //                 "3",
          //                 "4",
          //                 "5",
          //                 "6",
          //                 "7",
          //                 "8"
          //             ],
          //             "bjl_id": "3BMT-05-53-BJL-003",
          //             "sjr_trace": {
          //                 "0_cable": "3BMT-05-53-SSS-015",
          //                 "0_equip": "3BMT-05-53-BJL-003",
          //                 "0_port": "4",
          //                 "1_cable": "3BMT-05-53-FSL-003",
          //                 "1_equip": "3BMT-05-53-FJL-001_3BMT-05-53-SPL-105",
          //                 "1_port": "3",
          //                 "upstream_connectivity_flag": true
          //             },
          //             "sjr_connectivity_flag": true
          //         }
          //     ],
          //     "new_bjl_list": [
          //         [
          //             "3BMT-05-53-BJL-003",
          //             "3BMT-05-53-FSL-003",
          //             "3BMT-05-53-PIT-223"
          //         ]
          //     ],
          //     "selected_smp_structure": {
          //         "bjl_id": "3BMT-05-53-BJL-003",
          //         "bjl_structure_id": "3BMT-05-53-PIT-223",
          //         "structure_to_bjl_distance": 287.8,
          //         "structure_id": "3BMT-05-53-PIT-213",
          //         "potential_smp_structure_id": "3BMT-05-53-PIT-213",
          //         "label": "3BMT-05-53-PIT-213",
          //         "lat": -38.237205,
          //         "lon": 144.326181
          //     },
          //     "smp_structure_list": [
          //         {
          //             "bjl_id": "3BMT-05-53-BJL-003",
          //             "bjl_structure_id": "3BMT-05-53-PIT-223",
          //             "structure_to_bjl_distance": 28.1,
          //             "structure_id": "3BMT-05-53-PIT-222",
          //             "potential_smp_structure_id": "3BMT-05-53-PIT-222",
          //             "label": "3BMT-05-53-PIT-222",
          //             "lat": -38.238359,
          //             "lon": 144.326501
          //         },
          //         {
          //             "bjl_id": "3BMT-05-53-BJL-003",
          //             "bjl_structure_id": "3BMT-05-53-PIT-223",
          //             "structure_to_bjl_distance": 39.1,
          //             "structure_id": "3BMT-05-53-PIT-217",
          //             "potential_smp_structure_id": "3BMT-05-53-PIT-217",
          //             "label": "3BMT-05-53-PIT-217",
          //             "lat": -38.23785,
          //             "lon": 144.32644
          //         },
          //         {
          //             "bjl_id": "3BMT-05-53-BJL-003",
          //             "bjl_structure_id": "3BMT-05-53-PIT-223",
          //             "structure_to_bjl_distance": 54.2,
          //             "structure_id": "3BMT-05-53-PIT-221",
          //             "potential_smp_structure_id": "3BMT-05-53-PIT-221",
          //             "label": "3BMT-05-53-PIT-221",
          //             "lat": -38.238593,
          //             "lon": 144.326464
          //         },
          //         {
          //             "bjl_id": "3BMT-05-53-BJL-003",
          //             "bjl_structure_id": "3BMT-05-53-PIT-223",
          //             "structure_to_bjl_distance": 79.8,
          //             "structure_id": "3BMT-05-53-PIT-216",
          //             "potential_smp_structure_id": "3BMT-05-53-PIT-216",
          //             "label": "3BMT-05-53-PIT-216",
          //             "lat": -38.237796,
          //             "lon": 144.325965
          //         },
          //         {
          //             "bjl_id": "3BMT-05-53-BJL-003",
          //             "bjl_structure_id": "3BMT-05-53-PIT-223",
          //             "structure_to_bjl_distance": 89.2,
          //             "structure_id": "3BMT-05-53-PIT-218",
          //             "potential_smp_structure_id": "3BMT-05-53-PIT-218",
          //             "label": "3BMT-05-53-PIT-218",
          //             "lat": -38.23792,
          //             "lon": 144.32704
          //         },
          //         {
          //             "bjl_id": "3BMT-05-53-BJL-003",
          //             "bjl_structure_id": "3BMT-05-53-PIT-223",
          //             "structure_to_bjl_distance": 126.0,
          //             "structure_id": "3BMT-05-53-PIT-215",
          //             "potential_smp_structure_id": "3BMT-05-53-PIT-215",
          //             "label": "3BMT-05-53-PIT-215",
          //             "lat": -38.237733,
          //             "lon": 144.325424
          //         },
          //         {
          //             "bjl_id": "3BMT-05-53-BJL-003",
          //             "bjl_structure_id": "3BMT-05-53-PIT-223",
          //             "structure_to_bjl_distance": 157.0,
          //             "structure_id": "3BMT-05-53-PIT-229",
          //             "potential_smp_structure_id": "3BMT-05-53-PIT-229",
          //             "label": "3BMT-05-53-PIT-229",
          //             "lat": -38.237585,
          //             "lon": 144.325213
          //         },
          //         {
          //             "bjl_id": "3BMT-05-53-BJL-003",
          //             "bjl_structure_id": "3BMT-05-53-PIT-223",
          //             "structure_to_bjl_distance": 163.8,
          //             "structure_id": "3BMT-05-53-PIT-228",
          //             "potential_smp_structure_id": "3BMT-05-53-PIT-228",
          //             "label": "3BMT-05-53-PIT-228",
          //             "lat": -38.237937,
          //             "lon": 144.325601
          //         },
          //         {
          //             "bjl_id": "3BMT-05-53-BJL-003",
          //             "bjl_structure_id": "3BMT-05-53-PIT-223",
          //             "structure_to_bjl_distance": 199.0,
          //             "structure_id": "3BMT-05-53-PIT-230",
          //             "potential_smp_structure_id": "3BMT-05-53-PIT-230",
          //             "label": "3BMT-05-53-PIT-230",
          //             "lat": -38.23721,
          //             "lon": 144.325272
          //         },
          //         {
          //             "bjl_id": "3BMT-05-53-BJL-003",
          //             "bjl_structure_id": "3BMT-05-53-PIT-223",
          //             "structure_to_bjl_distance": 205.2,
          //             "structure_id": "3BMT-05-53-PIT-227",
          //             "potential_smp_structure_id": "3BMT-05-53-PIT-227",
          //             "label": "3BMT-05-53-PIT-227",
          //             "lat": -38.238317,
          //             "lon": 144.325541
          //         },
          //         {
          //             "bjl_id": "3BMT-05-53-BJL-003",
          //             "bjl_structure_id": "3BMT-05-53-PIT-223",
          //             "structure_to_bjl_distance": 244.4,
          //             "structure_id": "3BMT-05-53-PIT-214",
          //             "potential_smp_structure_id": "3BMT-05-53-PIT-214",
          //             "label": "3BMT-05-53-PIT-214",
          //             "lat": -38.237148,
          //             "lon": 144.325684
          //         },
          //         {
          //             "bjl_id": "3BMT-05-53-BJL-003",
          //             "bjl_structure_id": "3BMT-05-53-PIT-223",
          //             "structure_to_bjl_distance": 287.8,
          //             "structure_id": "3BMT-05-53-PIT-213",
          //             "potential_smp_structure_id": "3BMT-05-53-PIT-213",
          //             "label": "3BMT-05-53-PIT-213",
          //             "lat": -38.237205,
          //             "lon": 144.326181
          //         }
          //     ],
          //     "locations_served_from_smp": [
          //         {
          //             "smp_id": "3BMT-05-53-SMP-015",
          //             "smp_status": "new",
          //             "smp_port": "1",
          //             "suggested_location_id": "LOC000194788430",
          //             "distance": 48.6,
          //             "smp_label": "3BMT-05-53-SMP-015",
          //             "smp_lat": -38.237205,
          //             "smp_lon": 144.326181
          //         },
          //         {
          //             "smp_id": "3BMT-05-53-SMP-015",
          //             "smp_status": "new",
          //             "smp_port": "2",
          //             "suggested_location_id": "LOC000194788424",
          //             "distance": 50.5,
          //             "smp_label": "3BMT-05-53-SMP-015",
          //             "smp_lat": -38.237205,
          //             "smp_lon": 144.326181
          //         }
          //     ]
          // }
                if (response.message == 'Pre-signed url has been generated') {
                  this.map_div = false;
                  this.select_smp_screen = false;
                  this.upstream_screen = false;
                  this.select_bjl = false;
                  this.final_screen = false;
                  this.showMessage = true; 
                  this.url = response.url;
                  this.filename = response.file_name;
                  this.msg = response.message;
                  this.MessageToShow = JSON.stringify(response);
                }
                else if (response.message == 'upload adr') {
                  this.showADRCard = true;
                  this.map_div = false;
                  this.select_smp_screen = false;
                  this.upstream_screen = false;
                  this.select_bjl = false;
                  this.final_screen = false;
                  this.inputData = response;
                }
                else {              
                  if ('file_name' in response) {
                    this.select_bjl = false;
                    this.upstream_screen = false;
                    this.showMessage = true;
                    this.MessageToShow = response;
                  } else {
                    this.toastr.error(response.message, 'Error Message');
                    console.log('error');
                  }
                }

            this.isLoading = false;
            this.spinner.hide();
      },
      error: (error) => {
            this.isLoading = false;
            this.spinner.hide();
            this.toastr.error(
              `The API returned Unsuccessfull response : ${error}`,
              'Error Message'
            );
          },
    });
  }

  table_function_called() {
    this.isLoading = true;
    this.spinner.show();
    if (this.selectedRow && this.selectedRow.length > 0) {
      let new_route_list = [];
      for (let ele of this.selectedRow) {
        new_route_list.push(ele['Paths']);
      }
      this.inputData['new_route_list'] = new_route_list;
    }
    this.inputData['adr_path'] = this.myAdrForm.value.ADRpath;
    this.inputData['adr_file_name'] = this.file;

    this.inputData['available_paths'] = this.inputData['available_paths'];
    this.apiService.onadrcall(this.inputData).subscribe({
      next: (response) => {
        this.showADRCard = false;

        if (
          response.message.indexOf(
            "Tool generated path doesn't match with ADR"
          ) != -1
        ) {
          this.url = response.url;
          this.filename = response.file_name;
          this.msg = response.message;
          this.showMessage = true;
          this.MessageToShow = JSON.stringify(response);
        } else if (response.message == 'Pre-signed url has been generated') {
          this.url = response.url;
          this.filename = response.file_name;
          this.msg = response.message;
          this.showMessage = true;
          this.MessageToShow = JSON.stringify(response);
        }
        else if(response.message == 'alternate path'){
            this.showADRCard = false;
            this.alternate_path = true;    
            this.inputData =  response;  
            //this.tableChildComponent.initializeFunction(this.inputData);
        } 
        else {
          this.url = response.url;
          this.filename = response.file_name;
          this.msg = response.message;
          this.showMessage = true;
          this.MessageToShow = JSON.stringify(response);
        }
        this.isLoading = false;
        this.spinner.hide();
        // window.alert(response.messgae);
      },
      error: (error) => {
        this.isLoading = false;
        this.spinner.hide();
        this.toastr.error(
          `The API returned Unsuccessfull response : ${error}`,
          'Error Message'
        );
        //window.alert(`The API returned Unsuccessfull response : ${error}`);
      },
    });
  }
  
}
