
<div class="card" *ngIf="showMDUCard">
<h3 *ngIf="showMDUCard">{{'mdu' | titleText}}</h3>
  <app-upload-file [flowName]="flowName" (uploaded)="fileUploaded($event)"></app-upload-file>
  <form [formGroup]="myMduForm" (ngSubmit)="onMduCall()">
    <div class="row">
      <div class="col-md-2 form-group">
        <label class="adr_label" for="ada">MPS Boundary:</label>
      </div>
      <div class="col-md-6 form-group">

        <input type="text" id="mpsboundary" formControlName="MPSBoundary" name="mpsboundary"
          placeholder="Enter MPS Boundary Id" required />
      </div>
    </div>
    <div class="row">
      <div class="col-md-4 form-group">
        <button type="submit" class="primary">

          <!-- [disabled]="file == null ? true : false" [class]="file == null ? 'gray-button' : ''"-->
          Submit
        </button>
      </div>
    </div>
  </form>
</div>


<div *ngIf="success_path">
  <app-lfn-path-generartion [inputData]="inputData" table_title="MDU Flow"></app-lfn-path-generartion>
</div>

<div *ngIf="equipment_screen">
  <app-lfn-equipment-flow [inputData]="equPayLoad" [equPayLoad]="equPayLoad" [showEquipmetCard]="showEquipmetCard"
    table_title="MDU Flow"></app-lfn-equipment-flow>
</div>

<div *ngIf="show_adr_form">
  <app-adr-form [inputData]="inputData"
  [showADRCard]="show_adr_form"></app-adr-form>
</div>

<div  *ngIf="upstream_screen">
<app-lfn-upstream-equipment            
            [inputData]="tableData"
            table_title="MDU Flow"
    ></app-lfn-upstream-equipment> 
</div>

<!--Structure  Component called-->
<div *ngIf="structure_screen" class="">
        <app-structure-screen
            [inputData]="inputData"
        ></app-structure-screen>
</div>

<div *ngIf="showEndEquipment">
  <app-end-equipment-screen
    [inputData] = "inputData"
  >
  </app-end-equipment-screen>
</div>

<!-- spinner Component Called-->
<div *ngIf="isLoading">
  <ngx-spinner type="ball-scale-multiple"></ngx-spinner>
</div>