<div class="container">
  <app-header (signOut)="signOut()" [islogin]="islogin"></app-header>
  <div class="content" role="main">
    <div *ngIf="new_user" style="width: 95%;">
      <amplify-authenticator >
        <ng-template
          amplifySlot="authenticated"
          let-user="user"
          let-signOut="signOut"
        >
          <!-- <app-landing-page style="width: 95%;"></app-landing-page> -->
          <router-outlet></router-outlet>
        </ng-template>
        
      </amplify-authenticator>
    </div>
    <!-- <button (click)="signOut()">Sign Out</button> -->
    <div *ngIf="existing_user" style="width: 95%;">
      <!-- <app-landing-page style="width: 95%;"></app-landing-page> -->
      <router-outlet></router-outlet>
    </div>
  </div>
  <app-footer></app-footer>
</div>