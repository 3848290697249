import { CommonModule } from "@angular/common";
import { HttpClient } from "@angular/common/http";
import { Component, EventEmitter, HostListener, Input, OnInit, Output, SimpleChanges } from "@angular/core";
import { FormGroup, FormBuilder, Validators, ReactiveFormsModule } from "@angular/forms";
import { ApiService } from "../../../api.service";

declare var google: any;

@Component({
  selector: "app-map",
  standalone: true,
  imports:[CommonModule,ReactiveFormsModule],
  templateUrl: "./map.component.html",
  styleUrls: ["./map.component.scss"]
})

export class MapComponent implements OnInit {
  @Input() inputData: any;
  @Input() refreshVariable: any = '';
  @Output() appItemEvent = new EventEmitter<any>();
  @Output() OnSelectProceedMap = new EventEmitter<any>();
  map: any;
  isLoading: boolean = false;
  showMap: boolean = true;
  selectStructure = true;
  markers: any[] = [];
  enableProceedButton: boolean = false;
  // structure_list:any;
  marker_icon_FF0000 = {
    url: 'data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABUAAAAiCAYAAACwaJKDAAAABmJLR0QA/wD/AP+gvaeTAAACBklEQVRIia3VzUtUURgH4GdG/AiyZZShtWgXUbSIFtGqRYtqWRLhXyBYf0K6MaJQ2gRtayHtijYpleHKSCgIcRHoIiOSKEzLKea0OOeqTfPlzPzg5Qwz9zz3nXPvPTeneo7gNA4gjyI+Ygbva8z9L2cxi9BHOE+4msY+gliz6biayWE0R7GfMEcoEkJJzRH6CbnY+WiaVxEc6yY8KQOVq8eE7tj1WCV4qIswUyeY1QyhK8JDpWAP1m7vEMzqTkTXkrOZkYOEQoNogXAowiPE2wQuDqC9nktZJu0YSE72XRs2phrsMqup2OkG2vLpRB19DXaZJc3vQHv294Um0e3z8yigsNQkmuYXUMie5/npJtE0fz55YLiXsNHELdUbV2B4+4n2Y/Vmg+itCK4m558MdhBe7hCcJnRGdLDS0ox3E17XCb4h7IngeLX1zuFhD2G5BriytY4Tqmx9WXbh3Tnl99KsLkdwAbtrgVmO4/eDCuCkzd3/TL1glru9hF8lYJFwMoKPdgrCXqzfL0GfR7CIo42gcO9YCXopolONgnAC4W0Cv9l8dVxpBoWFGwmdiOC6Glc8X+3HlKeT6cOzOLzAjyaaBBc602ZzOHZ6vVkQ9kl7Qi6ip1qBwpdrEfwjPnFVU8+awuKrOC7hZ6vQlQ9baM3Ui379HsfVVqKf07jcSvRTGhfrOfgvIP3ECS77BDoAAAAASUVORK5CYII=',
    labelOrigin: new google.maps.Point(10, 11),
  };

  marker_icon_FF0001 = {
    url: '/assets/images/home.png',
    labelOrigin: new google.maps.Point(10, 11),
  };
  marker_icon_FF0002 = {
    url: '/assets/images/highligted_icon.png',
    labelOrigin: new google.maps.Point(10, 11),
  };

  markerData: any = [];
  myStrForm: FormGroup;
  structure_list_map = [
    {
      lat: -37.847361,
      lon: 144.991863,
      label: '000000000000000000',
    },
  ];

  constructor(
    private http: HttpClient,
    private fb: FormBuilder,
    private apiService: ApiService
  ) {
    this.myStrForm = this.fb.group({
      structure: ['', Validators.required],
    });
    this.myStrForm.setValue({
      structure: 'Select Structure',
    });
  }

  ngOnInit() {
    // this.structure_list=this.inputData.structure_list
    this.structure_list_map = this.inputData.structure_list;
    if (this.inputData.message == 'select bjl structure' ||
      this.inputData.message == 'select bjl structure dfn' ||
    this.inputData.message == 'select ajl structure') {
      this.selectStructure = false;
      this.structure_list_map = this.inputData.jl_structure_list;
    }
    if (this.inputData.message == 'select mpt structure') {
      this.selectStructure = false;
      this.structure_list_map = this.inputData.mpt_structure_list;
    }  
   
    
    let structure_list_home:any = {
      lat:this.inputData.loc_coordinate[1],
      lon: this.inputData.loc_coordinate[0],
      label: this.inputData.loc_id,
      excluded:true
    }
    this.structure_list_map.push(structure_list_home);
    this.initMap(
      this.structure_list_map[this.structure_list_map.length - 1].lat,
      this.structure_list_map[this.structure_list_map.length - 1].lon
    );
    this.addMarkers(this.structure_list_map, 'default');  
    
  }

  ngOnChanges(changes: SimpleChanges) {
    if (changes['refreshVariable']) {
      this.receiveEvent(changes['refreshVariable'].currentValue);
    }
  }
  addMarkers(structure_list: any, selectedLabel: any) {
    for (let ele of structure_list) {
      const data = {
        position: new google.maps.LatLng(ele.lat, ele.lon),
        label: ele.label,
      };
      this.markerData.push(data);
    }

    // Create markers and attach click event listeners
    this.markerData.forEach((data: { position: any; label: any }) => {
      let marker: any;
      if (data.label.indexOf('LOC') != -1) {
        marker = new google.maps.Marker({
          position: data.position,
          label: {
            text: data.label,
            color: 'white',
          },
          icon: this.marker_icon_FF0001,
          map: this.map,
        });
      } else if (data.label == selectedLabel) {
        marker = new google.maps.Marker({
          position: data.position,
          label: data.label,
          icon: this.marker_icon_FF0002,
          map: this.map,
        });
      } else {
        marker = new google.maps.Marker({
          position: data.position,
          label: {
            text: data.label,
            color: 'white',
          },
          icon: this.marker_icon_FF0000,
          map: this.map,
        });
      }

      // Add a click event listener to each marker
      marker.addListener('click', () => {
        // Handle the click event here, e.g., display the label
        this.handleMarker(marker);
        console.log('Marker clicked with label:', marker.getLabel());
      });

      // Push the marker object to the array
      this.markers.push(marker);
    });
  }
  handleMarker(_marker: any) {
    let label = _marker.getLabel();
    this.inputData['structure_id'] = label.text;
    this.myStrForm.setValue({
      structure: label.text,
    });
  }
  initMap(lat: any, long: any): void {
    this.map = new google.maps.Map(document.getElementById('map'), {
      center: { lat: lat, lng: long },
      zoom: 20,
      mapTypeId: 'satellite',
      // center: { lat: -25.274400, lng: 133.775100 },
      // zoom: 4
    });

    const marker = new google.maps.Marker({
      position: { lat: 37.7749, lng: -122.4194 },
      map: this.map,
      title: 'Marker Title',
    });
  }
  @HostListener('window:message', ['$event'])
  handleMessage(event: MessageEvent) {
    if (event.data.type === 'markerClick') {
      const label = event.data.label;
      // this.setFormValue(label)
      this.inputData['structure_id'] = label.text;
      console.log('Map clicked at:', this.inputData);
    }
  }
  
  OnSelectProceed() { this.OnSelectProceedMap.emit(this.inputData);}
  onselectStructure() { }
  
  receiveEvent(data: any) {
    console.log(data);
    if (this.refreshVariable.hasOwnProperty('lat')) {
      this.enableProceedButton = true;
      if (this.inputData.message === 'select mpt structure') {
        this.inputData['selected_mpt_structure_id'] = data;
        this.initMap(data.lat, data.lon);
        this.addMarkers(this.inputData.mpt_structure_list, data.structure_id);
      } else {
        this.inputData['selected_jl_structure_id'] = data;
        this.initMap(data.lat, data.lon);
        this.addMarkers(this.inputData.jl_structure_list, data.structure_id);
      }
    } else {
      this.inputData['selected_jl_structure_id'] = data;
      console.log(data);
      this.enableProceedButton = true;
      this.initMap(data.lat, data.lon);
      this.addMarkers(this.inputData.structure_list, data.structure_id);
    }
    if (this.inputData.message === 'select structure') {
      this.enableProceedButton = false;
    }

    const myLatLng = { lat: data.lat, lng: data.lon };
    const marker = new google.maps.Marker({
      position: myLatLng,
      map: this.map,
      label: {
        text: data.structure_id,
        color: 'white',
      },
      icon: this.marker_icon_FF0002,
    });
  }


  receiveTableEvent(data: any) {
    if (data.hasOwnProperty('data')) {
      this.refreshVariable = data['data'];
      this.receiveEvent(data['data']);
    }
    else if (data.hasOwnProperty('lat')) {
      this.refreshVariable = data;
      this.receiveEvent(data);
    }
    else {
      this.showMap = true;
      this.inputData = data;
      this.ngOnInit();
    }
  }
}
