import { Component, EventEmitter, Input, OnInit, Output, ViewChild } from "@angular/core";
import { NgxSpinnerService, NgxSpinnerModule } from 'ngx-spinner';
import { JlStructureComponent } from "../../common/jl-structure/jl-structure.component";
import { StructureScreenComponent } from "../../common/structure-screen/structure-screen.component";
import { TableComponent } from "../../common/table/table.component";
import { LfnPathGenerartionComponent } from "../lfn-path-generartion/lfn-path-generartion.component";
import { HttpClient } from "@angular/common/http";
import { FormBuilder, FormGroup } from "@angular/forms";
import { ToastrService } from "ngx-toastr";
import { ApiService } from "../../../api.service";
import { MapComponent } from "../../common/map/map.component";
import { CommonModule } from "@angular/common";
import { TitleTextPipe } from "../../../custom.pipe";
import { AdrFormComponent } from "../../common/adr-form/adr-form.component";

@Component({
  selector: "app-lfn-upstream-equipment",
  standalone: true,
  imports: [
    TableComponent,
    LfnPathGenerartionComponent,
    StructureScreenComponent,
    JlStructureComponent,
    NgxSpinnerModule,
    CommonModule,TitleTextPipe,AdrFormComponent
  ],
  templateUrl: "./lfn-upstream-equipment.component.html",
  styleUrls: ["./lfn-upstream-equipment.component.scss"]
})

export class LfnUpstreamEquipmentComponent implements OnInit {
  @Input() inputData: any;
  @Input() table_title: string = '';
  tableData: any;
  displayedColumns: string[] = [];
  //@Output() appItemEvent = new EventEmitter<any>();

  isLoading: boolean = false;   
  // myEquipmetForm: any = FormGroup;  
  //@Input() showEquipmetCard: boolean = true;
  showTable: boolean = true;
  // table2Data: any;
  // showType2Table: boolean = false;
  showMap: boolean = false;
  // mapData: any;
  showMessage: boolean = false;
  MessageToShow: any;
  showADRCard = false;

  @ViewChild(TableComponent)
  tableChildComponent!: TableComponent;

  @ViewChild(MapComponent)
  mapChildComponent!: MapComponent;

  @ViewChild(LfnPathGenerartionComponent)
  lfnPathGeneration!: LfnPathGenerartionComponent;
  showEquipmetTable: boolean = false;
  map_div: boolean = false;
  success_path: boolean = false;
  upstream_screens: boolean = true;
  structure_screen: boolean = false;

  constructor(
    private fb: FormBuilder,
    private http: HttpClient,
    private toastr: ToastrService,
    private apiService: ApiService,
    private spinner: NgxSpinnerService
  ) {

  }

  receiveTableEvent(data: any) {

    if ('structure_id' in data) {
      this.viewProfile(data);
    }
  }

  viewProfile(data: any) {   
    //this.isLoading = true;
    //this.spinner.show();    
    if (
      ['select mpt upstream equipment', 'select upstream equipment adr','select ajl upstream equipment'].includes(
        this.inputData.message
      )
    ) {
      this.inputData['selected_upstream_equipment'] = data;
      this.showADRCard = true;
      this.showEquipmetTable = false;
      this.upstream_screens = false;
      this.showTable = false;
            
      // this.apiService.selectUpstremEquipment(this.inputData).subscribe({
      //   next: (response) => {
      //     if (
      //       ['select bjl structure', 'select mpt structure', 'select ajl structure'].includes(
      //         response.message
      //       )
      //     ) {
      //       this.inputData = response;
      //       this.showTable = false;
      //       this.showEquipmetTable = false;
      //       this.tableData = response;
      //       this.tableChildComponent.initializeFunction(this.inputData);

      //       this.showMap = true;
      //       // this.mapData = data;
      //       this.map_div = true;
      //       this.upstream_screens = false;

      //     } else if (response.message === 'receive adr') {
      //       this.showADRCard = true;
      //       this.showEquipmetTable = false;
      //       this.upstream_screens = false;
      //       this.showTable = false;
      //       this.map_div = false;
      //       this.inputData = response;
      //     } else {
      //       this.toastr.error(
      //         response.message,
      //       'Error Message'
      //     );
      //       // this.showMessage = true;
      //       // this.MessageToShow = JSON.stringify(response);
      //     }
      //     this.isLoading = false;
      //     this.spinner.hide();
      //     // Handle the response data
      //   },
      //   error: (error) => {
      //     this.isLoading = false;
      //     this.spinner.hide();
      //     this.toastr.error(
      //       `The API returned Unsuccessfull response : ${error}`,
      //       'Error Message'
      //     );
      //   },
      // });

    }
    else {
      this.inputData['selected_path'] = data;
    }
  }

  ngOnInit() {

  }
}
